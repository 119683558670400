import { reportPopulatedAdapter } from "@/adapters/patients/reportPopulated"

export default {
    primaryKey: 'id',
    model: 'report_populated',
    modalsize: 'lg',
    modaltitle: 'ndfl_statement',
    // prepareParams: ['patient_id', 'branch_id'],
    prepareParams: ['id', 'patient_id'],
    order: 'id',
    sort: 'asc',
    actions: [
        // "add",
        "excel",
        // "edit",
        "delete"
    ],
    deletemessage: 'confirm_deletion',
    adapter: reportPopulatedAdapter,
    actionscomponent: 'PatientDatatableReportPopulatedActions',
    formComponent: "PatientDatatableReportPopulatedForm",
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'number',
            title: 'number',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            table_show: 'data.ndflCounter',
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true,
        },

        // {
        //     id: 'insurance_company_id',
        //     title: 'insurance_company',
        //     table: true,
        //     table_show: 'insurance_company.title',
        //     sortable: true,
        //     filter: true,
        //     filter_model_select: true,
        //     form: {
        //         type: "select",
        //         select_type: "model",
        //         required: true,
        //         select_data: {
        //             "title": "title",
        //         }
        //     }
        // },
        // {
        //     id: 'polis_limit',
        //     title: 'limit',
        //     format_number: true,
        //     table: true,
        //     form: {
        //         required: true,
        //         type: "number",
        //     },
        //     tdcomponent: 'InsurancePolisDatatableTDLimit',
        // },
        // {
        //     id: 'valid_from',
        //     title: 'valid_from',
        //     table: true,
        //     sortable: true,
        //     format_date: true,
        //     filter: true,
        //     filter_date: true,
        //     form: {
        //         required: true,
        //         classes: 'col-md-6',
        //         type: "date"
        //     }
        // },
        // {
        //     id: 'valid_to',
        //     title: 'valid_to',
        //     table: true,
        //     sortable: true,
        //     format_date: true,
        //     filter: true,
        //     filter_date: true,
        //     form: {
        //         required: true,
        //         classes: 'col-md-6',
        //         type: "date"
        //     },
        //     tdcomponent: 'InsurancePolisDatatableTDValidTo',
        // },
        // {
        //     id: 'comment',
        //     title: 'comment',
        //     table: true,
        //     sortable: false,
        //     form: {
        //         type: "textarea"
        //     }
        // },
    ]
}
