export default {
    f37u78: "Forma 037/U-88",
    f43kaz: "Forma 043/QAZ",
    f43rus: "Forma 043/RF",
    f43ukr: "Forma 043/U",
    f58kaz: "Forma 058/QAZ",
    accept_another_payment: "Başqa ödənişi qəbul edin",
    active_plan_treatments: "Aktiv plan müalicələri",
    activity: "Fəaliyyət",
    add_complex: "Kompleksi əlavə edin",
    add_diagnosis: "Diaqnozu əlavə edin",
    add_question: "Sualı əlavə edin",
    add_service: "Xidməti əlavə edin",
    add_tooth_jaw: "Diş / çənə əlavə edin",
    add_visit: "Vizit əlavə edin",
    added_by: "Kim tərəfindən əlavə olunub",
    additional_discount: "Əlavə endirim",
    age: "Yaş",
    agreement: "Razılıq",
    akt_of_services_for_period: "Müddət ərzində göstərilən xidmətlərin aktı",
    akt_print: "Aktın çapı",
    allergic_reactions: "Allergik reaksiyalar",
    allergies: "Allergiyalar",
    allergy_diagnosis: "Allergik diaqnoz",
    anamnesis: "Stomatoloji anamnez",
    anketa_footer: "Anketin alt hissəsi",
    anketa_header: "Anketin başlığı",
    anketa_texts: "Anket mətnləri",
    applied: "Tətbiq olunub",
    applied_complex: "Tətbiq olunan kompleks",
    apply_to_teeth: "Dişlərə tətbiq edin",
    apply_to_whole_tooth: "Bütün dişə tətbiq edin",
    apply_treatment_template: "Müalicə şablonunu tətbiq edin",
    are_you_sure_to_set_plan: "Müalicə kartını plana bağlamaq istədiyinizə əminsinizmi - kartın doldurulmuş sahələri yenidən yazıla bilər?",
    are_you_sure_you_want_to_delete_document: "Sənədi silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_payment: "Ödənişi silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_scan: "Skanı silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_status: "Statusu silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_transaction: "Transaksiyanı silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_user: "İstifadəçini silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_users: "İstifadəçiləri silmək istədiyinizə əminsiniz?",
    ask_result_on_saving: "Saxlama zamanı ziyarətin nəticəsini tələb edin",
    assistant: "Assistent",
    attach_new_user: "Yeni istifadəçini bağlayın",
    author: "Müəllif",
    available: "Mövcuddur",
    baby_teeth: "Süd dişləri",
    balance_refill: "Depoziti doldurmaq",
    balance_writeoff: "Depozitdən silmə",
    birthdays: "Doğum günləri",
    bite: "Dişləmə",
    bottom_baby_teeth: "Aşağı süd dişləri",
    bottom_jaw: "Aşağı çənə",
    bottom_molars: "Aşağı azı dişləri",
    by_doctors: "Həkimlər üzrə",
    by_complexes: "Komplekslər üzrə",
    call: "Zəng etmək",
    came: "Gəldi",
    came_from: "Haradan gəldi",
    can_be_paid_with_bonuses: "Bonuslarla ödənilə bilər",
    cancel_initiator: "Ləğv etmə təşəbbüskarı",
    cancelled: "Ləğv edilib",
    card: "Kart",
    card_number: "Kart nömrəsi",
    card_transfer: "Kartla köçürmə",
    cash: "Nağd",
    cashless: "Nağdsız",
    choose_document_type_and_its_parameters: "Sənəd növünü və onun parametrlərini seçin",
    choose_patient: "Pasiyenti seçin",
    choose_signer: "İmzalayanı seçin",
    choose_treatment_template: "Müalicə şablonunu seçin",
    client: "Pasiyent",
    client_document: "Pasiyentin sənədi",
    client_id: "Pasiyent",
    client_note: "Qeyd",
    client_service: "Müştəri xidməti",
    code: "Kod",
    color: "Rəng",
    combine_user_note: "Pasiyenti seçərək və \"birgə\" düyməsinə basaraq bütün ziyarət, ödəniş, diaqnoz məlumatlarını seçilən pasiyentin kartına köçürəcəksiniz. Hazırkı pasiyentin kartı silinəcək. Birləşmə əməliyyatını ləğv etmək mümkün olmayacaq.",
    comment_to_phone: "Telefon üzrə şərh",
    comment_to_phone_2: "Əlavə telefon üzrə şərh",
    complaints: "Şikayətlər",
    complex: "Kompleks",
    complexes: "Komplekslər",
    condition: "Vəziyyət",
    confirm_tag_deletion: "Etiketin silinməsinə əminsiniz?",
    confirmed: "Təsdiqlənib",
    confirmed_male: "Təsdiq edilib",
    contract: "Müqavilə",
    contract_agreement: "Razılaşma müqaviləsi",
    contract_general: "Ümumi müqavilə",
    contract_print: "Müqavilənin çapı",
    contract_services: "Xidmətlər təqdimatına dair müqavilə",
    consultation: "Məsləhət",
    create_diagnosis: "Diaqnoz yarat",
    creation_date: "Yaradılma tarixi",
    date: "Tarix",
    date_add: "Yaradılıb",
    date_from: "Tarixdən",
    date_of_birth: "Doğum tarixi",
    date_to: "Tarixə qədər",
    dates_not_selected: "Tarixlər seçilməyib",
    debt: "Borc",
    debt_cancellation: "Borcun ləğvi",
    delete_complex: "Kompleksi sil",
    delete_visit: "Viziti sil",
    department_code: "Şöbə kodu",
    deposit: "Depozit",
    description: "Təsvir",
    desired_date_from: "İstənilən tarixdən",
    desired_date_to: "İstənilən tarixə qədər",
    desired_time_from: "İstənilən vaxtdan",
    desired_time_to: "İstənilən vaxta qədər",
    detailed_information: "Ətraflı məlumat",
    diagnoses: "Diaqnozlar",
    diagnosis: "Diaqnoz",
    did_not_come: "Gəlmədi",
    discount: "Endirim",
    disease_anamnez: "Xəstəlik anamnezi (hazırkı xəstəliyin inkişafı)",
    disease_development: "Hazırkı xəstəliyin inkişafı",
    disease_history: "Xəstəlik tarixi",
    diseases_passed_and_current: "Keçirilən və mövcud xəstəliklər",
    doctor: "Həkim",
    document: "Sənəd",
    document_number: "Sənəd nömrəsi",
    document_type: "Sənəd növü",
    drag_file_here_or_select: "Faylı buraya sürükləyin və ya seçin",
    electronic_cheque: "Elektron çek",
    enter_the_title: "Adı daxil edin",
    epikriz: "Epikriz",
    examination_plan: "Müayinə planı",
    f043: "F 043",
    family_account: "Ailə hesabı",
    female: "Qadın",
    female_short: "Q",
    fill_anamnez_from_previous_visits: "Əvvəlki vizitlərdən anamnezi doldurun",
    fill_balance: "Balansı doldurun",
    filling: "Doldurma",
    finish_visit: "Viziti bitir",
    fio: "Ad, soyad, ata adı",
    first_visit: "İlkin vizit",
    fiscal_cheque: "Fiskal çek",
    for_female_patients: "Qadın pasiyentlər üçün",
    formula: "Formula",
    foto_link_note: "Fotoşəkli yükləmədən başqa, şəkilə açıq keçid də göstərə bilərsiniz",
    from_account: "Hesabdan",
    full_card: "Tam kart",
    gender: "Cins",
    general_info: "Əsas məlumat",
    hygiene_index: "Gigiyena indeksi",
    iin: "FİN",
    in_chair: "Kresloda",
    in_process: "Prosessdə",
    include_in_f043: "043 formaya daxil edin",
    include_in_f043f058: "043 / 058 formaya daxil edin",
    include_in_f058: "058 formaya daxil edin",
    inspection: "Xarici müayinə",
    insurance: "Sığorta",
    invoice_print: "Çekin çapı",
    importance: "Vaciblik",
    important: "Vacib",
    inn: "VÖEN",
    is_deleted: "Silinib",
    issue_date: "Buraxılış tarixi",
    issued_by: "Tərəfindən verilib",
    jaws: "Çənələr",
    kpu_index: "KQÇ indeksi",
    last_visit: "Son vizit",
    left_due: "Qalan məbləğ",
    life_anamnez: "Həyat anamnezi",
    link: "Keçid",
    link_to_call_record: "Danışıq qeydinin keçidi",
    listen: "Dinləmək",
    live_feed: "Canlı yayım",
    lost: "İtirilmiş",
    make_payment: "Ödəniş et",
    male: "Kişi",
    male_short: "K",
    manual_status: "Əl ilə status",
    medication: "Dərman",
    middle_name: "Atasının adı",
    mkb_code: "XBT kodu",
    move_to_visit: "Vizitə keç",
    mphone: "Telefon",
    ndfl_statement: "NDFL arayışı",
    ndfl_statement_new: "Yeni NDFL arayışı",
    ndfl_statement_xml: "NDFL arayışı (XML)",
    new: "Yeni",
    new_patient: "İlkin pasiyent",
    next_visit: "Növbəti vizit",
    no_attachment: "Heç bir yükləmə yoxdur",
    no_complexes: "Kompleks yoxdur",
    no_teeth_in_the_record: "Yazıda diş göstərilməyib",
    norm: "Norma",
    not_confirmed: "Təsdiqlənməyib",
    note: "Qeyd",
    notes: "Qeydlər",
    objectively: "Obyektiv",
    on_week: "Həftəyə",
    or_write_other_reason: "və ya digər səbəbi yazın",
    oral_cavity: "Ağız boşluğu",
    ortodentic_formula: "Ortodontik formula",
    ortodentic_formula_ru: "Ortodontik formula (RF)",
    other: "Digər",
    overpayment: "Artıq ödəniş",
    paid: "Ödənilib",
    passive: "Passiv",
    passport: "Pasport",
    passport_data: "Pasport məlumatları",
    passport_number: "Pasport nömrəsi",
    password_validation_error: "Şifrə azı 8 simvoldan ibarət olmalı, bir böyük hərf, bir rəqəm və bir xüsusi simvol olmalıdır.",
    patient: "Pasiyent",
    patient_condition_id: "Vəziyyət",
    patient_deleted: "Pasiyent silinib",
    patient_fio: "Pasiyentin FİO",
    patient_has_important_notes: "Pasiyentin vacib qeydləri var: {cnt}",
    patient_number: "Pasiyentin nömrəsi",
    patient_phone: "Pasiyentin telefonu",
    patient_source: "Haradan gəldi",
    payment: "Ödəniş",
    payment_accepted: "Ödəniş qəbul edilib",
    payment_date: "Ödəniş tarixi",
    payment_form: "Ödəniş forması",
    payment_history: "Ödəniş tarixi",
    payment_processing: "Ödənişin həyata keçirilməsi",
    payment_type: "Ödəniş üsulu",
    payments: "Ödənişlər",
    permanent_teeth: "Daimi dişlər",
    personal_data: "Şəxsi məlumatlar",
    pesel: "PESEL",
    phone_2: "Əlavə telefon",
    pin: "Bərkidmək",
    pinned: "Bərkidilib",
    plan_fact: "Plan / Fakt",
    plan_notification: "Bildirişi planlaşdırın",
    planned_result: "Planlaşdırılan nəticə",
    planned_send_date: "Planlaşdırılan göndərmə tarixi",
    planned_services: "Planlaşdırılan prosedurlar",
    planned_treatment: "Planlaşdırılan müalicə",
    preliminary: "İlkin",
    price_price: "Qiymət",
    prices_price: "Qiymətlər",
    print_empty: "Boş çap edin",
    process: "Həyata keçirmək",
    question: "Sual",
    questionnaire: "Anket",
    quick_card: "Sürətli kart",
    receipt: "Qəbz",
    recommendations: "Tövsiyələr",
    record: "Yazı",
    record_not_available: "Yazı mövcud deyil",
    recorded: "Yazılıb",
    refresh_prices: "Qiymətləri yeniləyin",
    region: "Rayon",
    regular_client: "Daimi müştəri",
    rejected: "Rədd edildi",
    reopen_visit: "Viziti yenidən aç",
    representative: "Nümayəndə",
    representative_address: "Nümayəndənin ünvanı",
    representative_document: "Nümayəndənin sənədi",
    representative_document_number: "Nümayəndənin sənəd nömrəsi",
    representative_document_type: "Nümayəndənin sənəd növü",
    representative_id: "Pasiyentlər siyahısından nümayəndə",
    representative_inn: "Nümayəndənin VÖEN-i",
    representative_noun: "Nümayəndə",
    representative_passport: "Nümayəndənin pasport nömrəsi",
    representative_passport_data: "Nümayəndənin pasport məlumatları",
    representative_phone: "Nümayəndənin telefonu",
    request_snils: "SNILS sorğu edin",
    request_snils_note: "SNILS almaq üçün pasiyentin FİO, cins, doğum tarixi və pasport məlumatları (növ, seriya, nömrə, verilmə tarixi və kim tərəfindən verildiyi) doldurulmalıdır.",
    restore: "Bərpa edin",
    result: "Nəticə",
    results_not_found: "Nəticələr tapılmadı",
    results_of_xray_and_lab_research: "Rentgen və laboratoriya tədqiqatlarının nəticələri",
    save_as_template: "Şablon kimi saxlayın",
    save_as_treatment_template: "Müalicə şablonu kimi saxlayın",
    save_and_close: "Saxla və bağla",
    scan: "Skan",
    scans: "Skanlar",
    select_document_type: "Sənəd növünü seçin",
    select_visit: "Viziti seçin",
    selected_teeth: "Seçilmiş dişlər",
    send: "Göndər",
    send_mail: "Poçt göndərin",
    send_marketing: "Marketinq materialları göndərin",
    send_message: "Mesaj göndər",
    send_notifications: "Bildirişlər göndərin",
    send_password_to_patient: "Şifrəni pasiyentə göndərin",
    send_try: "Göndərmə cəhdi",
    series: "Seriya",
    service: "Xidmət",
    services_payment_from: "Xidmət ödənişləri",
    set_the_amount: "Miqdarı göstərin",
    size: "Ölçü",
    sms_available: "SMS mövcuddur",
    sms_sending: "SMS göndərmə",
    snils: "SNILS",
    sorting_instructions: "Sətirləri istədiyiniz ardıcıllıqla düzmək üçün siçan ilə sürükləyin",
    standard: "Standart",
    start_treatment: "Müalicəyə başlayın",
    start_visit: "Viziti başlayın",
    stopped: "Dayandırılıb",
    subjectively: "Subyektiv",
    sum_with_discount: "Endirimlə məbləğ",
    superadministrator: "Superadministrator",
    surname: "Soyad",
    short_name: "Qısa ad",
    symbols_amount: "Simvolların sayı",
    tags_for_contract: "Məlumatın avtomatik çıxarılması üçün etiketlər:",
    tags_for_document: "Məlumatın avtomatik çıxarılması üçün etiketlər:",
    teeth: "Dişlər",
    teeth_card: "Diş kartı",
    termometry: "Termometriya",
    there_were_no_payments: "Hələ ödənişlər olmayıb",
    this_phone_is_in_system: "Bu nömrə artıq sistemdə mövcuddur",
    to_account: "Hesaba",
    to_pay_with_bonus: "Bonuslarla ödə",
    to_whom: "Kimə",
    tonometry: "Tonometriya",
    tooth: "Diş",
    topic: "Mövzu",
    treatment_template: "Müalicə şablonu",
    tooth_is_assigned: "Diş silinə bilməz - ona artıq xidmətlər və ya diaqnozlar təyin olunub",
    tooth_formula: "Diş formulu",
    top_baby_teeth: "Yuxarı süd dişləri",
    top_jaw: "Yuxarı çənə",
    top_molars: "Yuxarı azı dişləri",
    to_add_diagnoses_you_need_to_select_teeth: "Diaqnozları əlavə etmək üçün dişləri seçməlisiniz",
    to_add_services_you_need_to_select_teeth: "Xidmətləri əlavə etmək üçün dişləri seçməlisiniz",
    total_paid: "Ümumi ödənilmiş məbləğ",
    total_paid_for_treatment_plan: "Müalicə planı üzrə ödənilmiş məbləğ",
    total_services_selected: "Seçilmiş xidmətlərin ümumi sayı",
    total_to_pay: "Ödəniləcək məbləğ",
    total_with_discount: "Endirimlə ümumi məbləğ",
    transfer_between_patient_accounts: "Pasiyentlər arasında köçürmə",
    transfer_whole_amount: "Bütün məbləği köçür",
    treating_doctor: "Müalicə edən həkim",
    treatment: "Müalicə",
    treatment_card: "Müalicə kartı",
    treatment_complete: "Müalicə tamamlandı",
    treatment_plan: "Müalicə planı",
    un: "əd.",
    unify: "Birləşdir",
    unify_card: "Kartı birləşdir",
    unpaid: "Ödənilməyib",
    unpin: "Çıxar",
    upload_date: "Yükləmə tarixi",
    used_medication: "Qəbul edilən dərman preparatları",
    user_attached_to_family_account: "İstifadəçi ailə hesabına bağlıdır",
    users_attached_to_user_family_account: "İstifadəçinin ailə hesabına bağlı pasiyentlər",
    visit: "Vizit",
    visit_date: "Vizit tarixi",
    visit_description_doctor: "Həkimin təsviri",
    visit_duration: "Vizit müddəti (dəq)",
    visit_finished: "Qəbul bitdi",
    visit_in_progress: "Qəbul davam edir",
    visit_started: "Qəbul başladı",
    visit_time: "Vizit vaxtı",
    visits: "Vizitlər",
    waiting: "Gözləyir",
    waiting_list: "Gözləmə siyahısı",
    with_formula: "Formula ilə",
    without_payment: "Ödənişsiz",
    write_reason: "Səbəbi yazın",
    xray: "Rentgen",
    years: "İl",
    you_need_to_select_teeth: "Dişləri seçməlisiniz",
    zipcode: "Poçt indeksi",
}
