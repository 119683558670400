<template>
  <div class="mb-4"
       v-if="hasRight">
    <b-button v-spaceduck-collapse="`green-c`"
              class="btn-collapse">GreenAPI WhatsApp</b-button>
    <spaceduck-collapse id="green-c"
                        @close="closed"
                        @show="opened">
      <div class="mt-3 pb-2">
        <div v-if="loaded">

          <div v-if="!enabled && !justCreatedInstance">
            <div class="alert alert-warning">{{ $t('not_connected') }}</div>
            <button @click="connect"
                    class="btn btn-themed btn-themed-squared"
                    :disabled="loading">
              {{ $t('connect') }}
            </button>
          </div>

          <div v-if="justCreatedInstance && authorizedStatus !== 'notAuthorized'" class="alert alert-info">
            Заявка на создание подключения отправлена. Необходимо дождаться завершения создания инстанса и вернуться на эту страницу (создание и инициализация может занимать до 5 минут)
          </div>

          <div v-if="enabled">

            <div v-if="authorizedStatus !== 'authorized'">

              <div v-if="!qr">
                <div v-if="!justTurnedOff && !connectedFromOtherBranch">
                  <div class="alert alert-warning">{{ $t('status') }}: {{ authorizedStatus }}</div>
                  <div class="d-flex">
                    <button @click="authorize"
                            v-if="authorizedStatus === 'notAuthorized'"
                            class="btn btn-themed btn-themed-squared mr-3"
                            :disabled="loading">
                      {{ $t('authorization') }}
                    </button>
                    <button class="btn-themed btn-themed-squared btn-outline-danger"
                            @click="deleteInstance">
                      {{ $t('turn_off') }}
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="qr">
                <p class="mb-3">{{ $t('whatsapp_qr_instructions') }}</p>
                <div class="text-center">
                  <img :src="`data:image/png;base64, ${qr}`"
                       alt="qr"
                       class="qr-image"/>
                </div>
              </div>

            </div>

            <div v-if="connectedFromOtherBranch && 1 === 2"
                 class="alert alert-warning">
              Подключено с другого филиала <a @click="connectSeparately" class="pointer">Сделать отдельное подключение для текущего</a>
            </div>

            <div v-if="authorizedStatus === 'authorized'">
              <div class="alert alert-info">{{ $t('authorized') }}</div>
              <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                      :disabled="loading"
                      @click="logout">
                {{ $t('logout') }}
              </button>
            </div>

          </div>
        </div>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script setup>
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import { computed, ref } from "vue"
import { useStore } from "vuex"
import { WhatsappService } from "@/services/api.service"

const store = useStore()

const loaded = ref(false)
const authorizedStatus = ref(null)
const connectedFromOtherBranch = ref(false)
const justCreatedInstance = ref(false)
const justTurnedOff = ref(false)
const enabled = ref(false)
const qr = ref(null)
let timer = null
let tries = 0

const rights = computed(() => store.state.auth.rights)
const clinic = computed(() => store.state.auth.clinic)
const branchId = computed(() => Number(store.state.auth.branch))
const superadmin = computed(() => store.state.auth.superadmin)
const loading = computed(() => store.state.dom.loading)

const hasRight = computed(() => {
  if(superadmin.value) return clinic.value.rights.includes('green_api')
  return rights.value.includes('green_api')
})

const branchIdDefault = computed(() => {
  const res = branchId.value ?? clinic.value?.branches?.[0]?.id ?? null
  return res === 0 ? '' : res;
})

const opened = () => {
  closed()
  getStatus()
}

const closed = () => {
  loaded.value = false
  authorizedStatus.value = null
  enabled.value = false
  justCreatedInstance.value = false
  justTurnedOff.value = false
  connectedFromOtherBranch.value = false
  cancelQR()
}

const getStatus = async (loader = true) => {
  const res = await WhatsappService.getGreenAPIStatus(superadmin.value, clinic.value.id, branchIdDefault.value, loader)
  enabled.value = res.data?.enabled || false
  authorizedStatus.value = res.data?.authorized || false
  connectedFromOtherBranch.value = res.data?.otherBranch || false
  loaded.value = true
  if(authorizedStatus.value === 'authorized') {
    cancelQR()
  }
}

const connect = async () => {
  try {
    const res = await WhatsappService.createGreenAPIInstance(superadmin.value, clinic.value.id, branchIdDefault.value)
    if(res.data) {
      await getStatus()
      justCreatedInstance.value = true
    }
  } catch (e) {
    console.log(e)
  }
}

const connectSeparately = () => {
  authorizedStatus.value = null
  enabled.value = false
  connect()
}

const authorize = () => {
  tries = 0;
  getQR()
  timer = setInterval(() => {
    getQR()
    tries++
    if(tries > 10) {
      cancelQR()
    }
  }, 3000)
}

const logout = async () => {
  const res = await WhatsappService.logoutFromGreenAPIInstance(superadmin.value, clinic.value.id, branchIdDefault.value)
  if(res.data) await getStatus()
}

const deleteInstance = async () => {
  try {
    const res = await WhatsappService.deleteGreenAPIInstance(superadmin.value, clinic.value.id, branchIdDefault.value)
    if(res.data) await getStatus()
    justTurnedOff.value = true
  } catch (e) {
    console.log(e)
  }
}

const getQR = async () => {
  try {
    const res = await WhatsappService.getGreenAPIQRCode(superadmin.value, clinic.value.id, branchIdDefault.value)
    qr.value = res.data
    justCreatedInstance.value = false
    getStatus(false).then()
  } catch (e) {
    cancelQR()
    console.log(e)
    getStatus(false).then()
  }
}

const cancelQR = () => {
  qr.value = null
  tries = 0
  if(timer) {
    clearInterval(timer)
    timer = null
  }
}
</script>

<style scoped lang="scss">
.qr-image {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
