export default {
    "-": "-",
    actions: "Actions",
    actions_with_selected: "Actions with selected",
    active: "Active",
    add: "Add",
    add_attachment: "Add attachment",
    add_condition: "Add condition",
    all: "All",
    app_is_in_maintenance_mode: "The app is in maintenance mode, we'll be online soon",
    are_you_sure_you_want_to_delete: "Are you sure you want to delete material?",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    authorization: "Authorization",
    authorize_in_system: "Authorize in the system ",
    azerbaijani: "Azerbaijani",
    balance: "Deposit",
    cancel: "Cancel",
    change: "Change",
    change_password: "Change Password",
    comment: "Comment",
    confirm_deletion: "Confirm deletion?",
    contacts: "Contacts",
    contains: "Contains",
    continue: "Continue",
    currency: "Currency",
    delete: "Delete",
    does_not_equal: "Does not equal",
    done: "Done",
    down: "Down",
    edit: "Edit",
    email: "E-mail",
    english: "English",
    error: "Error",
    error_message: "Something went wrong, the operation was not completed successfully",
    equals: "Equals",
    excel: "Excel",
    fact: "Fact",
    file: "File",
    file_does_not_exist: "File not found",
    file_size_is_too_big: "File size is too big",
    filled: "Filled",
    filter: "Filter",
    finish: "Finish",
    forgot_password: "Did you forget your Password?",
    hide: "Hide",
    home: "Home page",
    image: "Image",
    import: "Import",
    in_interval: "In interval",
    input_field: "Text input field",
    KZT: "KZT",
    language: "Language",
    less: "Less",
    links: "Links",
    load_more: "Load more",
    login: "Login",
    login_or_pass_incorrect: "Login and/or password is incorrect",
    logout: "Logout",
    main: "Main",
    message: "Message",
    module_unavailable: "Module is unavailable",
    months: "Months",
    more: "More",
    name: "Name",
    no: "No",
    no_data_available: "No data available yet",
    nonactive: "Inactive",
    not_enough_rights_for_this_operation: "Insufficient rights for this operation",
    nothing_found: "No results found",
    old_password: "Old Password",
    on_page: "On Page",
    page_not_found: "Page not found",
    password: "Password",
    password_changed: "Password changed",
    password_confirm: "Confirm Password",
    passwords_do_not_match: "Passwords do not match",
    patient_search: "Patient search",
    payment_expires_at: "Payment expires at",
    phone: "Telephone",
    photo: "Photo",
    price: "Price",
    print_noun: "Print",
    program: "Program",
    profile: "Profile",
    registration: "Registration",
    refresh: "Refresh",
    requirements: "Requirements",
    reset: "Reset",
    reset_password: "Password reset",
    reset_success: "A reset letter has been sent to your E-mail",
    role: "Role",
    russian: "Russian",
    save: "Save",
    search: "Search",
    select: "Select",
    sent: "Sent",
    settings: "Settings",
    signin: "Sign in",
    show: "Show",
    show_full: "Show full",
    slogan: "Cloud-based solution for managing modern dentistry",
    sorting: "Sorting",
    start: "Start",
    status: "Status",
    subscription_sum: "Subscription sum",
    success_message: "Operation was successful",
    sum: "Amount",
    table_search: "Table look-up",
    tech_specialist: "Technical specialist",
    text: "Text",
    this_module_is_not_in_tariff: "This module is not included in the Free tariff, you need to connect Standard or Premium, and you will get access to a larger amount of functionality of the Dentist Plus program.",
    title: "Title",
    today: "Today",
    total: "Total",
    translation: "Translation",
    unknown: "Unknown",
    upload: "Upload",
    who_has_access: "Who has access",
    yes: "Yes",
}
