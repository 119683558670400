<template>
  <section id="laboratory_orders" class="laboratory_orders">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('requirement_order') }}</h1>
          </div>
        </div>
      </div>
    </transition>
    <DataTable :options="options"
               :params="{ patient_id: patient.id }"
               module="patients"/>
  </section>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/laboratory_order"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref, computed } from "vue"
import { useStore } from "vuex"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('requirement_order')
})

const patient = computed(() => store.state.patient.patient)

onMounted(() => {
  store.commit('setEloquentOptions', options)
})
</script>
