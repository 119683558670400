<template>
  <b-modal id="online-contract-modal"
           :title="$t('sign_via_onlinecontract')"
           @hide="stop"
           @show="startTheProcess"
           hide-footer
           centered>


    <div class="mb-3" v-if="documentData">Данные договора: <br/>
      Номер: {{ documentData.docNumber }}<br/>
      Дата: {{ formatDate(documentData.docDate) }}<br/>
      Статус: <b>{{ documentStatus }}</b><br/>
    </div>

    <ul>

      <li>
        1. Добавление договора в систему online-contract:
        <b-spinner v-if="loadingContract" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingContract && docId">✅</span>
        <span v-if="!loadingContract && !docId">❌</span>
      </li>

      <li>
        2. Добавление пациента в качестве подписанта:
        <b-spinner v-if="loadingParticipant" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingParticipant && !loadingContract && patientParticipantId && clinicParticipantId">✅</span>
        <span v-if="!loadingParticipant && !loadingContract && !patientParticipantId">❌</span>
      </li>

      <li>
        3. Отправка на подпись пациенту:
        <a v-if="!loadingParticipantSign && !sentToPatient && patientParticipantId" @click="sendToPatientForSign" class="pointer text-primary">Отправить пациенту</a>
        <b-spinner v-if="loadingParticipantSign" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingParticipantSign && sentToPatient">✅</span>
        <span v-if="!loadingContract && !docId">❌</span>
      </li>

      <li>
        4. Отправка на подпись клинике:
        <b-spinner v-if="loadingClinicSign" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingClinicSign && clinicSignLink"><a target="_blank" :href="clinicSignLink">{{ $t('link') }}</a></span>
        <span v-if="!loadingContract && !docId">❌</span>
      </li>

      <li>
        5. Подписано пациентом:
        <b-spinner v-if="loadingSignatures && !loadingContract" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingSignatures && !loadingContract && !patientSignature">❌</span>
        <span v-if="!loadingSignatures && !loadingContract && patientSignature">{{ patientSignature.status == 4 ? '✅' : '❌' }}</span>
      </li>

      <li>
        6. Подписано клиникой:
        <b-spinner v-if="loadingSignatures && !loadingContract" class="d-inline-block align-middle ml-2"></b-spinner>
        <span v-if="!loadingSignatures && !loadingContract && !clinicSignature">❌</span>
        <span v-if="!loadingSignatures && !loadingContract && clinicSignature">{{ clinicSignature.status == 4 ? '✅' : '❌' }}</span>
      </li>
    </ul>

    <a v-if="docId"
       @click="refreshData"
       class="btn btn-themed btn-themed-outline btn-themed-squared">
      Обновить данные
    </a>

  </b-modal>
</template>

<script setup>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import { DocumentsService } from "@/services/api.service"
import { onlineContractContractStatuses } from "@/dictionaries/dictionary"
import { formatDate } from "@/extensions/filters/filters"

const props = defineProps({
  generatedDoc: Object
})

const store = useStore()

const docId = ref(null)
const documentData = ref(null)
const patientParticipantId = ref(null)
const clinicParticipantId = ref(null)
const sentToPatient = ref(false)
const clinicSignLink = ref(null)
const patientSignature = ref(null)
const clinicSignature = ref(null)
const interval = ref(null)
const patient = computed(() => store.state.patient.patient)
const documentStatus = computed(() => {
  if(!documentData.value) return null
  return onlineContractContractStatuses.find(x => x.id === documentData.value.eDocCommonCardStatus)?.title || null
})

const loadingContract = ref(false)
const loadingParticipant = ref(false)
const loadingParticipantSign = ref(false)
const loadingClinicSign = ref(false)
const loadingSignatures = ref(false)
const isCancelled = ref(false)
const emit = defineEmits(['closed'])

const startTheProcess = async () => {
  isCancelled.value = false
  docId.value = null
  documentData.value = null
  patientParticipantId.value = null
  clinicParticipantId.value = null
  sentToPatient.value = null
  clinicSignLink.value = null
  patientSignature.value = null
  clinicSignature.value = null
  await generateContract()
  if(!docId.value || isCancelled.value) return
  await addPatient()
  // if(!patientParticipantId.value || isCancelled.value) return
  // await sendToPatientForSign()
  // if(!sentToPatient.value || isCancelled.value) return
  if(isCancelled.value) return
  await sendToClinicForSign()
  launchTimer()
}

const stop = () => {
  isCancelled.value = true
  if(interval.value) {
    clearInterval(interval.value)
    interval.value = null
  }
  emit('closed')
}

const generateContract = async () => {
  loadingContract.value = true
  try {
    const res = await DocumentsService.signGeneratedDocumentViaOnlineContractContract(patient.value.id, props.generatedDoc.id)
    documentData.value = res.data
    docId.value = documentData.value?.docCommonCardId
    console.log(docId.value)
    getSignatures()
  } catch(e) {
    console.log(e)
  } finally {
    loadingContract.value = false
  }
}

const getSignatures = async (loader = true) => {
  if(!docId.value) return
  if(loader) loadingSignatures.value = true
  try {
    const res = await DocumentsService.getOnlineContractSignatures(patient.value.id, props.generatedDoc.id)
    patientSignature.value = res.data?.patientSignature || null
    clinicSignature.value = res.data?.clinicSignature || null
  } catch(e) {
    console.log(e)
  } finally {
    loadingSignatures.value = false
  }
}

const getContract = async () => {
  try {
    const res = await DocumentsService.getOnlineContractData(patient.value.id, props.generatedDoc.id)
    documentData.value = res.data
  } catch(e) {
    console.log(e)
  }
}

const addPatient = async () => {
  loadingParticipant.value = true
  try {
    const res = await DocumentsService.signGeneratedDocumentViaOnlineContractPatient(patient.value.id, props.generatedDoc.id)
    patientParticipantId.value = res.data.patient_id
    clinicParticipantId.value = res.data.clinic_id
  } finally {
    loadingParticipant.value = false
  }
}

const sendToPatientForSign = async () => {
  loadingParticipantSign.value = true
  try {
    const res = await DocumentsService.signGeneratedDocumentViaOnlineContractParticipantToSign(
        patient.value.id,
        props.generatedDoc.id,
        patientParticipantId.value
    )
    sentToPatient.value = res.data
  } catch (e) {
    console.log(e)
  } finally {
    loadingParticipantSign.value = false
  }
}

const sendToClinicForSign = async () => {
  loadingClinicSign.value = true
  try {
    const res = await DocumentsService.signGeneratedDocumentViaOnlineContractParticipantToSign(
        patient.value.id,
        props.generatedDoc.id,
        'clinic'
    )
    clinicSignLink.value = res.data
  } catch (e) {
    console.log(e)
  } finally {
    loadingClinicSign.value = false
  }
}

const launchTimer = () => {
  interval.value = setInterval(() => {
    if(isCancelled.value) {
      clearInterval(interval.value)
      interval.value = null
      return
    }
    refreshData(false)
  }, 10000)
}

const refreshData = (loader = true) => {
  getSignatures(loader)
  getContract()
}
</script>
