<template>
  <span v-if="role === 'superadmin'">
    <router-link :title="$t('clinic')"
                 v-b-tooltip.hover
                 class="tdclickable data-table-action"
                 :to="{ name: 'SuperAdminClinicGeneral', params: { id: object.id } }">
      <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('clinic') }}</span>
    </router-link>
    <a @click="changeStatus"
       :title="object.blocked ? $t('activate') : $t('block')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <RefreshSvg class="svg-light" v-if="object.blocked"/><BlockSvg v-if="!object.blocked"/><span class="action-button-mob-title">{{ $t('activate') }}</span>
  </a>
    <a v-if="object.clinic_tariff && object.clinic_tariff.tariff_id === 14"
       v-b-tooltip.hover
       title="Очистить данные"
       class="tdclickable data-table-action"
       @click="deleteClinicData">
      <CleanSvg class="svg-stroke-light" style="width: 19px; height: 19px;"/><span class="action-button-mob-title">Очистить данные</span>
    </a>
    <a v-if="!object.set_for_deletion_at && archivingAllowed"
       v-b-tooltip.hover
       title="Поставить в очередь на полное удаление"
       class="tdclickable data-table-action"
       @click="toggleDeletionQueue(1)">
      <BombSvg class="svg-light" style="width: 19px; height: 19px;"/><span class="action-button-mob-title">Поставить в очередь на полное удаление</span>
    </a>
    <a v-if="object.set_for_deletion_at && archivingAllowed"
       v-b-tooltip.hover
       title="Убрать из очереди на полное удаление"
       class="tdclickable data-table-action"
       @click="toggleDeletionQueue(0)">
      <RestoreSvg class="svg-light" style="width: 19px; height: 19px;"/><span class="action-button-mob-title">Убрать из очереди на полное удаление</span>
    </a>
    </span>
</template>

<script setup>
import ActivateSvg from '@/assets/svg-vue/header/note.svg'
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import RefreshSvg from '@/assets/svg-vue/form/tick.svg'
import CleanSvg from '@/assets/svg-vue/general/skull.svg'
import RestoreSvg from '@/assets/svg-vue/general/restore.svg'
import BombSvg from '@/assets/svg-vue/general/bomb.svg'
import { SuperadminService } from "@/services/api.service"
import {computed, getCurrentInstance, onBeforeMount} from "vue"
import {useStore} from "vuex"

const store = useStore()

const props = defineProps({
  object: {
    type: Object
  }
})

let i18n = null
let noty = null

const archivingAllowed = true
const role = computed(() => store.state.auth.user.role)

onBeforeMount(() => {
  const instance = (getCurrentInstance())
  i18n = instance?.ctx?.$i18n
  noty = instance?.appContext?.app?.noty
})


const changeStatus = async () => {
  try {
    const res = await SuperadminService.toggleBlockedClinic(props.object.id, !props.object.blocked)
    if(res.data) store.commit('incrementDataTableCounter')
  } catch (e) {
    console.log(e)
  }
}

const deleteClinicData = async () => {
  if(!confirm('Вы точно хотите удалить все данные этой клиники? Это действие нельзя будет отменить')) return
  const res = await SuperadminService.cleanClinicData(props.object.id)
  if(res.data) {
    noty.info(i18n.t('success_message'))
  } else {
    noty.error(i18n.t('error_message'))
  }
}

const toggleDeletionQueue = async (action) => {
  if(action) {
    if(!confirm('Поставить эту клинику на очередь на удаление? Через 30 дней после постановки на очередь клиника полностью удалится вместе с записями, пациентами, материалами и тд безвозвратно')) return
  }
  const res = await SuperadminService.toggleDeletionQueue(props.object.id, action)
  if(res.data) {
    noty.info(i18n.t('success_message'))
  } else {
    noty.error(i18n.t('error_message'))
  }
  store.commit('incrementDataTableCounter')
}
</script>
