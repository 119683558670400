<template>
<span>
  <a class="data-table-action"
     v-b-tooltip.hover
     @click="print"
     :title="$t('print')">
      <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
  <a class="data-table-action"
     v-b-tooltip.hover
     v-if="object.status !== 'finished' && rights.includes('edit_laboratory_orders')"
     @click="changeStatus"
     :title="$t('change_status')">
      <StatusSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('change_status') }}</span>
    </a>
  <a class="data-table-action"
     :title="$t('edit')"
     v-if="object.status !== 'finished'"
     v-b-tooltip.hover
     @click="edit">
    <EditSvg class="svg-light"/>
    <span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>

  <b-modal id="order-status-modal"
           hide-footer
           :title="$t('status')"
           size="sm"
           v-if="showModal"
           centered>
    <a v-for="status in laboratoryOrderStatuses"
       class="d-flex justify-content-between align-items-center pointer py-2 text-black border-bottom visit-line"
       :key="`stage-${status.id}`"
       @click="saveStatus(status.id)">
      <span>{{ $t(status.title) }}</span>
      <span class="circle"
            :class="`status-${status.id}`"></span>
    </a>
  </b-modal>

    </span>
</template>

<script setup>
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import JwtService from "@/services/jwt.service"
import {computed, getCurrentInstance, nextTick, onMounted, ref} from "vue"
import { useStore } from "vuex"
import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import StatusSvg from '@/assets/svg-vue/general/approve_tr.svg'
import {useRouter} from "vue-router"
import {laboratoryOrderStatuses} from "@/dictionaries/dictionary"
import {EloquentService} from "@/services/api.service"

const props = defineProps({
  object: {
    type: Object
  }
})

const router = useRouter()
const store = useStore()

const showModal = ref(false)
const clinic = computed(() => store.state.auth.clinic)
const rights = computed(() => store.state.auth.rights)
// const branchId = computed(() => Number(store.state.auth.branch))

let bvModal = null
let i18n = null

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
  i18n = instance?.ctx?.$i18n
})

const print = () => {
  let link = JwtService.getPrintPrelink(clinic.value)
  window.open(link+'/requirement_order/'+props.object.id, '_blank')
}

const edit = () => {
  router.push(`/patients/${props.object.record.client_id}/visits/${props.object.record_id}/requirement_order`)
}

const changeStatus = async () => {
  showModal.value = true
  await nextTick()
  bvModal.show('order-status-modal')
}

const saveStatus = async (status) => {
  if(status === 'finished') {
    const isConfirmed = confirm(i18n.t('do_you_confirm_laboratory_order_finish'))
    if(!isConfirmed) return;
  }
  let formData = new FormData()
  formData.append('status', status)
  await EloquentService.update('laboratory_order', props.object.id, formData)
  bvModal.hide('order-status-modal')
  store.commit('incrementDataTableCounter')
  setTimeout(() => {
    showModal.value = false
  }, 300)
}
</script>

<style scoped>
.data-table-action {
  cursor: pointer;
}
</style>
