export default {
	active_requirement_orders: "Faol talab - buyurtmalar",
	add_laboratory_service: "Laboratoriya xizmatini qo'shish",
	can_have_dental_work: "Tish ishlarini o'z ichiga olishi mumkin",
	delivery_date: "Yetkazib berish sanasi",
	dental_work: "Tish ishi",
	do_you_confirm_laboratory_order_finish: "Ish bemorga topshirilganligini tasdiqlaysizmi? Tasdiqlangandan so'ng shifokorning ish haqi hisoblab chiqiladi va bu ish tartibiga o'zgartirish kiritish mumkin bo'lmaydi.",
	executor: "Ijrochi",
	field_is_required: "Maydon to'ldirilishi shart",
	fitting_date: "Moslashma sanasi",
	in_clinic: "Klinikada",
	in_lab: "Laboratoriyada",
	laboratories: "Laboratoriyalar",
	laboratory: "Laboratoriya",
	must_have_dental_work: "Majburiy stomatologik ishlarni o'z ichiga oladi",
	no_dental_work_needed_for_selected_services: "Tanlangan xizmatlar uchun stomatologik ishlar kerak emas",
	no_services: "Xizmatlar yo'q",
	order_number: "Buyurtma raqami",
	requirement_order: "Talab - buyurtma",
	select_from_price: "Narxdan tanlang",
	without_attachment_to_tooth: "Tishga bog'lanmagan",
}
