<template>
    <span>
      <a class="tdclickable data-table-action"
         v-b-tooltip.hover
         @click="updateInfo"
         :title="$t('refresh')">
      <RefreshSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('refresh') }}</span>
        </a>
      <a class="tdclickable data-table-action"
         v-b-tooltip.hover
         @click="getLinkPdf"
         title="Pdf">
      <FilePdfSvg class="svg-light"/><span class="action-button-mob-title">Pdf</span>
        </a>
    </span>
</template>

<script setup>
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import FilePdfSvg from '@/assets/svg-vue/patients/file_pdf.svg'
import { SuperadminService } from "@/services/api.service"
import {useStore} from "vuex"
import {openBase64AsPdf} from "@/services/onlineContract.helper"

const props = defineProps({
  object: {
    type: Object
  }
})

const store = useStore()

const updateInfo = async () => {
  await SuperadminService.refreshOnlineContractPayment(props.object.online_contract_id)
  store.commit('incrementDataTableCounter')
}

const getLinkPdf = async () => {
  const res = await SuperadminService.getOnlineContractOrderLink(props.object.online_contract_id)
  openBase64AsPdf(res.data)
}
</script>
