<template>
  <div :class="`status-${object.status}`">
    {{ $t(status) }}
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDTreatmentStatus",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    status() {
      if(this.object.status === 'confirmed') return 'confirmed_male'
      return this.object.status
    }
  }
}
</script>

<style scoped lang="scss">
  .status-treatment_complete, .status-finished {
    color: $green-color;
  }
  .status-active, .status-rejected, .status-stopped, .status-in_process {
    color: $danger-red;
  }
  .status-in_process {
    color: $warning-yellow;
  }
</style>
