const blocksToCheck = [
	'hde-contact-container',
	'hde-container',
	'hde-contact-container-1',
	'hde-container-1',
]


const launchHelpDeskeddy = async () => {
	let found = false
	blocksToCheck.forEach(blockId => {
		const content = document.getElementById(blockId)
		if(content) {
			content.style.display = 'block'
			found = true
		}
	})
	if(found) return

	const deskeddyScript = document.createElement('script')
	deskeddyScript.setAttribute('src', '//cdn5.helpdeskeddy.com//js/contact-widget.js')
	deskeddyScript.setAttribute('id', 'hde-contact-widget')
	deskeddyScript.setAttribute('data-assets-host', '//cdn5.helpdeskeddy.com/')
	deskeddyScript.setAttribute('data-host', 'dentistplus.helpdeskeddy.com')
	deskeddyScript.setAttribute('data-lang', 'ru')
	deskeddyScript.defer = true
	document.head.appendChild(deskeddyScript)
}

const hideHelpDeskeddy = () => {
	blocksToCheck.forEach(blockId => {
		const content = document.getElementById(blockId)
		if(content) content.style.display = 'none'
	})
}

export { launchHelpDeskeddy, hideHelpDeskeddy }
