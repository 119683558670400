export default {
    agreement_print: "Kelishuv muhri",
    agreement_template: "Kelishuv shabloni",
    agreements: "Bitimlar",
    are_you_sure_you_want_to_delete_template: "Haqiqatan ham shablonni o‘chirmoqchimisiz?",
    bite_templates: "Tishlam",
    choose_fields_for_autocomplete: "Avtomatik ravishda to‘ldirish uchun kerakli qiymatlarni tanlang:",
    complaints_templates: "Shikoyatlar",
    consents: "Rozilik",
    contracts: "Shartnomalar",
    contract_template: "Shartnoma shabloni",
    development: "Rivojlanish",
    development_templates: "Rivojlanish",
    diseases: "Kasalliklar",
    diseases_templates: "Kasalliklar",
    document_template_word_file_note: "Siz shablon sifatida word faylini biriktirishingiz mumkin. Word faylida o‘rnatish joylari $ belgisi va qo‘shilgan qavs bilan belgilanishi kerak va faqat katta harflar ishlatilishi kerak. Masalan: ${FIO_CLIENT}.",
    full_treatment_template: "Davolanish shabloni",
    general_template: "Shablon",
    generate: "Yaratish",
    generated: "Yaratilgan",
    inspection_templates: "Ko‘rik",
    medication_templates: "Davolanish",
    not_generated: "Yaratilmagan",
    objectively_templates: "Xolisona",
    plansurvey_templates: "Tekshiruv rejasi",
    plansurvey: "Tekshiruv rejasi",
    plan_treatment_template: "Tekshiruv rejasi shabloni",
    recommendations_templates: "Tavsiyalar",
    research: "Tadqiqot ma’lumotlari",
    researches: "Tadqiqotlar",
    research_templates: "Tadqiqot ma’lumotlari",
    see_in_onlinecontract: "OnlineContract da ko‘rish",
    see_in_trustme: "TrustMe da ko‘rish",
    sign_via_onlinecontract: "OnlineContract orqali imzolash",
    sign_via_trustme: "TrustMe orqali imzolash",
    signed: "Imzolangan",
    signed_via_onlinecontract: "OnlineContract orqali imzolangan",
    signed_via_trustme: "TrustMe orqali imzolangan",
    standard_diagnoses: "Standart diagnozlar",
    subjectively_templates: "Noxolis",
    teeth_medication: "Davolanish",
    teeth_plansurvey: "Tekshiruv rejasi",
    teeth_recommendations: "Tavsiyalar",
    templates_treatments_categories: "Davolanish shablonlarining toifalari",
    upload_signed: "Imzolangan faylni yuklash",
    uploaded: "Yuklangan"
}
