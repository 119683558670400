import JwtService from "@/services/jwt.service"
import { UsersService, ClinicService, EloquentService, PatientService, CRMService } from "@/services/api.service"
import LangHelper from "@/services/lang.helper"
import BranchHelper from "@/services/branch.helper"
import i18n from '@/lang/i18n'
import moment from 'moment'
import axios from "axios"

const state = {
    errors: {},
    user: null,
    clinic: null,
    branches: [],
    doctors: [],
    curators: [],
    superadmins: [],
    admins: [],
    assistants: [],
    chairs: [],
    branchChairs: [],
    tasks: [],
    units: {},
    rights: [],
    pay_methods: [],
    record_reasons: [],
    deposit_accounts: [],
    professions: [],
    record_tags: [],
    clientSources: [],
    CRMStages: [],
    patientCanbanStatuses: [],
    superadmin: JwtService.isSuperAdmin(),
    branch: BranchHelper.getBranch(),
    isLogged: !!JwtService.getToken(),
    paid_till: null,
    free_day: null,
    clinicTariff: null,
    urlAfterLogin: null,
    useProxy: true,
    flutterApp: false,

    headerUpdateCounter: 0,

    headerCounters: {
        tasks: 0,
        birthdays: 0,
        waiting: 0,
        notifications: 0,
        unread: 0,
    }
}

const getters = {
    user(state) {
        return state.user
    },
    isLogged(state) {
        return state.isLogged
    },
    branchObject() {
        return state.branches.find(obj => obj.id === state.branch)
    },
    isPatient(state) {
        return state.user && Number(state.user.role_id) === 5
    },
    isDoctor(state) {
        return state.user && Number(state.user.role_id) === 4
    },
    isDoctorOrAssistant(state) {
        return state.user && [4,7].includes(Number(state.user.role_id))
    },
    getCurrencyName(state) {
        // console.log(state.clinic)
        return state.clinic.currency ? state.clinic.currency.name : ''
    },
    hasFreeTariff() {
        if(!state.clinicTariff) return false
        return state.clinicTariff.tariff_id == 1
    },
    isPaid(state) {
        let today = moment().format('YYYY-MM-DD')
        if(state.free_day && state.free_day == today) return true
        if(state.paid_till && state.paid_till >= today) return true
        return false
    },
    usesBonuses(state) {
        return state.rights.includes('bonuses') && (state.clinic?.clinic_additional?.bonuses_enabled || false)
    },
    deductsAcquiringFromSalaries(state) {
        return state.clinic?.clinic_additional?.deduct_acquiring_from_salary || false
    },
    recordTimingIsOn(state) {
        return state.clinic?.clinic_additional?.record_timing_enabled || false
    },
    ppoSettings(state) {
        return state.clinic?.ppo_settings ? JSON.parse(state.clinic.ppo_settings) : {}
    },
    kkmEnabled(state, getters) {
        return getters.ppoSettings[`kkm_enabled${state.branch}`] === '1'
    },
    jetAPIEnabled(state, getters) {
        return getters.ppoSettings.jetAPIRealInstanceId && getters.ppoSettings.jetAPIToken
    },
    onlineContractEnabled(state, getters) {
        return !!getters.ppoSettings.online_contract_token
    },
    jetAPIPanelEnabled(state, getters) {
        return getters.jetAPIEnabled && getters.ppoSettings.jetAPIShowPanel && (getters.ppoSettings.jetAPIPanelUsers || []).includes(state.user.id)
    },
    getPPOAttribute: (state, getters) => (attribute) => {
        return getters.ppoSettings[attribute] || null
    },
    listLeadResponsible: (state) => (lead) => {
        if(lead.multiple_responsible && lead.multiple_responsible.length) {
            if(state.admins.length === lead.multiple_responsible.length) {
                if(state.admins.map(x => x.id).sort().join(',') === lead.multiple_responsible.map(x => x.id).sort().join(',')) {
                    return i18n.global.t('all_administrators')
                }
            }
            return lead.multiple_responsible.map(x => x.full_name).join(', ')
        }
        if(lead.responsible) {
            return lead.responsible.full_name
        }
        return ''
    },
    countryId(state) {
        return Number(state.clinic?.country_id) || 0
    }
}

const mutations = {
    setError(state, error) {
        state.errors = error
    },
    setBranch(state, branch) {
        state.branch = branch
        BranchHelper.saveBranch(branch)
    },
    setUser(state, user) {
        state.user = user
    },
    setClientSources(state, sources) {
        state.clientSources = sources
    },
    setUseProxy(state, value) {
        state.useProxy = value
    },
    setCRMStages(state, value) {
        state.CRMStages = value
    },
    setPatientCanbanStatuses(state, value) {
        state.patientCanbanStatuses = value
    },
    setAuth(state, data) {
        state.isLogged = true
        state.user = data.user
        state.errors = {}
        if(data.token) {
            JwtService.saveToken(data.token, data.superadmin ? data.superadmin : false)
        }
        if(!data.use_proxy) {
            axios.defaults.baseURL = process.env.VUE_APP_API_URL
            state.useProxy = false
        }
        if(data.clinic) {
            state.clinic = data.clinic
            if(state.clinic.lang_code) {
                i18n.global.locale = state.clinic.lang_code
                LangHelper.saveLanguage(state.clinic.lang_code)
                moment.locale(state.clinic.lang_code)
            }
            if(state.clinic.currency) {
                window.localStorage.setItem('currency', state.clinic.currency.name)
            }
        }
        if(!state.branch && (data.user.branches || []).length) {
            let branch = data.user.branches[0].id
            state.branch = branch
            BranchHelper.saveBranch(branch)
        }
        if(data.branches) {
            state.branches = data.branches
            if(!state.branch && data.branches.length > 0) {
                let branch = data.branches[0].id
                state.branch = branch
                BranchHelper.saveBranch(branch)
            }
        }
        if(data.doctors) state.doctors = data.doctors
        if(data.curators) state.curators = data.curators
        if(data.admins) {
            state.admins = data.admins
        }
        if(data.superadmins) {
            state.superadmins = data.superadmins
        }
        if(data.assistants) {
            state.assistants = data.assistants
        }
        if(data.prices) state.prices = data.prices
        if(data.chairs) {
            state.chairs = data.chairs
            state.branchChairs = data.chairs.filter(x => Number(x.branch_id) === Number(state.branch))
        }
        if(data.units) {
            state.units = data.units
        }
        if(data.pay_methods) {
            state.pay_methods = data.pay_methods
        }
        if(data.record_reasons) {
            state.record_reasons = data.record_reasons
        }
        if(data.rights) {
            state.rights = data.rights
        }
        if(data.professions) {
            state.professions = data.professions
        }
        if(data.superadmin) {
            state.superadmin = true
        }
        if(data.record_tags) {
            state.record_tags = data.record_tags
        }
        if(data.tariff) {
            state.paid_till = data.tariff.paid_till ?? null
            state.free_day = data.tariff.free_day ?? null
            state.clinicTariff = data.tariff
        }
    },
    setClinic(state, data) {
        state.clinic = data.clinic
    },
    setBranches(state, branches) {
        state.branches = branches
    },
    setTasks(state, tasks) {
        state.tasks = tasks
    },
    setHeaderCounter(state, { counter, value } ) {
        state.headerCounters[counter] = value
    },
    incrementHeaderUpdateCounter(state) {
        state.headerUpdateCounter++
    },
    setFreeDay(state, value) {
        state.free_day = value
    },
    setPaidTill(state, value) {
        state.paid_till = value
    },
    setAdmins(state, value) {
        state.admins = value
    },
    setUrlAfterLogin(state, value) {
        state.urlAfterLogin = value
    },
    setDepositAccounts(state, value) {
        state.deposit_accounts = value
    },
    setFlutterApp(state, value) {
        state.flutterApp = value
    }
}

const actions = {
    login(context, params) {
        return new Promise(resolve => {
            UsersService.login(params).then(data => {
                const d = data.data
                context.commit('setAuth', d)
                context.commit('setMyOpenedVisit', d.my_opened_visit)
                saveCalendarSettings(context, d)
                context.dispatch('saveFCMToken', d.user)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response ? err.response.data.errors : {})
            })
        })
    },
    async logout(context) {
        if(JwtService.getToken()) {
            try {
                await UsersService.invalidateToken()
            } catch(e) {
                console.log(e)
            }
        }
        state.isLogged = false
        state.superadmin = false
        state.user = null
        state.clinic = null
        state.branch = null
        state.paid_till = null
        state.free_day = null
        state.doctors = []
        state.branches = []
        state.chairs = []
        state.tasks = []
        state.errors = {}
        window.localStorage.removeItem('currency')
        BranchHelper.saveBranch(null)
        JwtService.destroyToken()
        context.dispatch('nullifyOpenedVisit')
        context.dispatch('nullifySelectedDoctors')
        context.dispatch('dataTableLogout')
    },
    init(context) {
        return new Promise((resolve, reject) => {
            UsersService.init(state.superadmin).then(data => {
                const d = data.data
                context.commit('setAuth', d)
                context.commit('setMyOpenedVisit', d.my_opened_visit)
                context.dispatch('checkSelectedStaff', null, { root: true })
                saveCalendarSettings(context, d)
                context.dispatch('saveFCMToken', d.user)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response ? err.response.data.errors : {})
                reject(err.response ? err.response.data.errors : {})
            })
        })
    },
    saveFCMToken(context, user) {
        if(!user) return
        if(state.flutterApp) {
            window.flutter_inappwebview.callHandler('userLoggedIn', user.id).then(data => {
                if(data && data.token && data.platform) {
                    const params = {
                        token: data.token,
                        type: data.platform,
                        user_id: user.id
                    }
                    let existingToken = user.fcm_tokens.find(x => x.token === data.token)
                    if(!existingToken) UsersService.fcmToken(params)
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    saveData(context, params) {
        return new Promise((resolve, reject) => {
            ClinicService.saveData(params).then(data => {
                let d = data.data
                context.commit('setClinic', d)
                saveCalendarSettings(context, d)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response.data.errors)
                reject(err.response.data.errors)
            })
        })
    },
    getTasks(context, params = { loading: false, page: 1 }) {
        const page = params.page || 1
        return new Promise((resolve, reject) => {
            EloquentService.dataTable('task', page, params, params.loading).then(data => {
                let d = data.data.result
                const tasks = page === 1 ? d.data : context.state.tasks.concat(d.data)
                context.commit('setTasks', tasks)
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    },
    async getClientSources(context) {
        const sources = await PatientService.getClientSources()
        context.commit('setClientSources', sources.data)
    },
    async getCRMStages(context) {
        const crmStages = await CRMService.getStatuses()
        context.commit('setCRMStages', crmStages.data)
    },
    async getPatientCanbanStatuses(context) {
        const crmStages = await CRMService.getPatientCanbanStatuses()
        context.commit('setPatientCanbanStatuses', crmStages.data)
    },
    async getAdmins(context) {
        if(!context.state.admins.length) {
            const data = await ClinicService.getAdmins()
            context.commit('setAdmins', data.data)
        }
    },
    async getDepositAccounts(context) {
        if(!context.state.deposit_accounts.length) {
            const depositAccountsData = await EloquentService.dataTable('deposit_account')
            const depositAccounts = depositAccountsData?.data?.result?.data || []
            context.commit('setDepositAccounts', depositAccounts)
        }
    },
}

function saveCalendarSettings(context, data = null) {
    if(data.clinic) {
        const clinic = data.clinic
        context.commit('setTimeStep', parseInt(clinic.calendar_step))
        context.commit('setTimeFromHour', parseInt(clinic.time_start))
        let timeToHour = parseInt(clinic.time_end) + 1
        if(clinic.time_end.substr(clinic.time_end.length - 5) === '00:00') timeToHour--
        context.commit('setTimeToHour', timeToHour)
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
