export default {
    absent: "Відсутній",
    abonplata: "Абонентська плата",
    access_restriction: "Обмеження доступу",
    accountant_fio: "ПІБ головного бухгалтера",
    accrue_bonuses_on_bonus_payments: "Нараховувати бонуси на оплату бонусами",
    activate_bonus_system: "Активувати бонусну систему",
    acquiring_fee: "Комісія за оплату",
    add_field: "Додати напрям",
    add_user: "Додати користувача",
    additional_doctors: "Додатково лікарів",
    additional_modules: "Додаткові модулі",
    additional_space: "Додаткове місце",
    admins_limit: "Ліміт адміністраторів",
    Advance: "Депозит",
    advance_calculated: "Депозит",
    advance_payment: "Одноразовий платіж",
    after_discounts: "Після урахування знижок",
    after_technic_deduction: "Після відрахування техніка",
    after_visit: "Нагадування після візиту",
    akt_text: "Позначка в акті наданих послуг",
    all_administrators: "Всі адміністратори",
    all_visits: "Всі записи",
    allow: "Дозволити",
    allowed_ips: "IP адреси, з яких можливий вхід до облікового запису",
    allow_negative_deposits: "Дозволяти списання депозитів у мінус",
    api: "API",
    api_token: "API токен",
    are_you_sure_you_want_to_delete_branch: "Ви впевнені, що хочете видалити філію?",
    are_you_sure_you_want_to_delete_company: "Ви впевнені, що хочете видалити компанію?",
    are_you_sure_you_want_to_delete_notification: "Ви впевнені, що хочете видалити повідомлення?",
    are_you_sure_you_want_to_delete_source: "Ви впевнені, що хочете видалити джерело?",
    are_you_sure_you_want_to_delete_tag: "Ви впевнені, що хочете видалити позначку?",
    ask_for_more: "Запитати ще",
    ask_reason_cancel: "Запитувати причину при скасуванні візиту",
    ask_reason_not_come: "Запитувати причину при статусі 'не прийшов'",
    ask_reason_reschedule: "Запитувати причину перенесення візиту",
    assistants_connected: "Підключено модуль Помічники",
    available_disk_space: "Доступне місце на диску, МБ (0 - безліміт)",
    automatic_conflict_task_creation: "Автоматичне створення завдань під час конфлікту розкладу",
    before_discounts: "До урахування знижок",
    before_technic_deduction: "До відрахування техніка",
    before_visit: "Нагадування перед візитом",
    before_visit_day_in_advance: "Нагадування про візит за день (відразу)",
    billing: "Білінг",
    billing_payment: "Оплата",
    bonus_accrued: "Нараховано бонусів",
    bonus_adjustment: "Коригування бонусів",
    bonus_payment: "Оплата бонусом",
    bonus_percent_can_be_used: "Максимальна частка продажу, яку можна сплатити бонусами",
    bonus_percent_of_cheque: "Відсоток бонусів від продажу",
    bonus_system: "Бонусна система",
    bonus: "Бонус",
    bonuses: "Бонуси",
    branch_address: "Адреса філії",
    branch_contacts: "Контакти філії",
    branch_inn: "ІПН філії",
    branches: "Філії",
    branches_connected: "Підключено платний модуль Філії",
    by_chairs: "По кріслам",
    calendar_text_color: "Колір тексту в календарі",
    calendar_view_by_default: "Вид календаря за замовчуванням",
    cancel_and_move_reason: "Причина відміни та перенесення",
    cancel_and_move_reasons: "Причини відміни та перенесення",
    change_tariff: "Змінити тариф",
    ClientNote: "Примітка",
    client_tag: "Позначка",
    clinic: "Клініка",
    clinics: "Клініки",
    clinic_data: "Дані клініки",
    clinic_inn: "ІПН клініки",
    clinic_is_blocked: "Клініка заблокована",
    clinic_name: "Назва клініки",
    clinic_name_in_cheque: "Назва клініки в чеку",
    close: "Закрити",
    cloud_space: "Місце в хмарі",
    city: "Місто",
    communications: "Комунікації",
    company_data: "Дані компанії",
    connected_successfully: "Підключено успішно",
    connection_failed: "Помилка зв'язку",
    control_system: "Система контролю",
    countries: "Країни",
    country: "Країна",
    crm_application_created: "Створення заявки CRM",
    crm_connected: "Підключено платний модуль CRM",
    currency_rates: "Курси валют",
    data: "Дані",
    date_of_registration: "Дата реєстрації",
    day: "День",
    deduct_acquiring_from_salary: "Відраховувати комісію за оплату зарплати",
    deduct_warranty_from_salary: "Не нараховувати зарплату працівникам з гарантійного випадку",
    deleted: "Видалення",
    demo: "Демо",
    director_data: "Дані директора",
    director_fio: "ПІБ директора",
    disk_space_limit: "Ліміт дискового місця (Мб)",
    do_not_deduct_acquiring_from_technic_absolute: "Не віднімати еквайринг у техніків з абсолютним нарахуванням",
    do_not_send_notifications_if_period_passed: "Не надсилати нагадування про візит, якщо термін уже минув",
    Doctor: "Лікар",
    DoctorAssistant: "Асистент",
    doctors_limit: "Ліміт лікарів",
    egisz_prod: "ЕГІСЗ у бойовому режимі",
    enter_ip_address: "Введіть IP-адресу",
    error_code: "Код помилки",
    finance: "Фінанси",
    free: "Вільно",
    free_day: "Безплатний день",
    general: "Загальне",
    has_additional_doctors: "Є додаткові лікарі",
    higher_number_higher_priority: "Чим вищий номер, тим вища опція у списку",
    hour: "година",
    hours: "години",
    image_hidden: "Зображення приховано",
    in_development: "У розробці",
    insurance_company: "Страхова компанія",
    integrations: "Інтеграції",
    interface: "Інтерфейс",
    ioka: "Ioka",
    ip_note: "Будьте уважні при введенні IP-адреси, щоб не втратити доступ до програми. Можна використовувати, якщо у Вас статична IP-адреса в клініці.",
    jetapi_connected: "Підключено модуль JET API",
    'kit materials sync': "Прив'язка матеріалів до комплекту",
    kpp: "КПП",
    last_login: "Останній вхід",
    legal_address: "Юридична адреса",
    legal_name: "Юридична назва",
    license: "Ліцензія",
    license_issue_body: "Ким видано ліцензію",
    license_issue_date: "Дата видачі ліцензії",
    license_num: "Ліцензія #",
    location_on_map: "Розташування на карті",
    logs: "Логи",
    logs_journal: "Журнал дій",
    logo: "Логотип",
    logout_interval_timeout: "Інтервал виходу з програми при відсутності активності (для облікових записів адміністраторів)",
    max_doctor_amount: "Максимальна кіл-ть лікарів",
    Manager: "Адміністратор",
    manager2: "Менеджер",
    manual: "Ручна",
    min: "хвилин",
    min_symbols: "Мінімум символів",
    minute: "хвилина",
    minutes: "хвилин",
    model_id: "Id моделі",
    model_type: "Тип моделі",
    month: "Місяць",
    monthly_payment: "Месячний платіж",
    months_to_consider_patient_lost: "Кількість місяців, після яких пацієнт вважається загубленим",
    more_than_one_day: "Більше одного дня",
    more_than_one_visit: "Більше одного візиту",
    next_payment: "Наступний платіж",
    not_connected: "Не підключено",
    notification_channel: "Тип доставки",
    notification_setting: "Повідомлення",
    NotificationSetting: "Повідомлення",
    notification_type: "Тип повідомлення",
    notifications: "Повідомлення",
    occupied: "Зайнято",
    of_few_hours: "години",
    ogrn: "ЄДРПОУ",
    oid_ministry_rf: "OID Міністерства охорони здоров'я РФ",
    on: "Включено",
    on_pay_page: "На сторінку оплати",
    online_booking: "Онлайн-запис",
    online_booking_activate: "Активувати онлайн-запис",
    online_booking_categories: "Відображати категорії послуг",
    online_booking_feedbacks: "Активувати функціонал відгуків",
    online_booking_map: "Відображати карту філії",
    online_booking_max_days: "Максимальний період запису на днях",
    online_booking_time: "Бронювати час у календарі чи ні",
    only_after_accordance_with_trustme: "Тільки після узгодження з trustme.kz",
    only_after_payment_received: "Тілько при отриманні оплати від пацієнта",
    paid_functionality: "Оплатний функціонал",
    paid_till: "Оплачено до",
    Patient: "Пацієнт",
    patient_conditions: "Стан пацієнтів",
    patient_sources: "Джерела пацієнтів",
    patients_import: "Імпорт пацієнтів",
    pay: "Сплатити",
    payable: "Платний",
    Payment: "Оплата",
    payment_id: "Id оплати",
    pay_methods: "Способи оплати",
    pay_methods_acquiring: "Способи оплати для передачі на еквайринг-термінал",
    pay_methods_cash: "Способи оплати, що відображаються як готівка",
    pay_system: "Платіжна система",
    percent_accrual_for_administrator: "Нарахування відсотка адміністраторам",
    percent_accrual_for_personnel: "Нарахування відсотка персоналу",
    permanent_patients_hint: "Чи вважати пацієнта постійним при: а) наявності у нього мінімум двох візитів зі статусом прийшов або послугами в рахунку, навіть протягом одного дня; б) наявності у нього мінімум двох різних днів з візитами",
    period_by_default: "Період за замовчуванням",
    plan_treatment_text: "Позначка щодо лікування",
    PlanTreatment: "План лікування",
    popup: "Попап",
    price_per_additional_doctor: "Ціна за додаткового лікаря",
    price_per_additional_space: "Ціна за додаткове місце (100 Мб)",
    program_language: "Мова програми",
    prolong: "Продовжити",
    purchase: "Придбання",
    record_tag: "Ярлик для візитів",
    record_tags: "Ярлики для візитів",
    record_timing_enabled: "Система контролю 'Початок та завершення візитів'",
    Record: "Запис",
    RecordTooth: "Зуб",
    recipient: "Отримувач",
    reject: "Відхилити",
    required_fields_to_close_visit: "Обов'язкові поля для закриття візиту",
    requires_payment: "Потребує доплати",
    right: "Право",
    rights: "Права",
    salary_accrual: "Логіка нарахування процентів лікарям / адміністраторам / кураторам",
    salary_accrual_for_personnel: "Нарахуванняе зарплатні лікарям / адміністраторам / кураторам",
    salary_accrual_for_technics: "Нарахуванняе зарплатні технікам",
    send_time: "Час відправки",
    sendouts_only_in_day_time: "Надсилати масові розсилки в денний час (09.00 - 22.00)",
    server_error: "Системі не вдалося виконати ваш запит. Будь ласка, спробуйте ще раз або зверніться в службу підтримки",
    services_import: "Імпорт послуг",
    short_title: "Коротка назва",
    show_complex_materials_for_teeth: "Відображати комплекти та матеріали у вкладці \"Зуби\" в Картці лікування",
    show_description_in_forms: "Відображати коментар до візиту до формату 043, 058, історії лікування",
    show_images: "Показувати зображення",
    show_preinstalled_templates: "Показувати попередньо встановлені шаблони",
    show_teeth_general: "Відображати спільні поля у вкладці \"Зуби\" у Картці лікування",
    sign_type: "Тип підпису",
    sort_order: "Порядок сортування",
    source: "Джерело",
    sources: "Джерела",
    superadmin: "Суперадмін",
    superadministrators: "Суперадміністратори",
    supermanager: "Суперменеджер",
    super_user: "Суперкористувач",
    switched_to_version_2: "Перейшла на версію 2.0",
    sync_data: "Синхронізувати дані",
    tag: "Ярлик",
    tag_id: "Мітка",
    tags: "Позначки",
    tags_for_custom_notifications: "Теги для автоматичного виведення інформації:",
    tags_for_notifications: "Теги для автоматичного виведення інформації:",
    tariff: "Тариф",
    tariff_payment_expires_in_days: "Оплата за тарифом закінчується сьогодні | Оплата за тарифом закінчується через {day} день | Оплата за тарифом закінчується через {day} дня",
    tariffs: "Тарифи",
    task_created: "Створення задачі",
    technic_salary_accrual: "Логіка нарахування відсотків технікам",
    templates: "Шаблони",
    templates_agreements: "Шаблони згод",
    templates_categories: "Стандартні категорії",
    templates_contracts: "Шаблони договорів",
    templates_for_fields: "Шаблони для полів",
    templates_text: "Шаблони текстів",
    templates_treatments: "Шаблони лікувань",
    techsupport_panel: "Панель техпідтримки",
    test: "Тест",
    this_may_take_several_minutes_do_not_close_the_window: "Це може зайняти кілька хвилин. Не закривайте вікно",
    time_after_visit: "посля візиту",
    time_before_visit: "до візиту",
    time_interval_value: "Величина інтервалів часу",
    time_zone: "Часовий пояс",
    to_billing: "До налаштувань білінгу",
    to_the_one_who_accepted_payment: "Тому, хто приймає платіж",
    to_the_one_who_made_record: "Тому, хто робить запис",
    treatment_finished: "Лікування завершено",
    turn_on_autosign: "Увімкнути автопідпис",
    turn_off: "Відключити",
    turned_off: "Вимкнена",
    turned_on: "Увімкнена",
    'visit services sync': "Прив'язка послуг",
    ukrainian: "Українска",
    unblock_for_3_days: "Розблокувати на 3 дні",
    updated: "Змінено",
    User: "Користувач",
    use_bonuses_on_salary: "Нарахування зарплати з оплати бонусами",
    use_proxy: "Использовать прокси",
    user_id: "Id користувача",
    uzbek: "Узбецький",
    verify: "Перевірити",
    visit_cancel: "Відміна візиту",
    visit_comment: "Коментар візиту",
    visit_created: "Створення візиту",
    visit_results: "Результати візиту",
    visit_result: "Результат візиту",
    visit_type: "Тип візиту",
    warehouse_connected: "Підключено платний модуль склад",
    week: "Тиждень",
    when_changing_tariff_all_payments_will_nullify: "При зміні тарифу всі платежі будуть анульовані",
    which_branches_use_online_booking: "Які філії використовують онлайн-запис",
    which_clients_to_consider_permanent: "Яких клієнтів вважати постійними",
    work_time_from: "Час роботи з",
    work_time_to: "Час роботи до",
    your_current_ip: "Ваш поточний IP",
    your_tariff_plan_is_not_paid: "Ваш тарифний план не сплачено",
    zero_unlimited: "0 - необмежено",
}
