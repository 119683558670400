<template>
  <div class="mb-4" v-if="hasRight">
    <b-button v-spaceduck-collapse="`online-contract-kz`"
              class="btn-collapse">
      Online-contract kz
    </b-button>
    <spaceduck-collapse id="online-contract-kz"
                        @show="onShow">
      <div class="pt-4">
        <div v-if="!OCKZConnected">
          <div v-if="loginMode === 'register'">
            <InputGroup v-model="username"
                        :show-errors="true"
                        validation-id="username"
                        :label="$t('login')"/>
            <InputGroup v-model="email"
                        :show-errors="true"
                        validation-id="email"
                        :label="$t('email')"/>
            <InputGroup v-model="password"
                        type="password"
                        :show-errors="true"
                        validation-id="password"
                        :label="$t('password')"/>
          </div>
          <div v-if="loginMode === 'enter_token'">
            <InputGroup v-model="token"
                        :show-errors="true"
                        validation-id="token"
                        label="Токен"/>
            <InputGroup v-model="password"
                        type="password"
                        :show-errors="true"
                        validation-id="password"
                        :label="$t('password')"/>
          </div>
          <div class="mt-3 mb-3">
            <a class="text-primary pointer" v-if="loginMode === 'register'" @click="loginMode = 'enter_token'">Уже зарегистрированы? Введите токен и пароль из личного кабинета online-contract.kz</a>
            <a class="text-primary pointer" v-if="loginMode === 'enter_token'" @click="loginMode = 'register'">Зарегистрироваться в системе online contract</a>
          </div>
          <button class="btn btn-themed btn-themed-squared"
                  @click="register"
                  :disabled="loading">
            {{ $t('connect') }}
          </button>
        </div>
        <div v-if="OCKZConnected">
          <div class="text-center" v-if="loading">
            <b-spinner label="Spinning"></b-spinner>
          </div>

          <div v-if="clientData">

            <div>{{ $t('tariff') }}: <b>{{ clientData.eAdmLicenseTypeName }}</b></div>
            <div>Клиент верифицирован: {{ clientData.isClientSignVerified  ? '✅' : '❌' }}</div>
            <div>Завершена настройка профиля: {{ clientData.isClientSetupComplete ? '✅' : '❌' }}</div>

            <div class="mt-3"
                 v-if="!clientData.isClientSignVerified">
              <a @click="goToVerification"
                 class="btn btn-themed btn-themed-squared px-5">
                {{ $t('verify') }}
              </a>
            </div>

            <b-tabs nav-class="sub-menu"
                    content-class="pt-4"
                    class="w-100 mt-3">
              <b-tab active :title="$t('company_data')" v-if="companyData">
                <div>
                  <!--              {{ companyData }}-->
                  <h5>{{ $t('company_data') }}</h5>
                  <div class="text-muted small">{{ companyData.dictContragentId }}</div>
                  <div class="mt-3">
                    <InputGroup v-model="companyData.name" label="Название ТОО / ИП" class="mb-3"/>
                    <InputGroup v-model="companyData.email" :label="$t('email')" class="mb-3"/>
                    <InputGroup v-model="companyData.iinbin" :label="$t('bin')" class="mb-3"/>
                    <InputGroup v-model="companyData.address" :label="$t('address')" class="mb-3"/>
                    <InputGroup v-model="companyData.smsSignPhoneNumber" :label="$t('phone')" class="mb-3"/>
                    <!--                <SelectGroup v-model="companyData.eDetDocumentSignType"-->
                    <!--                             :showlabel="true"-->
                    <!--                             variant="white"-->
                    <!--                             :label="$t('sign_type')"-->
                    <!--                             :options="onlineContractSignTypes"-->
                    <!--                             class="mb-3"/>-->
                                    <SelectGroup v-model="companyData.eDictContragentType"
                                                 :showlabel="true"
                                                 variant="white"
                                                 :label="$t('type')"
                                                 :options="onlineContractLegalTypes"
                                                 class="mb-3"/>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-themed btn-themed-squared"
                            @click="updateCompany"
                            :disabled="loading">
                      {{ $t('save') }}
                    </button>
                  </div>
                  <hr/>
                </div>
              </b-tab>

              <b-tab :title="$t('director_data')">
                <div>
                  <!--              {{ employeeData }}-->
                  <h5>{{ $t('director_data') }}</h5>
                  <div class="text-muted small">{{ employeeData.dictContragentId }}</div>
                  <div class="mt-3">
                    <InputGroup v-model="employeeData.name" :label="$t('fio')" class="mb-3"/>
                    <InputGroup v-model="employeeData.email" :label="$t('email')" class="mb-3"/>
                    <InputGroup v-model="employeeData.iinbin" :label="$t('iin')" class="mb-3"/>
                    <InputGroup v-model="employeeData.address" :label="$t('address')" class="mb-3"/>
                    <InputGroup v-model="employeeData.smsSignPhoneNumber" :label="$t('phone')" class="mb-3"/>
                    <!--                <SelectGroup v-model="employeeData.eDetDocumentSignType"-->
                    <!--                             :showlabel="true"-->
                    <!--                             variant="white"-->
                    <!--                             :label="$t('sign_type')"-->
                    <!--                             :options="onlineContractSignTypes"-->
                    <!--                             class="mb-3"/>-->
                    <!--                <SelectGroup v-model="employeeData.eDictContragentType"-->
                    <!--                             :showlabel="true"-->
                    <!--                             variant="white"-->
                    <!--                             :label="$t('type')"-->
                    <!--                             :options="onlineContractLegalTypes"-->
                    <!--                             class="mb-3"/>-->
                  </div>
                  <div class="text-right">
                    <button class="btn btn-themed btn-themed-squared"
                            @click="updateEmployee"
                            :disabled="loading">
                      {{ $t('save') }}
                    </button>
                  </div>
                  <hr/>
                </div>
              </b-tab>

              <b-tab title="Настройки пациента">
                <div>
                  <h5>Настройки подписи пользователя</h5>
                  <SelectGroup v-model="patientSendType"
                               :showlabel="true"
                               variant="white"
                               :null-option="false"
                               label="Канал получения ссылки на договор"
                               :options="onlineContractSendTypes"
                               class="mb-3"/>
                  <SelectGroup v-model="patientSignType"
                               :showlabel="true"
                               variant="white"
                               :null-option="false"
                               :label="$t('sign_type')"
                               :options="onlineContractSignTypes"
                               class="mb-3"/>
                  <div class="text-right">
                    <button class="btn btn-themed btn-themed-squared"
                            @click="savePatientSettings"
                            :disabled="loading">
                      {{ $t('save') }}
                    </button>
                  </div>
                  <hr/>
                </div>
              </b-tab>

              <b-tab lazy
                     :title="$t('payments')">
                <OnlineContractKZPayments/>
                <hr/>
              </b-tab>

            </b-tabs>

            <div>
              <button class="btn-themed btn-themed-squared btn-outline-danger"
                      @click="resetConnection">
                Сбросить подключение
              </button>
            </div>

          </div>
        </div>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeMount, ref } from "vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import { useStore } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
import { ClinicService, DocumentsService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import {
  onlineContractSignTypes,
  onlineContractSendTypes,
  onlineContractLegalTypes,
  countries
} from "@/dictionaries/dictionary"
import OnlineContractKZPayments from "@/components/parts/settings/OnlineContractKZPayments.vue"

const store = useStore()
let i18n = null
let noty = null

const loginMode = ref('register')
const email = ref('')
const password = ref('')
const username = ref('')
const token = ref('')
const clientData = ref('')
const companyData = ref('')
const employeeData = ref('')
const patientSignType = ref(1)
const patientSendType = ref(1)
const loading = ref(false)

const clinic = computed(() => store.state.auth.clinic)
const ppoSettings = computed(() => store.getters.ppoSettings)
const rights = computed(() => store.state.auth.rights)
const superadmin = computed(() => store.state.auth.superadmin)
const hasRight = computed(() => rights.value.includes('online_contract') && Number(clinic.value.country_id) === countries.KAZAKHSTAN)

const OCKZConnected = computed(() => {
  return !!ppoSettings.value?.online_contract_token
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

const onShow = async () => {
  patientSendType.value = ppoSettings.value?.online_contract_patient_send_type || 1
  patientSignType.value = ppoSettings.value?.online_contract_patient_sign_type || 1
  if(OCKZConnected.value) {
    await loadData()
  }
}

const loadData = async () => {
  loading.value = true
  const res = await DocumentsService.getOnlineContractKzData()
  clientData.value = res.data.clientData
  companyData.value = res.data.companyData
  // companyData.value.eDictContragentType = res.data.eDictContragentType
  companyData.value.eDetDocumentSignType = 0
  employeeData.value = res.data.employeeData
  employeeData.value.eDictContragentType = 3
  employeeData.value.eDetDocumentSignType = 0
  loading.value = false
}

const register = async () => {
  try {
    const res = await DocumentsService.registerInOnlineContractKz({
      email: email.value,
      username: username.value,
      password: password.value,
      token: token.value,
      type: loginMode.value
    })
    if(res.data) {
      noty.info(i18n.t('success_message'))
      await store.dispatch('init')
      await loadData()
    }
  } catch (e) {
    console.log(e)
  }
}

const updateCompany = async () => {
  try {
    const res = await DocumentsService.updateOnlineContractKzCompany({
      email: companyData.value.email,
      name: companyData.value.name,
      iinbin: companyData.value.iinbin,
      address: companyData.value.address,
      smsSignPhoneNumber: companyData.value.smsSignPhoneNumber,
      eDetDocumentSignType: Number(companyData.value.eDetDocumentSignType),
      eDictContragentType: Number(companyData.value.eDictContragentType),
    })
    if(res.data) {
      noty.info(i18n.t('success_message'))
      await loadData()
    }
  } catch (e) {
    console.log(e)
  }
}

const updateEmployee = async () => {
  try {
    const res = await DocumentsService.updateOnlineContractKzEmployee({
      email: employeeData.value.email,
      name: employeeData.value.name,
      iinbin: employeeData.value.iinbin,
      address: employeeData.value.address,
      smsSignPhoneNumber: employeeData.value.smsSignPhoneNumber,
      eDetDocumentSignType: Number(employeeData.value.eDetDocumentSignType),
      eDictContragentType: Number(employeeData.value.eDictContragentType),
    })
    if(res.data) {
      noty.info(i18n.t('success_message'))
      await loadData()
    }
  } catch (e) {
    console.log(e)
  }
}

const goToVerification = async () => {
  const res = await DocumentsService.getVerificationLink()
  if(res.data) {
    window.open(res.data, '_blank')
  }
}

const resetConnection = async () => {
  await DocumentsService.resetOnlineContractKz()
  await store.dispatch('init')
}

const savePatientSettings = async () => {
  await ClinicService.savePPOAttribute([
    'online_contract_patient_send_type',
    'online_contract_patient_sign_type',
  ], [
    patientSendType.value,
    patientSignType.value,
  ], superadmin.value, clinic.value.id)
  noty.info(i18n.t('success_message'))
}


</script>
