export default {
    active_requirement_orders: "Active requirement orders",
    add_laboratory_service: "Add laboratory service",
    can_have_dental_work: "Can have dental work",
    delivery_date: "Delivery date",
    dental_work: "Dental work",
    do_you_confirm_laboratory_order_finish: "Do you confirm that the work has been handed over to the patient? After confirmation, the doctor's salary will be accrued, and changes to this work order will be impossible.",
    executor: "Executor",
    field_is_required: "Field is required",
    fitting_date: "Fitting date",
    in_clinic: "In clinic",
    in_lab: "In laboratory",
    laboratories: "Laboratories",
    laboratory: "Laboratory",
    must_have_dental_work: "Must have dental work",
    no_dental_work_needed_for_selected_services: "No dental work needed for selected services",
    no_services: "No services",
    order_number: "Order number",
    requirement_order: "Requirement - order",
    select_from_price: "Select from price",
    without_attachment_to_tooth: "Without attachment to a tooth",
}
