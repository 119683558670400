export default {
    agreement_print: "Друк угоди",
    agreement_template: "Шаблон угоди",
    agreements: "Угоди",
    are_you_sure_you_want_to_delete_template: "Ви впевнені, що хочете видалити шаблон?",
    bite_templates: "Прикус",
    choose_fields_for_autocomplete: "Виберіть необхідні значення для автозаповнення:",
    complaints_templates: "Скарги",
    consents: "Згоди",
    contracts: "Договори",
    contract_template: "Шаблон договору",
    development: "Розвиток",
    development_templates: "Розвиток",
    diseases: "Хвороби",
    diseases_templates: "Хвороби",
    document_template_word_file_note: "Ви можете прикріпити файл word як шаблон. В файлі word необхідно позначати теги знаком $ і фігурними дужками і використовувати тільки великі літери. Наприклад: ${FIO_CLIENT}.",
    full_treatment_template: "Шаблон лікування",
    general_template: "Шаблон",
    generate: "Сформувати",
    generated: "Сформовано",
    inspection_templates: "Огляд",
    medication_templates: "Лікування",
    not_generated: "Не сформовано",
    objectively_templates: "Об'єктивно",
    plansurvey_templates: "План обстеження",
    plansurvey: "План обстеження",
    plan_treatment_template: "Шаблон плану лікування",
    recommendations_templates: "Рекомендації",
    research: "Дані досліджень",
    researches: "Дослідження",
    research_templates: "Дані досліджень",
    see_in_onlinecontract: "Переглянути в OnlineContract",
    see_in_trustme: "Переглянути в TrustMe",
    sign_via_onlinecontract: "Підписати через OnlineContract",
    sign_via_trustme: "Підписати через TrustMe",
    signed: "Підписаний",
    signed_via_onlinecontract: "Підписаний через OnlineContract",
    signed_via_trustme: "Підписаний через TrustMe",
    standard_diagnoses: "Стандартні діагнози",
    subjectively_templates: "Суб'єктивно",
    teeth_medication: "Лікування",
    teeth_plansurvey: "План обстеження",
    teeth_recommendations: "Рекомендації",
    templates_treatments_categories: "Категорії шаблонів лікувань",
    upload_signed: "Завантажити підписаний",
    uploaded: "Завантажений"
}
