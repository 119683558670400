<template>
  <div class="form-group">
    <label class="form-label"
           :data-content="$t(field.title)">
      <span class="">{{ $t(field.title) }}</span>
      <span v-if="required"> *</span>
    </label>
    <table class="table data-table sm"
           v-if="object.services && object.services.length > 0">
      <thead>
      <tr>
        <th>{{ $t('title') }}</th>
        <th>{{ $t('amount') }}</th>
        <th>{{ $t('cost') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(s, sind) in object.services"
          :key="`service-row-${sind}`">
        <td>{{ s.name }}</td>
        <td>
          <input type="text" class="form-control sm"
                 @keyup="amountChanged(sind, $event)"
                 :value="s.pivot.amount"/>
        </td>
        <td>
          {{ formatCurrency(s.cost_branch * s.pivot.amount) }}
        </td>
        <td>
          <a class="pointer"
             @click="deleteService(sind)">
            <DeleteSvg/>
          </a>
        </td>
      </tr>
      <tr class="font-weight-bold">
        <td colspan="2">{{ $t('total') }}</td>
        <td colspan="2">{{ formatCurrency(totalCost) }}</td>
      </tr>
      </tbody>
    </table>
    <Autocomplete :placeholder="$t('add_service')"
                  model="service"
                  class="autocomplete-operation-material w-100"
                  :render-title="(f) => `${f.title} (${f.price_p})`"
                  v-model="autocompleteService"/>
    <div class="row mt-3">
      <div class="col-md-6">
        <InputGroup type="number"
                    :label="$t('discount') + ' %'"
                    @change="changeDiscount"
                    @keyup="(($event.target.value > 100 && object.discount_type === 'percent') ? object.discount_percent = 100 : $event.target.value < 0 ? object.discount_value = 0 : '')"
                    v-model="object.discount_percent"/>
      </div>
      <div class="col-md-6">
        <InputGroup :label="`${$t('total_with_discount')} (${currency})`"
                    type="number"
                    @input="totalCostWithDiscountFieldChanged"
                    v-model="totalCostWithDiscountField"/>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment"
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import Autocomplete from "@/components/parts/general/Autocomplete"
import {formatCurrency} from "@/extensions/filters/filters"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "ServiceDatatableFormComplexServiceServices",
  components: {
    InputGroup,
    Autocomplete,
    DeleteSvg
  },
  data() {
    return {
      autocompleteService: null,
      totalCostWithDiscountField: 0
    }
  },
  props: {
    object: {
      type: Object
    },
    field: {},
    required: {},
    value: {},
  },
  methods: {
    formatCurrency,
    deleteService(index) {
      this.object.services.splice(index, 1)
      this.recountAbsoluteField()
    },
    emit(services) {
      this.$emit('input', services)
    },
    amountChanged(index, e) {
      let val = e.target.value
      let services = this.object.services ? this.copyObject(this.object.services) : []
      services[index].pivot.amount = val
      this.emit(services)
      this.recountAbsoluteField()
    },
    totalCostWithDiscountFieldChanged(val) {
      if(this.totalCost === 0) return;
      this.object.discount_percent = Math.round((this.totalCost - val) / this.totalCost * 1000000) / 10000
      if((this.object.discount_percent * this.totalCost) != val) {
        this.object.discount_value = this.totalCost - val
        this.object.discount_type = 'absolute'
      }
    },
    changeDiscount() {
      this.recountAbsoluteField()
      this.object.discount_value = 0
      this.object.discount_type = 'percent'
    },
    recountAbsoluteField() {
      this.$nextTick(() => {
        this.totalCostWithDiscountField = this.object.discount_type === 'percent' ? this.totalCostWithDiscount : this.totalCost - this.object.discount_value
      })
    }
  },
  mounted() {
    this.recountAbsoluteField()
  },
  computed: {
    currency() {
      return window.localStorage.getItem('currency')
    },
    totalCost() {
      return this.object.services ? this.object.services.reduce((acc, curr) => acc + (curr.pivot.amount * Number(curr.cost || curr.cost_branch)), 0) : 0
    },
    totalCostWithDiscount() {
      console.log(this.totalCost)
      console.log(this.object.discount_percent)
      return Math.round(this.totalCost - (this.totalCost * (this.object.discount_percent || 0) / 100))
    }
  },
  watch: {
    autocompleteService(val) {
      if(val) {
        val.name = val.title
        val.service_id = val.id
        val.pivot = { amount: 1 }
        let services = this.object.services ? this.copyObject(this.object.services) : []
        services.push(val)
        this.emit(services)
        this.recountAbsoluteField()
      }
    },
  }
}
</script>
