<template>
<span>
  <a class="data-table-action"
     :title="$t('edit')"
     v-b-tooltip.hover
     @click="edit">
    <EditSvg class="svg-light"/>
    <span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
    </span>
</template>

<script setup>
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import JwtService from "@/services/jwt.service"
import { computed } from "vue"
import { useStore } from "vuex"

const props = defineProps({
  object: {
    type: Object
  }
})

const store = useStore()

const clinic = computed(() => store.state.auth.clinic)
const branchId = computed(() => Number(store.state.auth.branch))

const edit = () => {
  let link = JwtService.getPrintPrelink(clinic.value)
  let url = link +'/document/'+props.object.patient_id+'/ndfl?t=r&branchId='+branchId.value+'&reportId=' + props.object.id+'&isForm=1'
  window.open(url, '_blank')
}
</script>
