<template>
  <b-modal id="patient-ndfl-modal"
           :title="$t('ndfl_statement')"
           centered>
    <b-row class="mt-4">
      <b-col md="6" cols="12">
        <DateGroup :label="$t('date_from')"
                   :required="true"
                   v-model="from"/>
      </b-col>
      <b-col md="6" cols="12">
        <DateGroup :label="$t('date_to')"
                   :required="true"
                   v-model="to"/>
      </b-col>
    </b-row>
    <InputGroup :label="$t('document_number')"
                v-model="docNumber"/>

    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading"
                @click="generate"
                class="btn-themed align-middle btn-themed-squared px-3 w-auto">
          {{ $t('generate') }}
        </button>
      </div>
    </template>

  </b-modal>
</template>

<script setup>
import DateGroup from "@/components/form/DateGroup.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import {computed, getCurrentInstance, onBeforeMount, ref} from "vue"
import { useStore } from "vuex"
import { createAndOpenNDFLReport } from "@/services/ndfl.helper"

const store = useStore()

let bvModal = null

const clinic = computed(() => store.state.auth.clinic)
const patient = computed(() => store.state.patient.patient)
const branchId = computed(() => Number(store.state.auth.branch))

const from = ref(null)
const to = ref(null)
const docNumber = ref(null)
const loading = ref(false)

onBeforeMount(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
})

const generate = async () => {
  loading.value = true
  try {
    await createAndOpenNDFLReport(
        clinic.value,
        branchId.value,
        patient.value,
        from.value,
        to.value,
        docNumber.value,
        function() {
          store.commit('incrementDataTableCounter')
          bvModal.hide('patient-ndfl-modal')
        }
    )
  } finally {
    loading.value = false
  }
}
</script>
