<template>
  <div/>
</template>

<script setup>
import {computed, onMounted} from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useCookies } from "vue3-cookies"

const store = useStore()
const router = useRouter()
const { cookies } = useCookies()

const user = computed(() => store.state.auth.user)
const clinic = computed(() => store.state.auth.clinic)
const flutterApp = computed(() => store.state.auth.flutterApp)
let logoutTimer = null
const activityKey = 'last_activity'

onMounted(() => {
  if(!flutterApp.value) {
    document.onmousemove = resetLogoutTimer
    document.onkeypress = resetLogoutTimer
    resetLogoutTimer()
  }
})

const resetLogoutTimer = () => {
  if(logoutTimer) {
    clearTimeout(logoutTimer)
  }
  if(![2,3].includes(user.value?.role_id || null)) return
  const logoutTimeout = clinic.value?.logout_timeout ? parseInt(clinic.value.logout_timeout) : 0
  if(logoutTimeout > 0) {
    if(getTimeSinceLastActivity() > logoutTimeout) {
      logout()
      return
    }
    cookies.set(activityKey, (new Date().getTime()).toString())
    logoutTimer = setTimeout(logout, logoutTimeout * 1000)
  }
}

const getTimeSinceLastActivity = () => {
  const lastTime = cookies.get(activityKey)
  if(!lastTime) return 0
  const now = new Date().getTime()
  const diff = now - lastTime
  return diff / 1000
}

const logout = async () => {
  cookies.remove(activityKey)
  await store.dispatch('logout')
  router.push('/login').catch(()=>{})
}
</script>
