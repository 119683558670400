export default {
    "-": "-",
    actions: "Əməliyyatlar",
    actions_with_selected: "Seçilənlərlə əməliyyatlar",
    active: "Aktiv",
    add: "Əlavə et",
    add_attachment: "Əlavəni əlavə et",
    add_condition: "Şərt əlavə et",
    all: "Hamısı",
    app_is_in_maintenance_mode: "Proqramda texniki işlər aparılır. Tezliklə geri qayıdacağıq",
    are_you_sure_you_want_to_delete: "Siz həqiqətən materialı silmək istəyirsiniz?",
    are_you_sure_you_want_to_logout: "Siz həqiqətən çıxmaq istəyirsiniz?",
    authorization: "Avtorizasiya",
    authorize_in_system: "Sistemə daxil olun",
    azerbaijani: "Azərbaycanca",
    balance: "Depozit",
    cancel: "Ləğv et",
    change: "Dəyişdir",
    change_password: "Şifrəni dəyişdir",
    comment: "Şərh",
    confirm_deletion: "Silinməni təsdiq edirsiniz?",
    contacts: "Əlaqələr",
    contains: "Əhatə edir",
    continue: "Davam et",
    currency: "Valyuta",
    delete: "Sil",
    does_not_equal: "Bərabər deyil",
    done: "Hazırdır",
    down: "Aşağı",
    edit: "Redaktə et",
    email: "Email",
    english: "İngilis",
    error: "Xəta",
    error_message: "Nəsə səhv getdi, əməliyyat uğurla başa çatmadı",
    equals: "Bərabərdir",
    excel: "Excel",
    fact: "Fakt",
    file: "Fayl",
    file_does_not_exist: "Fayl tapılmadı",
    file_size_is_too_big: "Faylın həcmi çox böyükdür",
    filled: "Doldurulmuşdur",
    filter: "Filter",
    finish: "Tamamla",
    forgot_password: "Şifrəni unutmusunuz?",
    hide: "Gizlət",
    home: "Əsas səhifə",
    image: "Şəkil",
    import: "İdxal",
    in_interval: "Aralıqda",
    input_field: "Mətn daxil etmək sahəsi",
    KZT: "KZT",
    language: "Dil",
    less: "Daha az",
    links: "Linklər",
    load_more: "Daha çox yüklə",
    login: "Giriş",
    login_or_pass_incorrect: "Giriş və ya şifrə səhvdir",
    logout: "Çıxış",
    main: "Əsas",
    message: "Mesaj",
    module_unavailable: "Modul mövcud deyil",
    months: "Aylar",
    more: "Daha çox",
    name: "Ad",
    no: "Xeyr",
    no_data_available: "Hələlik məlumat yoxdur",
    nonactive: "Aktiv deyil",
    not_enough_rights_for_this_operation: "Bu əməliyyat üçün kifayət qədər hüququnuz yoxdur",
    nothing_found: "Heç nə tapılmadı",
    old_password: "Köhnə şifrə",
    on_page: "Səhifədə",
    page_not_found: "Səhifə tapılmadı",
    password: "Şifrə",
    password_changed: "Şifrə dəyişdirildi",
    password_confirm: "Şifrəni təsdiq et",
    passwords_do_not_match: "Şifrələr uyğun gəlmir",
    patient_search: "Xəstə axtarışı",
    payment_expires_at: "Ödəniş müddəti bitir",
    phone: "Telefon",
    photo: "Foto",
    price: "Qiymət",
    print_noun: "Çap",
    program: "Proqram",
    profile: "Profil",
    registration: "Qeydiyyat",
    refresh: "Yenilə",
    requirements: "Tələblər",
    reset: "Sıfırla",
    reset_password: "Şifrəni sıfırla",
    reset_success: "Sıfırlama məktubu email ünvanınıza göndərildi",
    role: "Rol",
    russian: "Rus",
    save: "Yadda saxla",
    search: "Axtar",
    select: "Seç",
    sent: "Göndərildi",
    settings: "Parametrlər",
    signin: "Daxil ol",
    show: "Göstər",
    show_full: "Tam göstər",
    slogan: "Müasir stomatologiya idarəçiliyi üçün bulud həlli",
    sorting: "Sıralama",
    start: "Başla",
    status: "Status",
    subscription_sum: "Abunə məbləği",
    success_message: "Əməliyyat uğurla tamamlandı",
    sum: "Məbləğ",
    table_search: "Cədvəl üzrə axtarış",
    tech_specialist: "Texnik",
    text: "Mətn",
    this_module_is_not_in_tariff: "Bu modul Free tarifinə daxil deyil, Standard və ya Premium tarifinə keçmək lazımdır və siz Dentist Plus proqramının geniş funksiyalarına çıxış əldə edəcəksiniz.",
    title: "Başlıq",
    today: "Bu gün",
    total: "Cəmi",
    translation: "Tərcümə",
    unknown: "Naməlum",
    upload: "Yüklə",
    who_has_access: "Kimin girişi var",
    yes: "Bəli",
}
