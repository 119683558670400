<template>
  <div class="clinic-data page pb-5">
    <div v-if="clinic.quote_value > 0">
      <div class="bg-gray mt-4 mb-4 px-4 py-3">
        <b-row class="align-items-center">
          <b-col>
            <label class="form-label">{{ $t('cloud_space') }}</label>
            <div>
              <span>{{ $t('occupied') }}: {{ formatFileSize(clinic.quote_total) }}</span>
              <span class="ml-3">{{ $t('free') }}: {{ formatFileSize(clinic.quote_value - clinic.quote_total) }}</span>
            </div>
          </b-col>
          <b-col cols="auto">
            <!--          <a class="btn btn-themed btn-themed-squared">{{ $t('ask_for_more') }}</a>-->
          </b-col>
        </b-row>
      </div>
      <hr/>
    </div>
    <div class="radio-inline">
      <RadioGroup :options="salaryTypes"
                  :label="$t('salary_accrual_for_personnel')"
                  :showlabel="true"
                  v-model="form.doctor_salaries_type"
                  validationId="doctor_salaries_type"/>
    </div>
    <hr/>
    <div class="radio-inline">
      <RadioGroup :options="salaryTypes"
                  :label="$t('salary_accrual_for_technics')"
                  :showlabel="true"
                  v-model="form.technic_salaries_type"
                  validationId="technic_salaries_type"/>
    </div>
    <hr/>
    <div class="radio-inline">
      <RadioGroup :options="percentTypes"
                  :label="$t('percent_accrual_for_personnel')"
                  :showlabel="true"
                  v-model="form.percent_accrual"
                  validationId="percent_accrual"/>
    </div>
    <hr/>
    <div class="radio-inline">
      <RadioGroup :options="managerPercentTypes"
                  :label="$t('percent_accrual_for_administrator')"
                  :showlabel="true"
                  v-model="form.manager_percent_type"
                  validationId="manager_percent_type"/>
    </div>
    <hr/>
    <div class="radio-inline">
      <RadioGroup :options="salaryAccrualTypes"
                  :label="$t('salary_accrual')"
                  :showlabel="true"
                  v-model="form.salary_accrual"
                  validationId="salary_accrual"/>
    </div>
    <hr/>
    <div v-if="form.salary_accrual === 'payment'">
      <div class="radio-inline">
        <RadioGroup :options="salaryAccrualTypes"
                    :label="$t('technic_salary_accrual')"
                    :showlabel="true"
                    :readonly="form.salary_accrual === 'all'"
                    v-model="form.clinic_additional.technic_salary_accrual"
                    validationId="technic_salary_accrual"/>
      </div>
      <hr/>
    </div>

    <CheckboxGroup :label="$t('deduct_acquiring_from_salary')"
                   :checkboxvalue="1"
                   fid="deduct_acquiring_from_salary"
                   v-model="form.clinic_additional.deduct_acquiring_from_salary"/>

    <transition name="fade"  mode="out-in">
      <div v-if="form.clinic_additional.deduct_acquiring_from_salary" class="ml-5">
        <CheckboxGroup :label="$t('do_not_deduct_acquiring_from_technic_absolute')"
                       :checkboxvalue="1"
                       fid="do_not_deduct_acquiring_from_technic_absolute"
                       v-model="form.clinic_additional.do_not_deduct_acquiring_from_technic_absolute"/>
      </div>
    </transition>

    <CheckboxGroup :label="$t('deduct_warranty_from_salary')"
                   :checkboxvalue="1"
                   fid="deduct_warranty_from_salary"
                   v-model="form.clinic_additional.deduct_warranty_from_salary"/>

    <CheckboxGroup :label="$t('prohibit_minus_stocks')"
                   :checkboxvalue="1"
                   fid="prohibit_minus_stocks"
                   v-model="form.clinic_additional.prohibit_minus_stocks"/>

    <div class="radio-inline">
      <RadioGroup :options="techSupportTypes"
                  :label="$t('techsupport_panel')"
                  :showlabel="true"
                  v-model="form.support_button"
                  validationId="support_button"/>
    </div>
    <hr/>
    <SelectGroup :options="languages"
                 variant="white"
                 :showlabel="true"
                 :label="$t('program_language')"
                 class="d-inline-block align-middle mb-0 select-200"
                 v-model="form.language"/>
    <div class="mt-3">{{ $t('time_zone') }}: {{ clinic.time_zone }}</div>
    <hr/>
    <SelectGroup :options="intervals"
                 variant="white"
                 :showlabel="true"
                 :translate="false"
                 :nullOption="false"
                 :label="$t('logout_interval_timeout')"
                 class="d-inline-block align-middle mb-0 select-200"
                 v-model="form.logout_timeout"/>
    <hr/>

    <InputGroup :label="$t('months_to_consider_patient_lost')"
                type="number"
                class="d-inline-block select-200"
                v-model="form.activity_status_months"/>

    <SelectGroup :options="permanentPatientOptions"
                 variant="white"
                 :showlabel="true"
                 :translate="true"
                 :nullOption="false"
                 :info-hint="$t('permanent_patients_hint')"
                 :label="$t('which_clients_to_consider_permanent')"
                 class="d-inline-block align-middle select-200"
                 v-model="form.clinic_additional.permanent_patients"/>
    <div>
      <SelectGroup :options="outgoingCallTypes"
                   variant="white"
                   :showlabel="true"
                   :translate="true"
                   :nullOption="false"
                   :label="$t('outgoing_calls_through')"
                   class="d-inline-block align-middle select-200"
                   v-model="form.clinic_additional.outgoing_calls"/>
    </div>
    <hr/>
    <!--    <InputGroup v-model="form.name" :label="$t('clinic_name')"/>-->
    <FileGroup :label="$t('logo')"
               fid="file-logo"
               :filetype="'image'"
               :deletefile="true"
               :labelline="true"
               :required="false"
               @input="file"
               v-model="form.photo"/>
    <hr/>

    <CheckboxGroup :label="$t('show_teeth_general')"
                   :checkboxvalue="1"
                   fid="show_teeth_general"
                   v-model="form.show_teeth_general"/>

    <CheckboxGroup :label="$t('show_description_in_forms')"
                   :checkboxvalue="1"
                   fid="show_description_in_forms"
                   v-model="form.show_description_in_forms"/>

    <CheckboxGroup :label="$t('show_complex_materials_for_teeth')"
                   v-if="rights.includes('materials')"
                   :checkboxvalue="1"
                   fid="show_complex_materials_for_teeth"
                   v-model="form.clinic_additional.show_complex_materials_for_teeth"/>

    <CheckboxGroup :label="$t('automatic_conflict_task_creation')"
                   :checkboxvalue="1"
                   fid="automatic_conflict_task_creation"
                   v-model="form.clinic_additional.automatic_conflict_task_creation"/>

    <CheckboxGroup :label="$t('allow_negative_deposits')"
                   :checkboxvalue="1"
                   fid="allow_negative_deposits"
                   v-model="form.clinic_additional.allow_negative_deposits"/>

    <!--    <CheckboxGroup :label="$t('record_timing_enabled')"-->
    <!--                   :checkboxvalue="1"-->
    <!--                   fid="record_timing_enabled"-->
    <!--                   v-model="form.clinic_additional.record_timing_enabled"/>-->
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import FileGroup from '@/components/form/FileGroup'
import RadioGroup from '@/components/form/RadioGroup'
import SelectGroup from "@/components/form/SelectGroup"
import { mapState } from "vuex"
import {
  salaryTypes,
  percentTypes,
  salaryAccrualTypes,
  managerPercentTypes,
  permanentPatientOptions,
  outgoingCallTypes
} from "@/dictionaries/dictionary"
import { saveLanguage } from "@/services/lang.helper"
import InputGroup from "@/components/form/InputGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { EloquentService } from "@/services/api.service"
import { formatFileSize } from "@/extensions/filters/filters"
import { copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "SettingsClinic",
  head() {
    return {
      title: this.$t('clinic_data'),
    }
  },
  components: {
    CheckboxGroup,
    InputGroup,
    FileGroup,
    RadioGroup,
    SelectGroup,
  },
  data() {
    return {
      permanentPatientOptions,
      salaryTypes: Object.values(salaryTypes),
      percentTypes: Object.values(percentTypes),
      outgoingCallTypes,
      techSupportTypes: [
        { id: '0', title: 'turned_off' },
        { id: '1', title: 'turned_on' }
      ],
      managerPercentTypes: Object.values(managerPercentTypes),
      salaryAccrualTypes: Object.values(salaryAccrualTypes),
      languages: [
        { id: 'russian', title: 'russian' },
        { id: 'ukrainian', title: 'ukrainian' },
        { id: 'uzbek', title: 'uzbek' },
        { id: 'english', title: 'english' },
        { id: 'azerbaijani', title: 'azerbaijani' },
      ],
      intervals: [
        { id: 0, title: this.$t('absent') },
        { id: 60, title: '1 '+this.$t('minute') },
        { id: 300, title: '5 '+this.$t('minutes') },
        { id: 600, title: '10 '+this.$t('minutes') },
        { id: 900, title: '15 '+this.$t('minutes') },
        { id: 1200, title: '20 '+this.$t('minutes') },
        { id: 1800, title: '30 '+this.$t('minutes') },
        { id: 3600, title: '1 '+this.$t('hour') },
        { id: 7200, title: '2 '+this.$t('of_few_hours') },
        { id: 10800, title: '3 '+this.$t('of_few_hours') },
        { id: 14400, title: '4 '+this.$t('of_few_hours') },
        { id: 18000, title: '5 '+this.$t('of_hours') },
      ],
      additional: null
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
    clinicSalaryAccrual() {
      return this.form.salary_accrual
    }
  },
  watch: {
    clinic: {
      handler(val) {
        this.form = val
      },
      immediate: true
    },
    clinicSalaryAccrual(val) {
      if(val === 'all') {
        this.form.clinic_additional.technic_salary_accrual = 'all'
      }
    }
  },
  mounted() {
    this.additional = copyObject(this.clinic.clinic_additional)
  },
  methods: {
    formatFileSize,
    async save() {
      let formData = new FormData()
      let file = this.form.image
      let data = copyObject(this.form)
      if(!data.show_teeth_general) data.show_teeth_general = 0
      data.image = file
      formData = this.appendFormdata(formData, data)
      if(this.form.clinic_additional.automatic_conflict_task_creation !== this.additional.automatic_conflict_task_creation
          || this.form.clinic_additional.show_complex_materials_for_teeth !== this.additional.show_complex_materials_for_teeth
          || this.form.clinic_additional.permanent_patients !== this.additional.permanent_patients
          || this.form.clinic_additional.deduct_acquiring_from_salary !== this.additional.deduct_acquiring_from_salary
          || this.form.clinic_additional.do_not_deduct_acquiring_from_technic_absolute !== this.additional.do_not_deduct_acquiring_from_technic_absolute
          || this.form.clinic_additional.deduct_warranty_from_salary !== this.additional.deduct_warranty_from_salary
          || this.form.clinic_additional.outgoing_calls !== this.additional.outgoing_calls
          || this.form.clinic_additional.technic_salary_accrual !== this.additional.technic_salary_accrual
          || this.form.clinic_additional.allow_negative_deposits !== this.additional.allow_negative_deposits
          || this.form.clinic_additional.prohibit_minus_stocks !== this.additional.prohibit_minus_stocks
      ) {
        let addFormData = new FormData()
        addFormData.set('clinic_id', this.clinic.id)
        addFormData.set('automatic_conflict_task_creation', this.form.clinic_additional.automatic_conflict_task_creation)
        addFormData.set('show_complex_materials_for_teeth', this.form.clinic_additional.show_complex_materials_for_teeth)
        addFormData.set('deduct_acquiring_from_salary', this.form.clinic_additional.deduct_acquiring_from_salary)
        addFormData.set('do_not_deduct_acquiring_from_technic_absolute', this.form.clinic_additional.do_not_deduct_acquiring_from_technic_absolute)
        addFormData.set('deduct_warranty_from_salary', this.form.clinic_additional.deduct_warranty_from_salary)
        addFormData.set('permanent_patients', this.form.clinic_additional.permanent_patients)
        addFormData.set('outgoing_calls', this.form.clinic_additional.outgoing_calls)
        addFormData.set('technic_salary_accrual', this.form.clinic_additional.technic_salary_accrual)
        addFormData.set('allow_negative_deposits', this.form.clinic_additional.allow_negative_deposits)
        addFormData.set('prohibit_minus_stocks', this.form.clinic_additional.prohibit_minus_stocks)
        await EloquentService.update('clinic_additional', this.clinic.id, addFormData)
      }
      const d = await this.$store.dispatch('saveData', formData)
      if (d.clinic.lang_code !== this.$i18n.locale) {
        saveLanguage(d.clinic.lang_code)
        location.reload()
      } else {
        this.$noty.info(this.$t('success_message'))
      }
      await this.$store.dispatch('init')
    },
    file(val) {
      this.form.image = val
    }
  }
}
</script>

<style scoped lang="scss">
.clinic-data {
  max-width: 600px;
}
.bg-gray {
  border-radius: 5px;
}
</style>
