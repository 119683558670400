export default {
    insurance_companies: "Sug'urta kompaniyalari",
    insurance_polis: "Sug'urta polislari",
    limit: "Chegirma",
    number: "Raqam",
    polis: "Sug'urta polisi",
    polises: "Siyosat",
    use_custom_prices_for_insurance: "Sug'urta kompaniyalari uchun maxsus narxlarni qo'llash",
    use_only_in_insurance: "Faqat sug'urta uchun foydalaning",
    valid_from: "Dan amal qiladi",
    valid_to: "Gacha amal qiladi",
    visit_should_have_polis_set_in_order_to_use_insurance_payment: "Tashrif sug'urta to'lovidan foydalanish uchun to'ldirilgan sug'urta polisiga ega bo'lishi kerak",
}
