<template>
  <div class="mt-3 pb-4">
    <vue-excel-xlsx
        ref="excel"
        class="d-none"
        :data="excelData"
        :columns="excelColumns"
        :filename="'report'"
        :sheetname="'report'">
    </vue-excel-xlsx>
    <div v-if="settings && loaded" class="mb-4">
      <div class="mb-3">
        <span v-if="isNonTechnicView">{{ $t('rate_per_month') }}: {{ formatCurrency(settings.rate) }}</span>
        <div v-if="settings.rate_per_hour" class="d-inline-block px-4">|</div>
        <span v-if="settings.rate_per_hour">{{ $t('rate_per_hour') }}: {{ formatCurrency(settings.rate_per_hour) }}</span>
        <div v-if="(isAssistantView || isAdministratorView) && settings.rate_per_day" class="d-inline-block px-4">|</div>
        <span v-if="(isAssistantView || isAdministratorView) && settings.rate_per_day">{{ $t('rate_per_day') }}: {{ formatCurrency(settings.rate_per_day) }}</span>
        <div v-if="isNonTechnicView" class="d-inline-block px-4">|</div>
        {{ $t('general_percent') }}: {{ formatPercent(settings.percent) }}
      </div>
      <b-row>
        <b-col cols="12" v-if="isNonTechnicView">
          <div>
            <div class="text-muted d-inline-block">{{ $t('salary_accrual_for_personnel') }}:</div>
            {{ $t(salaryTypes[settings.doctor_salaries_type].title) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div>
            <div class="text-muted d-inline-block">{{ $t('salary_accrual_for_technics') }}:</div>
            {{ $t(salaryTypes[settings.technic_salaries_type].title) }}
          </div>
        </b-col>
        <b-col cols="12" v-if="isNonTechnicView">
          <div>
            <div class="text-muted d-inline-block">{{ $t('percent_accrual_for_personnel') }}:</div>
            {{ $t(percentTypes[settings.percent_accrual].title) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div>
            <div class="text-muted d-inline-block">{{ $t('salary_accrual') }}:</div>
            {{ $t(salaryAccrualTypes[settings.salary_accrual].title) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div>
            <div class="text-muted d-inline-block">{{ $t('technic_salary_accrual') }}:</div>
            {{ $t(salaryAccrualTypes[settings.technic_salary_accrual].title) }}
          </div>
        </b-col>
        <b-col cols="12" v-if="usesBonuses">
          <div>
            <div class="text-muted d-inline-block">{{ $t('bonus_payment') }}:</div>
            {{ $t(bonusUseTypes[settings.use_bonuses_on_salary].title) }}
          </div>
        </b-col>
        <b-col cols="12">
          <div>
            <div class="text-muted d-inline-block">{{ $t('total') }}:</div> {{ formatCurrency(total) }}
          </div>
        </b-col>
      </b-row>

    </div>
    <div v-if="loaded">
      <div v-for="(r, rind) in rows" :key="`sal-row-${rind}`">
        <div class="data-table-wrapper">
          <table class="table data-table more-button">
            <thead>
            <tr class="expanded">
              <th>{{ $t('visit_date') }}</th>
              <th>{{ $t('patient') }}</th>
              <th>{{ $t('service') }}</th>
              <th>{{ $t('cost') }}</th>
              <th v-if="onlyPaid && isNonTechnicView">{{ $t('payments') }}</th>
              <th v-if="onlyPaid && isNonTechnicView && deductsAcquiringFromSalaries">{{ $t('acquiring_fee') }}</th>
              <th>{{ $t('technic') }}</th>
              <th>{{ $t('technic_percent') }}</th>
              <th>{{ $t('technic_sum') }}</th>
              <th v-if="isDoctorView">{{ $t('doctor_percent') }}</th>
              <th v-if="isDoctorView">{{ $t('doctor_sum') }}</th>
              <th v-if="isAssistantView">{{ $t('assistant_percent') }}</th>
              <th v-if="isAssistantView">{{ $t('assistant_sum') }}</th>
              <th v-if="isAdministratorView">{{ $t('administrator_percent') }}</th>
              <th v-if="isAdministratorView">{{ $t('administrator_sum') }}</th>
              <th v-if="isCuratorView">{{ $t('curator_percent') }}</th>
              <th v-if="isCuratorView">{{ $t('curator_sum') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(s, sind) in r.services"
                :key="`service-${r.id}-${sind}`"
                class="expanded">
              <td :data-title="$t('visit_date')" v-if="sind === 0"
                  :rowspan="r.services.length">{{ r.date }}</td>
              <td :data-title="$t('patient')"
                  v-if="sind === 0"
                  :rowspan="r.services.length">{{ r.patient }}</td>
              <td :data-title="$t('service')">{{ s.name }}</td>
              <td :data-title="$t('cost')">{{ formatCurrency(s.price) }}</td>
              <td :data-title="$t('payments')"
                  v-if="sind === 0 && onlyPaid && isNonTechnicView" :rowspan="r.services.length">
                <div v-for="(p, pind) in r.payments"
                     :key="`pay-${p.id}`"
                     :class="{'mt-3': pind > 0 }">
                  <div class="text-muted small">{{ formatDateTime(p.date_timezone) }}</div>
                  <div class="small text-muted mb-0" v-if="p.pay_method">({{ p.pay_method.translatedTitle }})</div>
                  <div>{{ formatCurrency(p.paid) }}</div>

                </div>
                <div class="mt-2 font-weight-bold">
                  <div class="text-muted small">{{ $t('total') }}</div>
                  {{ formatCurrency(sumPayments(r.payments)) }} ({{ getPayCovered(r) }}%)
                </div>
              </td>
              <td :data-title="$t('acquiring_fee')"
                  v-if="sind === 0 && onlyPaid && isNonTechnicView && deductsAcquiringFromSalaries"
                  :rowspan="r.services.length">
                {{ formatCurrency(r.acquiring_fee) }}
              </td>
              <td :data-title="$t('technic')">{{ s.technic_name }}</td>
              <td :data-title="$t('technic_percent')">{{ formatPercent(s.technic_percent) }}</td>
              <td :data-title="$t('technic_sum')">{{ formatCurrency(s.technic_sum) }}</td>
              <td :data-title="$t('doctor_percent')" v-if="isNonTechnicView">{{ formatPercent(s.doctor_percent) }}</td>
              <td :data-title="$t('doctor_sum')" v-if="isNonTechnicView">{{ formatCurrency(s.doctor_sum) }}</td>
            </tr>
            <tr class="font-weight-bold expanded">
              <td colspan="6"></td>
              <td v-if="onlyPaid && isNonTechnicView"
                  :colspan="deductsAcquiringFromSalaries ? 2 : 1"></td>
              <td :data-title="$t('technic')">{{ formatCurrency(r.total_technic) }}</td>
              <td v-if="isNonTechnicView && !isMobile"></td>
              <td :data-title="$t('doctor')"
                  v-if="isNonTechnicView">{{ formatCurrency(r.total_doctor) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="total > 0"
           class="font-weight-bold text-right">
        {{ $t('total') }}: {{ formatCurrency(total) }}
      </div>
      <div v-if="hoursWorked > 0 && settings.rate_per_hour">
        {{ $t('worked_hours') }}: {{ formatNumber(hoursWorked) }}
        <span class="text-muted">[{{ formatCurrency(hoursWorked * settings.rate_per_hour) }}]</span>
      </div>
      <div v-if="daysWorked > 0 && daysWorkedIncome">
        {{ $t('worked_days') }}: {{ formatNumber(daysWorked) }}
        <span class="text-muted">[{{ formatCurrency(daysWorkedIncome) }}]</span>
      </div>
    </div>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import { salaryTypes, percentTypes, salaryAccrualTypes, bonusUseTypes } from "@/dictionaries/dictionary"
import { mapGetters, mapState } from "vuex"
import { formatCurrency, formatDateTime, formatPercent, formatNumber } from "@/extensions/filters/filters"
import {copyObject} from "@/extensions/prototypes/prototypes"

export default {
  name: "ReportsSalaries",
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    oneDoctor: {
      type: Number
    },
    oneTechnic: {},
    oneAssistant: {},
    oneAdministrator: {},
    oneCurator: {},
    diagnoses: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('salaries'),
    }
  },
  data() {
    return {
      salaryTypes,
      percentTypes,
      salaryAccrualTypes,
      bonusUseTypes,
      onlyPaid: false,
      rows: [],
      settings: null,
      hoursWorked: 0,
      daysWorked: 0,
      daysWorkedIncome: 0,
      loaded: false,
    }
  },
  methods: {
    formatCurrency,
    formatPercent,
    formatDateTime,
    formatNumber,
    loadReport() {
      ReportsService.loadReport('salaries', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctor: this.oneDoctor ? this.oneDoctor : null,
        technic: this.oneTechnic ? this.oneTechnic.id : null,
        assistant: this.oneAssistant ? this.oneAssistant.id : null,
        administrator: this.oneAdministrator ? this.oneAdministrator.id : null,
        curator: this.oneCurator ? this.oneCurator.id : null,
        view: this.view
      }).then(res => {
        this.rows = res.data.data
        this.settings = res.data.settings
        this.onlyPaid = res.data.onlyPaid
        this.hoursWorked = res.data.hoursWorked
        this.daysWorked = res.data.daysWorked
        this.daysWorkedIncome = res.data.daysWorkedIncome
        this.loaded = true
      }).catch(() => {})
    },
    sumPayments(payments) {
      return payments.reduce((acc, cur) => acc + parseFloat(cur.paid), 0)
    },
    getPayCovered(r) {
      return r.record_price > 0 ? (Math.round((this.sumPayments(r.payments) / r.record_price) * 10000) / 100) : 0
    },
    excel() {
      this.$refs.excel.$el.click()
    },
  },
  computed: {
    ...mapGetters(['usesBonuses', 'deductsAcquiringFromSalaries']),
    ...mapState({
      dictionaryDoctors: state => state.auth.doctors,
      isMobile: state => state.dom.isMobile
    }),
    total() {
      let field = this.view === 'technic' ? 'total_technic' : 'total_doctor'
      return this.rows ? this.rows.reduce((acc, cur) => acc + cur[field], 0) : 0
    },
    view() {
      if(this.oneTechnic) return 'technic'
      if(this.oneAssistant) return 'assistant'
      if(this.oneAdministrator) return 'administrator'
      if(this.oneCurator) return 'curator'
      return 'doctor'
    },
    isDoctorView() {
      return this.view === 'doctor'
    },
    isAssistantView() {
      return this.view === 'assistant'
    },
    isAdministratorView() {
      return this.view === 'administrator'
    },
    isCuratorView() {
      return this.view === 'curator'
    },
    isNonTechnicView() {
      return this.isDoctorView || this.isAssistantView || this.isAdministratorView || this.isCuratorView
    },
    doctorModel() {
      if(!this.isDoctorView) return null
      return this.dictionaryDoctors.find(doctor => parseInt(doctor.id) === parseInt(this.oneDoctor))
    },
    selectedId() {
      if(this.isDoctorView) return this.oneDoctor
      if(this.isAssistantView) return this.oneAssistant.id
      if(this.isAdministratorView) return this.oneAdministrator.id
      if(this.isCuratorView) return this.oneCurator.id
      return this.oneTechnic ? this.oneTechnic.id : null
    },
    columns() {
      let arr = [
        {
          field: 'date',
          label: this.$t('date'),
        },
        {
          field: 'name',
          label: this.$t('patient'),
        },
        {
          field: 'service',
          label: this.$t('service'),
        },
        {
          field: 'price',
          label: this.$t('price'),
        },
      ]
      if(this.onlyPaid) {
        arr.push({
          field: 'paid',
          label: this.$t('paid'),
        })
        arr.push({
          field: 'acquiring_fee',
          label: this.$t('acquiring_fee'),
        })
      }
      arr.push({
        field: 'technic_name',
        label: this.$t('technic'),
      })
      arr.push({
        field: 'technic_percent',
        label: this.$t('technic_percent'),
      })
      arr.push({
        field: 'technic_sum',
        label: this.$t('technic_sum'),
      })
      if(this.isDoctorView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('doctor_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('doctor_sum'),
        })
        arr.push({
          field: 'doctor_name',
          label: this.$t('doctor_name'),
        })
      }
      if(this.isAssistantView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('assistant_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('assistant_sum'),
        })
      }
      if(this.isAdministratorView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('administrator_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('administrator_sum'),
        })
      }
      return arr
    },
    excelColumns() {
      const cols = copyObject(this.columns)
      cols.splice(6, 0, {
        id: 'forma',
        field: 'forma',
        label: this.$t('payment_type'),
      })
      return cols
    },
    excelData() {
      let arr = []
      let totals = {
        paid: 0,
        price: 0,
        technic_sum: 0,
        doctor_sum: 0
      }
      if(!this.rows) return arr
      this.rows.forEach(row => {
        row.services.forEach(service => {
          let data = {
            date: row.date,
            name: row.patient,
            service: service.name,
            price: Number(service.price),
            technic_name: service.technic_name,
            technic_percent: service.technic_percent,
            technic_sum: service.technic_sum,
          }
          totals.technic_sum += service.technic_sum
          totals.price += Number(service.price)
          if(this.onlyPaid) {
            const paid = (this.getPayCovered(row) * Number(service.price)) / 100
            data.paid = paid
            data.forma = [...new Set(row.payments.map(p => p.pay_method?.translatedTitle || null))].join(', ')
            totals.paid += paid
          }
          if(this.isNonTechnicView) {
            data.doctor_percent = service.doctor_percent
            data.doctor_sum = service.doctor_sum
            data.doctor_name = this.doctorModel?.full_name || ''
            totals.doctor_sum += service.doctor_sum
          }
          arr.push(data)
        })
      })
      arr.push(totals)
      return arr
    }
  },
  watch: {
    selectedId() {
      this.loaded = false
    }
  }
}
</script>
