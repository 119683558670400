export default {
	active_requirement_orders: "Активні наряд-замовлення",
	add_laboratory_service: "Додати лабораторну послугу",
	can_have_dental_work: "Може містити зуботехнічні роботи",
	delivery_date: "Дата видачі",
	dental_work: "Зуботехнічні роботи",
	do_you_confirm_laboratory_order_finish: "Ви підтверджуєте, що роботу пацієнту здано? Після підтвердження, буде нарахована зарплата лікарю, а зміни за даним нарядом-замовленням будуть неможливі.",
	executor: "Виконавець",
	field_is_required: "Поле обов'язкове",
	fitting_date: "Дата примірки",
	in_clinic: "В клініці",
	in_lab: "В лабораторії",
	laboratories: "Лабораторії",
	laboratory: "Лабораторія",
	must_have_dental_work: "Обов'язково містить зуботехнічні роботи",
	no_dental_work_needed_for_selected_services: "Для вибраних послуг зуботехнічні роботи не потрібні",
	no_services: "Немає послуг",
	order_number: "Номер наряду",
	requirement_order: "Вимога - замовлення",
	select_from_price: "Вибрати з прайсу",
	without_attachment_to_tooth: "Без прив'язки до зуба",
}
