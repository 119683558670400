<template>
  <div class="clinic-egisz-data page pb-5 pt-4" style="max-width: 450px;">
    <SelectGroup :options="doctors"
                 title="full_name"
                 variant="white"
                 :showErrors="true"
                 validationId="doctor_id"
                 :label="$t('main_doctor')"
                 :showlabel="true"
                 v-model="form.main_doctor_id"/>
    <InputGroup :label="$t('oid_ministry_rf')" v-model="form.oid_ministry_rf"/>
    <SelectGroup :options="regions"
                 valuekey="egisz_id"
                 variant="white"
                 :showErrors="true"
                 validationId="region_egisz_id"
                 :label="$t('region')"
                 :showlabel="true"
                 v-model="form.region_egisz_id"/>
    <InputGroup label="N3 GUID" v-model="form.n3_guid"/>
    <InputGroup label="N3 IDLPU" v-model="form.n3_idlpu"/>
    <InputGroup label="N3 GUID (СНИЛС)" v-model="form.n3_snils_guid"/>
    <InputGroup label="N3 GUID (EventLog)" v-model="form.n3_eventlog_guid"/>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import { CalendarService, EloquentService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import { copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "SettingsEGISZ",
  head() {
    return {
      title: this.$t('clinic_data'),
    }
  },
  components: {
    InputGroup,
    SelectGroup,
  },
  data() {
    return {
      form: {},
      regions: []
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      branchId: state   => state.auth.branch,
      superadmin: state => state.auth.superadmin,
      doctors: state    => state.auth.doctors,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      // await EloquentService.update('clinic_additional', this.clinic.id, formData)
      await EloquentService.update('branch', this.branchId, formData)
      await this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  },
  async mounted() {
    const showRes = await EloquentService.show('branch', this.branchId, this.superadmin)
    this.form = copyObject(showRes.data)
    const res = await CalendarService.getEGISZDictionaries('getOblasts')
    this.regions = res.data.getOblasts
  }
}
</script>
