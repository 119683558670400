<template>
  <div>
    <a :href="url" target="_blank">
      <FileSvg class="d-inline-block align-middle mr-2 file-svg"/>
      <span class="d-inline-block align-middle">{{ object.original }}</span>
    </a>
    <div v-if="trustmeLink" class="mt-2">
      <a :href="trustmeLink" target="_blank">
        (TrustMe)
      </a>
    </div>
    <div v-if="onlineContract" class="mt-2">
      <a @click="openOnlineContract" class="pointer text-primary">
        (OnlineContract)
      </a>
    </div>
  </div>
</template>

<script>

import FileSvg from '@/assets/svg-vue/patients/file.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import {getOnlineContractKzContractPdf} from "@/services/onlineContract.helper"

export default {
  name: "PatientsDatatableTDDocumentFile",
  components: {
    FileSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
    trustmeLink() {
      return this.object?.generated_document?.trustMeDownloadLink || null
    },
    url() {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/client/document/'+this.object.id
    },
    onlineContract() {
      const generatedDoc = this.object?.generated_document
      return generatedDoc?.trustme_id
          && generatedDoc?.sign_api === 'onlinecontract'
          && generatedDoc?.signed_at
    }
  },
  methods: {
    async openOnlineContract() {
      await getOnlineContractKzContractPdf(this.object?.generated_document?.trustme_id)
    }
  }
}
</script>

<style scoped lang="scss">
.file-svg {
  fill: $blue-color;
}
</style>
