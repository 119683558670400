export default {
    insurance_companies: "Страхові компанії",
    insurance_polis: "Страховий поліс",
    limit: "Ліміт",
    number: "Номер",
    polis: "Поліс",
    polises: "Поліси",
    use_custom_prices_for_insurance: "Використовувати індивідуальні ціни для страхових компаній",
    use_only_in_insurance: "Використовувати тільки в страхових",
    valid_from: "Дійсний з",
    valid_to: "Дійсний до",
    visit_should_have_polis_set_in_order_to_use_insurance_payment: "У візиту має бути заповнений страховий поліс для використання страхової оплати",
}
