<template>
  <div>
    <router-link :to="{
      name: ['superadmin', 'supermanager', 'tech_specialist'].includes(role) ? 'SuperAdminClinicGeneral' : 'SuperAdminManagerClinic',
      params: { id: object.id }
    }">
      {{ object.name }}

    </router-link>
    <div class="small text-danger"
         v-if="clinicToBeDeletedDate">
      Будет удалена {{ formatDate(clinicToBeDeletedDate) }}
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/extensions/filters/filters"
import moment from "moment-timezone"

export default {
  name: "SuperAdminDatatableTDClinicTitle",
  methods: {
    formatDate
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    },
    clinicToBeDeletedDate() {
      if(!this.object.set_for_deletion_at) return null
      return moment(this.object.set_for_deletion_at).add(30, 'day')
    }
  }
}
</script>

<style scoped>
  div {
    word-break: break-word;
  }
</style>
