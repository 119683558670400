<template>
  <div>
    {{ $t(title) }}
  </div>
</template>

<script setup>
import { computed } from "vue"
import { anketaCategories } from "@/dictionaries/dictionary"

const props = defineProps({
  object: {
    type: Object
  },
})

const title = computed(() => {
  return anketaCategories[props.object.category] || props.object.category
})
</script>
