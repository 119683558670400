export default {
	active_requirement_orders: "Aktiv tələb sifarişləri",
	add_laboratory_service: "Laboratuvar xidməti əlavə edin",
	can_have_dental_work: "Diş işi olabilir",
	delivery_date: "Çatdırılma tarixi",
	dental_work: "Diş işi",
	do_you_confirm_laboratory_order_finish: "İşin xəstəyə təqdim edildiyini təsdiq edirsinizmi? Təsdiq edildikdən sonra həkimin maaşı hesablanacaq və bu iş əmrinə dəyişiklik etmək mümkün olmayacaq.",
	executor: "Yürütücü",
	field_is_required: "Sahə tələb olunur",
	fitting_date: "Uyğunlaşma tarixi",
	in_clinic: "Klinikada",
	in_lab: "Laboratoriyada",
	laboratories: "Laboratuvarlar",
	laboratory: "Laboratuvar",
	must_have_dental_work: "Diş işi olmalıdır",
	no_dental_work_needed_for_selected_services: "Seçilen hizmetler için diş işi gerekli değil",
	no_services: "Xidmətlər yoxdur",
	order_number: "Sifariş nömrəsi",
	requirement_order: "Gereklilik - sipariş",
	select_from_price: "Fiyattan seçin",
	without_attachment_to_tooth: "Dişe bağlantısız",
}
