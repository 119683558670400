<template>
  <div>
    <DataTable :options="options"
               module="templates"/>
  </div>
</template>

<script setup>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/templates/document_category"
import { useStore} from "vuex"
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue"
import {useHead} from "@unhead/vue"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('categories')
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
})
</script>
