export default {
    active_requirement_orders: "Активные наряд-заказы",
    add_laboratory_service: "Добавить лабораторную услугу",
    can_have_dental_work: "Может содержать зуботехнические работы",
    delivery_date: "Дата сдачи",
    dental_work: "Зуботехнические работы",
    do_you_confirm_laboratory_order_finish: "Вы подтверждаете, что работа пациенту сдана? После подтверждения, будет начислена зарплата доктору, а изменения по данному наряд-заказу будут невозможны.",
    executor: "Исполнитель",
    field_is_required: "Поле обязательно",
    fitting_date: "Дата примерки",
    in_clinic: "В клинике",
    in_lab: "В лаборатории",
    laboratories: "Лаборатории",
    laboratory: "Лаборатория",
    must_have_dental_work: "Обязательно содержит зуботехнические работы",
    no_dental_work_needed_for_selected_services: "Для выбранных услуг зуботехнические работы не требуются",
    no_services: "Нет услуг",
    order_number: "Номер наряда",
    requirement_order: "Наряд - заказ",
    select_from_price: "Выбрать из прайса",
    without_attachment_to_tooth: "Без привязки к зубу",
}
