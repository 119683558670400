import { anketaCategories } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'anketa_question',
    modaltitle: 'question',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'question',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            filter_number: true,
            form: {
                type: "text",
                required: true,
            },
        },
        {
            id: 'category',
            title: 'category',
            table: true,
            sortable: true,
            format_translate: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                required: true,
                translate: true,
                select_type: "list",
                options: Object.entries(anketaCategories).map(([id, title]) => ({ id, title }))
            },
            tdcomponent: "SettingsDatatableTDAnketaCategory"
        },
        // {
        //     id: 'created_at',
        //     title: 'date',
        //     table: true,
        //     sortable: true,
        //     format_datetime: true,
        //     filter: true
        // }
    ]
}
