export const complexServiceAdapter =
    ({
       title,
       services,
       discount_percent,
       discount_value,
       discount_type
     }) => ({
      title: title ?? null,
      discount_percent: discount_percent ?? 0,
      discount_value: discount_value ?? 0,
      discount_type: discount_type ?? 'percent',
      services: services?.map(s => ({
        service_id: s.id,
        amount: s.pivot.amount
      })) || []
    })
