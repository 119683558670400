<template>
  <div>
   <a class="text-primary pointer" @click="getLink">{{ $t('link') }}</a><br/>
   <a class="text-primary pointer" @click="getLinkPdf">pdf</a>
  </div>
</template>

<script setup>
import {DocumentsService} from "@/services/api.service"
import {openBase64AsPdf} from "@/services/onlineContract.helper"

const props = defineProps({
  object: {
    type: Object
  },
})

const getLink = async () => {
  const res = await DocumentsService.getOnlineContractOrderLink(props.object.id)
  if(res.data) {
    window.open(res.data, '_blank')
  }
}

const getLinkPdf = async () => {
  const res = await DocumentsService.getOnlineContractOrderLink(props.object.id, 1)
  openBase64AsPdf(res.data)
}

</script>
