import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import SettingsGeneral from "@/components/pages/settings/SettingsGeneral"
import SettingsClinic from "@/components/pages/settings/SettingsClinic"
import SettingsContacts from "@/components/pages/settings/SettingsContacts"
import SettingsCalendar from "@/components/pages/settings/SettingsCalendar"
import SettingsCurrencies from "@/components/pages/settings/SettingsCurrencies"
import SettingsIntegrations from "@/components/pages/settings/SettingsIntegrations"
import SettingsTags from "@/components/pages/settings/SettingsTags"
import SettingsRecordTags from "@/components/pages/settings/SettingsRecordTags"
import SettingsVisitResults from "@/components/pages/settings/SettingsVisitResults"
import SettingsSources from "@/components/pages/settings/SettingsSources"
import SettingsPayMethods from "@/components/pages/settings/SettingsPayMethods"
import SettingsDepositAccounts from "@/components/pages/settings/SettingsDepositAccounts"
import SettingsNotifications from "@/components/pages/settings/SettingsNotifications"
import SettingsPatientConditions from "@/components/pages/settings/SettingsPatientConditions"
import SettingsControlSystem from "@/components/pages/settings/SettingsControlSystem"
import SettingsBonuses from "@/components/pages/settings/SettingsBonuses"
import SettingsOnlineBooking from "@/components/pages/settings/SettingsOnlineBooking"
import SettingsTemplates from "@/components/pages/settings/SettingsTemplates"
import SettingsFields from "@/components/pages/settings/SettingsFields"
import SettingsEGISZ from "@/components/pages/settings/SettingsEGISZ"
import SettingsRecordReasons from "@/components/pages/settings/SettingsRecordReasons"
import SettingsAnketa from "@/components/pages/settings/SettingsAnketa"
import SettingsIPs from "@/components/pages/settings/SettingsIPs"

export default {
    path: '/settings',
    meta: { middleware: [authorized], layout: "default", rights: ['settings'] },
    // component: () => import('../components/pages/settings/SettingsGeneral.vue'),
    component: SettingsGeneral,
    children: [
        { path: '', redirect: '/settings/clinic' },
        {
            path: 'clinic', name: "SettingsClinic",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            // component: () => import('../components/pages/settings/SettingsClinic.vue')
            component: SettingsClinic
        },
        {
            path: 'contacts', name: "SettingsContacts",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            // component: () => import('../components/pages/settings/SettingsContacts.vue')
            component: SettingsContacts
        },
        {
            path: 'calendar', name: "SettingsCalendar",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            // component: () => import('../components/pages/settings/SettingsCalendar.vue')
            component: SettingsCalendar
        },
        {
            path: 'currencies', name: "SettingsCurrencies",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'clinic_currencies'] },
            // component: () => import('../components/pages/settings/SettingsCurrencies.vue')
            component: SettingsCurrencies
        },
        {
            path: 'integrations', name: "SettingsIntegrations",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings','sms_send'] },
            // component: () => import('../components/pages/settings/SettingsIntegrations.vue')
            component: SettingsIntegrations
        },
        // {
        //     path: 'billing', name: "SettingsBilling",
        //     meta: { middleware: [authorized], layout: "default", rights: ['settings'] },
        //     component: SettingsBilling
        // },
        {
            path: 'tags', name: "SettingsTags",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            // component: () => import('../components/pages/settings/SettingsTags.vue')
            component: SettingsTags
        },
        {
            path: 'record_tags', name: "SettingsRecordTags",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsRecordTags
        },
        {
            path: 'visit_results', name: "SettingsVisitResults",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsVisitResults
        },
        {
            path: 'sources', name: "SettingsSources",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            // component: () => import('../components/pages/settings/SettingsTags.vue')
            component: SettingsSources
        },
        {
            path: 'pay_methods', name: "SettingsPayMethods",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsPayMethods
        },
        {
            path: 'deposit_accounts', name: "SettingsDepositAccounts",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsDepositAccounts
        },
        {
            path: 'fields', name: "SettingsFields",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsFields
        },
        {
            path: 'notifications', name: "SettingsNotifications",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'notifications'] },
            component: SettingsNotifications
        },
        {
            path: 'conditions', name: "SettingsPatientConditions",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'patient_conditions'] },
            // component: () => import('../components/pages/settings/SettingsPatientConditions.vue')
            component: SettingsPatientConditions
        },
        // {
        //     path: 'marketing', name: "SettingsMarketing",
        //     meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'marketing'] },
        //     component: SettingsMarketing
        // },
        {
            path: 'templates', name: "SettingsTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsTemplates
        },
        {
            path: 'reasons', name: "SettingsRecordReasons",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsRecordReasons
        },
        {
            path: 'bonuses', name: "SettingsBonuses",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'bonuses'] },
            component: SettingsBonuses
        },
        {
            path: 'online_booking', name: "SettingsOnlineBooking",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'online_booking'] },
            component: SettingsOnlineBooking
        },
        {
            path: 'control_system', name: "SettingsControlSystem",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'control_system'] },
            component: SettingsControlSystem
        },
        {
            path: 'egisz', name: "SettingsEGISZ",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'egisz_view'] },
            component: SettingsEGISZ
        },
        {
            path: 'ips', name: "SettingsIPs",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsIPs
        },
        {
            path: 'anketa', name: "SettingsAnketa",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings'] },
            component: SettingsAnketa
        },
    ]
}
