<template>
  <div>
    <a class="data-table-action"
       :title="$t('delete')"
       v-b-tooltip.hover
       @click="deleteAccess">
      <DeleteSvg/><span class="action-button-mob-title">{{ $t('delete') }}</span>
    </a>
  </div>
</template>

<script setup>
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import { UsersService } from "@/services/api.service"
import { useStore } from "vuex"

const store = useStore()

const props = defineProps({
  object: {
    required: true,
    type: Object
  }
})

const deleteAccess = async () => {
  try {
    const res = await UsersService.invalidateToken(props.object.id)
    if(res.data) {
      store.commit('incrementDataTableCounter')
    }
  } catch (e) {
    console.log(e)
  }
}
</script>
