export default {
    acquiring_device: "Пристрій прийому платежів",
    cashdesk: "Каса",
    cash_operation: "Касова операція",
    cashier_inn: "ІПН касира",
    cashier_name: "Ім'я касира",
    cheque: "Чек",
    cheques: "Чеки",
    closing_balance: "Залишок на кінець дня",
    close_shift: "Закрити зміну",
    data_is_invalid: "Дані невалідні",
    data_is_valid: "Дані валідні",
    deposit_to_cashdesk: "Внести в касу",
    error_printing_cheque: "Помилка друку чека",
    fiscal_device: "Пристрій ККТ",
    injection: "Поповнення",
    kkm_url: "HTTP-адреса сервера торгового обладнання. Заповнювати у випадку, якщо ккм сервер підключений нелокально.",
    name_of_service_in_cheque: "Назва послуги в чеку",
    not_subject_to_vat: "Не оподатковується ПДВ",
    opening_balance: "Залишок на початок дня",
    open_shift: "Відкрити зміну",
    opened_at: "Дата відкриття",
    print_totals: "Друк підсумків",
    sales: "Продажі",
    send_services_in_cheque: "Передавати послуги в чеку",
    shift: "Зміна",
    shifts: "Зміни",
    shift_number: "Номер зміни",
    terminal: "Термінал",
    vat_rate: "Ставка ПДВ",
    waiting_for_payment_in_terminal: "Очікуємо оплату на терміналі",
    withdraw_from_cashdesk: "Зняти з каси",
    withdrawal: "Вилучення",
    xreport: "X-звіт",
    xreports: "X-звіти"
}
