<template>
  <b-modal id="contract-modal"
           size="xl"
           no-enforce-focus
           no-close-on-backdrop
           @show="onShow"
           @hide="onHide"
           :title="printAgreements ? $t('agreement_print') : $t('contract_print')"
           centered>
    <div v-if="!loaded">
      <div class="mb-3">{{ $t('select_document_type') }}</div>
      <div v-if="!initialLoading">
        <div v-for="categoryItem in listByCategories"
             :key="`category-${categoryItem.category.id}`">
          <div v-if="categoryItem.category && categoryItem.category.id !== 'none'"
               class="category-toggle d-flex justify-content-between align-items-center"
               @click="toggleOpenedCategory(categoryItem.category.id)">
            <span>{{ categoryItem.category.title }}</span>
            <span>
              <MinusSvg v-if="openedCategories.includes(categoryItem.category.id)"/>
              <PlusSvg v-else/>
            </span>
          </div>
          <div class="category-collapse"
               :class="{
                  opened: openedCategories.includes(categoryItem.category.id) || categoryItem.category.id === 'none',
                  'mt-3': categoryItem.category.id === 'none'
               }">
            <div>
              <div v-for="ct in categoryItem.items"
                   class="py-1"
                   :class="{ 'border-bottom': !printAgreements }"
                   :key="`ct-${ct.id}`">
                <b-row class="align-items-center">
                  <b-col :md="7" cols="12">
                    <a @click="selectContract(ct)"
                       class="d-block pointer py-1 text-decoration-none">- {{ ct.title }}</a>
                  </b-col>
                  <b-col md="2" cols="6">
                    <div v-if="!ct.generated">{{ $t('not_generated') }}</div>
                    <div v-else-if="ct.generated && ct.generated.signed_at && ct.generated.trustMeDownloadLink" class="text-success">{{ $t('signed_via_trustme') }}</div>
                    <div v-else-if="ct.generated && ct.generated.signed_at && ct.generated.sign_api === 'onlinecontract'" class="text-success">{{ $t('signed_via_onlinecontract') }}</div>
                    <div v-else-if="ct.generated && ct.generated.signed_at" class="text-success">{{ $t('signed') }}</div>
                    <div v-else-if="ct.generated && ct.generated.fileManualUrl" class="text-primary">{{ $t('uploaded') }}</div>
                    <div v-else  class="text-info">{{ $t('generated') }}</div>
                  </b-col>
                  <b-col md="3" cols="6">
                    <b-button :title="$t('generate')"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none"
                              v-if="!ct.generated"
                              @click="generateDoc(ct)"
                              v-b-tooltip.hover><GenerateSvg class="svg-light"/>
                    </b-button>
                    <b-button :title="`${$t('file')} (pdf)`"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none position-relative"
                              v-if="ct.generated && ct.generated.filePdfUrl"
                              @click="loadDoc(ct.generated.filePdfUrl)"
                              v-b-tooltip.hover><FilePdfSvg class="svg-light"/>
                    </b-button>
                    <b-button :title="`${$t('file')} (word)`"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none position-relative"
                              v-if="ct.generated && ct.generated.fileWordUrl"
                              @click="loadWordDoc(ct.generated.fileWordUrl)"
                              v-b-tooltip.hover><FileWordSvg class="svg-light"/>
                    </b-button>
                    <b-button :title="`${$t('file')}${ct.generated.signed_at ? ' - '+$t('signed') : ''}`"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none position-relative"
                              v-if="ct.generated && ct.generated.fileManualUrl"
                              @click="loadDoc(ct.generated.fileManualUrl)"
                              v-b-tooltip.hover><FilePdfSvg class="svg-light"/><SignedSvg class="signed-svg" v-if="ct.generated.signed_at"/>
                    </b-button>
                    <label :for="`manual_doc_${ct.id}`"
                           class="btn btn-link pointer mb-0"
                           :class="{ disabled: loading }"
                           :title="$t('upload_signed')"
                           v-if="ct.generated && !ct.generated.fileManualUrl && !ct.generated.signed_at"
                           v-b-tooltip.hover>
                      <UploadSvg class="svg-stroke-light"/>
                      <input type="file"
                             accept="application/pdf"
                             class="d-none"
                             :multiple="false"
                             :key="`input-${ct.id}-${counter}`"
                             :id="`manual_doc_${ct.id}`"
                             @change="processFile($event, ct)"/>
                    </label>
                    <b-button :title="$t('sign')"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none"
                              v-if="ct.generated && !ct.generated.signed_at && !ct.trustme_id"
                              @click="signDoc(ct.generated)"
                              v-b-tooltip.hover><SignSvg class="svg-light"/>
                    </b-button>
                    <b-button :title="$t('sign_via_trustme')"
                              variant="link"
                              class="pointer outline-none"
                              v-if="ct.generated && !ct.generated.signed_at && trustMeEnabled && !ct.generated.trustMeDownloadLink"
                              @click="signDocTrustMe(ct.generated)"
                              v-b-tooltip.hover><ShieldSvg class="svg-light svg-size-lg"/>
                    </b-button>
                    <b-button :title="$t('sign_via_onlinecontract')"
                              variant="link"
                              class="pointer outline-none"
                              v-if="ct.generated && !ct.generated.signed_at && onlineContractEnabled"
                              @click="signDocOnlineContract(ct.generated)"
                              v-b-tooltip.hover><StampSvg class="svg-stroke-light svg-size-lg"/>
                    </b-button>
                    <b-button :title="$t('see_in_trustme')"
                              variant="link"
                              class="pointer outline-none"
                              v-if="ct.generated && ct.generated.trustMeDownloadLink && trustMeEnabled"
                              @click="loadDoc(ct.generated.trustMeDownloadLink)"
                              v-b-tooltip.hover><EyeSvg class="svg-light svg-size-lg"/>
                    </b-button>
                    <b-button :title="$t('see_in_onlinecontract')"
                              variant="link"
                              class="pointer outline-none"
                              v-if="ct.generated && ct.generated.signed_at && onlineContractEnabled && ct.generated.sign_api === 'onlinecontract'"
                              @click="loadOnlineContractDoc(ct.generated)"
                              v-b-tooltip.hover><EyeSvg class="svg-light svg-size-lg"/>
                    </b-button>
                    <b-button :title="$t('delete')"
                              variant="link"
                              :disabled="loading"
                              class="pointer outline-none"
                              v-if="ct.generated"
                              @click="deleteDoc(ct.generated)"
                              v-b-tooltip.hover><TrashSvg class="svg-light"/>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>

        <!--        <div v-for="ct in list"-->
        <!--             class="py-1"-->
        <!--             :class="{ 'border-bottom': !printAgreements }"-->
        <!--             :key="`ct-${ct.id}`">-->
        <!--          <b-row class="align-items-center">-->
        <!--            <b-col :md="7" cols="12">-->
        <!--              <a @click="selectContract(ct)"-->
        <!--                 class="d-block pointer py-1 text-decoration-none">- {{ ct.title }}</a>-->
        <!--            </b-col>-->
        <!--            <b-col md="2" cols="6">-->
        <!--              <div v-if="!ct.generated">{{ $t('not_generated') }}</div>-->
        <!--              <div v-else-if="ct.generated && ct.generated.signed_at && ct.generated.trustMeDownloadLink" class="text-success">{{ $t('signed_via_trustme') }}</div>-->
        <!--              <div v-else-if="ct.generated && ct.generated.signed_at && ct.generated.sign_api === 'onlinecontract'" class="text-success">{{ $t('signed_via_onlinecontract') }}</div>-->
        <!--              <div v-else-if="ct.generated && ct.generated.signed_at" class="text-success">{{ $t('signed') }}</div>-->
        <!--              <div v-else-if="ct.generated && ct.generated.fileManualUrl" class="text-primary">{{ $t('uploaded') }}</div>-->
        <!--              <div v-else  class="text-info">{{ $t('generated') }}</div>-->
        <!--            </b-col>-->
        <!--            <b-col md="3" cols="6">-->
        <!--              <b-button :title="$t('generate')"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="!ct.generated"-->
        <!--                        @click="generateDoc(ct)"-->
        <!--                        v-b-tooltip.hover><GenerateSvg class="svg-light"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="`${$t('file')} (pdf)`"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none position-relative"-->
        <!--                        v-if="ct.generated && ct.generated.filePdfUrl"-->
        <!--                        @click="loadDoc(ct.generated.filePdfUrl)"-->
        <!--                        v-b-tooltip.hover><FilePdfSvg class="svg-light"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="`${$t('file')} (word)`"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none position-relative"-->
        <!--                        v-if="ct.generated && ct.generated.fileWordUrl"-->
        <!--                        @click="loadWordDoc(ct.generated.fileWordUrl)"-->
        <!--                        v-b-tooltip.hover><FileWordSvg class="svg-light"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="`${$t('file')}${ct.generated.signed_at ? ' - '+$t('signed') : ''}`"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none position-relative"-->
        <!--                        v-if="ct.generated && ct.generated.fileManualUrl"-->
        <!--                        @click="loadDoc(ct.generated.fileManualUrl)"-->
        <!--                        v-b-tooltip.hover><FilePdfSvg class="svg-light"/><SignedSvg class="signed-svg" v-if="ct.generated.signed_at"/>-->
        <!--              </b-button>-->
        <!--                <label :for="`manual_doc_${ct.id}`"-->
        <!--                       class="btn btn-link pointer mb-0"-->
        <!--                       :class="{ disabled: loading }"-->
        <!--                       :title="$t('upload_signed')"-->
        <!--                       v-if="ct.generated && !ct.generated.fileManualUrl && !ct.generated.signed_at"-->
        <!--                       v-b-tooltip.hover>-->
        <!--                  <UploadSvg class="svg-stroke-light"/>-->
        <!--                  <input type="file"-->
        <!--                         accept="application/pdf"-->
        <!--                         class="d-none"-->
        <!--                         :multiple="false"-->
        <!--                         :key="`input-${ct.id}-${counter}`"-->
        <!--                         :id="`manual_doc_${ct.id}`"-->
        <!--                         @change="processFile($event, ct)"/>-->
        <!--                </label>-->
        <!--              <b-button :title="$t('sign')"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated && !ct.generated.signed_at && !ct.trustme_id"-->
        <!--                        @click="signDoc(ct.generated)"-->
        <!--                        v-b-tooltip.hover><SignSvg class="svg-light"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="$t('sign_via_trustme')"-->
        <!--                        variant="link"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated && !ct.generated.signed_at && trustMeEnabled && !ct.generated.trustMeDownloadLink"-->
        <!--                        @click="signDocTrustMe(ct.generated)"-->
        <!--                        v-b-tooltip.hover><ShieldSvg class="svg-light svg-size-lg"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="$t('sign_via_onlinecontract')"-->
        <!--                        variant="link"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated && !ct.generated.signed_at && onlineContractEnabled"-->
        <!--                        @click="signDocOnlineContract(ct.generated)"-->
        <!--                        v-b-tooltip.hover><StampSvg class="svg-stroke-light svg-size-lg"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="$t('see_in_trustme')"-->
        <!--                        variant="link"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated && ct.generated.trustMeDownloadLink && trustMeEnabled"-->
        <!--                        @click="loadDoc(ct.generated.trustMeDownloadLink)"-->
        <!--                        v-b-tooltip.hover><EyeSvg class="svg-light svg-size-lg"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="$t('see_in_onlinecontract')"-->
        <!--                        variant="link"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated && ct.generated.signed_at && onlineContractEnabled && ct.generated.sign_api === 'onlinecontract'"-->
        <!--                        @click="loadOnlineContractDoc(ct.generated)"-->
        <!--                        v-b-tooltip.hover><EyeSvg class="svg-light svg-size-lg"/>-->
        <!--              </b-button>-->
        <!--              <b-button :title="$t('delete')"-->
        <!--                        variant="link"-->
        <!--                        :disabled="loading"-->
        <!--                        class="pointer outline-none"-->
        <!--                        v-if="ct.generated"-->
        <!--                        @click="deleteDoc(ct.generated)"-->
        <!--                        v-b-tooltip.hover><TrashSvg class="svg-light"/>-->
        <!--              </b-button>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </div>-->
      </div>
    </div>
    <div v-if="loaded">
      <label class="form-label mt-0">{{ $t('document') }}</label>
      <!--      <ckeditor class=""-->
      <!--                @namespaceloaded="onNamespaceLoaded"-->
      <!--                :config="ckConfig"-->
      <!--                v-model="template">-->
      <!--      </ckeditor>-->
      <jodit-editor v-model="template"
                    :buttons="joditConfig.buttons"
                    :config="joditConfig.config"/>
      <TagsList :type="printAgreements ? 'agreement' : 'contract'"
                class="mt-4">
        {{ printAgreements ? $t('tags_for_document') : $t('tags_for_contract') }}
      </TagsList>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="loaded ? loaded = false : close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading || !selectedContract"
                v-if="loaded"
                @click="next"
                class="ml-3 btn-themed align-middle btn-themed-squared">
          {{ loaded ? $t('print_noun') : $t('continue') }}
        </button>
      </div>
    </template>
    <OnlineContractModal :generatedDoc="selectedForOnlineContract"
                         @closed="onShow"/>
  </b-modal>
</template>

<script>
import { JoditEditor } from 'jodit-vue'
import { DocumentsService, EloquentService } from "@/services/api.service"
import JwtService from "@/services/jwt.service"
import {mapGetters, mapState} from "vuex"
import GenerateSvg from '@/assets/svg-vue/general/refresh2.svg'
import SignSvg from '@/assets/svg-vue/form/tick.svg'
import SignedSvg from '@/assets/svg-vue/form/tick.svg'
import FilePdfSvg from '@/assets/svg-vue/patients/file_pdf.svg'
import FileWordSvg from '@/assets/svg-vue/patients/file_word.svg'
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import UploadSvg from '@/assets/svg-vue/patients/upload.svg'
import TagsList from "@/components/parts/crm/TagsList.vue"
import ShieldSvg from '@/assets/svg-vue/patients/shield.svg'
import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import StampSvg from '@/assets/svg-vue/patients/stamp.svg'
import { joditConfig } from "@/dictionaries/joditConfig"
import OnlineContractModal from "@/components/parts/patients/OnlineContractModal.vue"
import {getOnlineContractKzContractPdf} from "@/services/onlineContract.helper"
import MinusSvg from "@/assets/svg-vue/general/boxminus.svg.vue"
import PlusSvg from "@/assets/svg-vue/general/boxplus.svg.vue"

export default {
  name: "PatientContractSelectModal",
  components: {
    PlusSvg,
    MinusSvg,
    OnlineContractModal,
    TagsList,
    GenerateSvg,
    SignSvg,
    SignedSvg,
    FilePdfSvg,
    FileWordSvg,
    TrashSvg,
    UploadSvg,
    ShieldSvg,
    EyeSvg,
    StampSvg,
    JoditEditor
  },
  data() {
    return {
      loaded: false,
      selectedContract: null,
      selectedContractObj: null,
      selectedForOnlineContract: null,
      template: '',
      loading: false,
      initialLoading: true,
      counter: 0,
      customAgreements: [],
      generatedDocuments: [],
      openedCategories: ['generated'],
    }
  },
  methods: {
    async selectContract(ct) {
      this.selectedContract = ct.id
      this.selectedContractObj = ct
      await this.next()
    },
    async next() {
      let isWord = false
      if(!this.loaded) {
        const templateRes = await DocumentsService.getDocumentTemplate(
            this.documentType,
            this.documentType === 'custom' ? this.selectedContract : null
        )
        this.template = templateRes.data.text || templateRes.data // for compatibility purpose, res.data can be deleted later on
        this.loaded = true
        isWord = templateRes.data.word
        if(!isWord) return
        // return
      }
      if(this.loaded && this.template) {
        const res = await DocumentsService.saveDocumentTemplate(
            this.documentType,
            this.template,
            this.documentType === 'custom' ? this.selectedContract : null
        )
        if(this.selectedContractObj && !this.selectedContractObj?.generated?.signed_at) {
          await this.generateDoc(this.selectedContractObj)
        }
        let link = JwtService.getPrintPrelink(this.clinic)
        let visit_id = '?visit_id=' + (this.visit ? this.visit.id : '')
        let doc_type = this.selectedContract
        if(this.documentType === 'custom') {
          doc_type = 'custom'
          visit_id += '&custom_id='+res.data.id
        }
        window.open(link+'/document/'+this.patient.id+'/'+doc_type + visit_id, '_blank')
        if(isWord) this.$bvModal.hide('contract-modal')
      }
    },
    async loadCustomAgreements(type) {
      const res = await DocumentsService.getAgreementsList(type)
      this.customAgreements = res.data
      if(this.customAgreements.length === 0) {
        this.$store.commit('setPrintAgreements', false)
      }
    },
    async loadGeneratedDocuments() {
      if(!this.patient) return;
      const res = await DocumentsService.getGeneratedDocuments(this.patient.id, this.printAgreements ? (this.visit?.id || '') : '')
      this.generatedDocuments = res.data
    },
    async onShow() {
      this.template = ''
      this.loaded = false
      this.initialLoading = true
      this.loading = false
      await this.loadCustomAgreements(this.printAgreements ? 'agreement' : 'contract')
      await this.loadGeneratedDocuments()
      this.initialLoading = false
    },
    onHide() {
      this.$store.commit('setPrintAgreements', false)
      this.template = ''
      this.loaded = false
      this.customAgreements = []
      this.selectedContract = null
      this.selectedContractObj = null
      this.selectedForOnlineContract = null
    },
    async generateDoc(doc) {
      this.loading = true
      try {
        await DocumentsService.generateDocument(this.patient.id, doc, this.visit?.id || null)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async signDoc(generatedDoc) {
      this.loading = true
      try {
        await DocumentsService.signGeneratedDocument(this.patient.id, generatedDoc.id)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async signDocTrustMe(generatedDoc) {
      this.loading = true
      try {
        const res = await DocumentsService.signGeneratedDocumentViaTrustMe(this.patient.id, generatedDoc.id)
        const url = res?.data?.url || null
        await this.loadGeneratedDocuments()
        if(url) window.open('https://'+url, '_blank')
      } finally {
        this.loading = false
      }
    },
    async signDocOnlineContract(generatedDoc) {
      this.selectedForOnlineContract = generatedDoc
      await this.$nextTick()
      this.$bvModal.show('online-contract-modal')
    },
    async deleteDoc(generatedDoc) {
      this.loading = true
      try {
        await EloquentService.delete('generated_document', generatedDoc.id)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async processFile(event, doc) {
      const file = event.target.files[0]
      this.counter++
      if(file.size > (2 * 1024 * 1024)) { // 2 MB
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        file,
        generatedDocumentId: doc.generated?.id || null,
        docType: doc.type,
        docId: doc.id
      })
      await DocumentsService.uploadToGeneratedDocument(this.patient.id, formData)
      await this.loadGeneratedDocuments()
    },
    loadDoc(url) {
      window.open(url,"_blank")
    },
    async loadOnlineContractDoc(doc) {
      this.$store.commit('setLoading', true)
      await getOnlineContractKzContractPdf(doc.trustme_id)
      this.$store.commit('setLoading', false)
    },
    loadWordDoc(url) {
      window.open(url,"_blank")
      // window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${url}`,"_blank")
    },
    toggleOpenedCategory(categoryItemId) {
      console.log(categoryItemId)
      if(this.openedCategories.includes(categoryItemId)) {
        this.openedCategories = this.openedCategories.filter(obj => obj !== categoryItemId)
      } else {
        this.openedCategories.push(categoryItemId)
      }
    }
  },
  created() {
    JoditEditor.compatConfig = { MODE: 3 }
  },
  computed: {
    joditConfig() {
      return joditConfig
    },
    contractTypes() {
      if(this.showStandardContracts) {
        return [
          { id: 'services', title: 'contract_services', type: 'services' },
        ]
      }
      return []
    },
    ...mapState({
      patient: state => state.patient.patient,
      visit: state => state.patient.visit,
      clinic: state => state.auth.clinic,
      printAgreements: state => state.patient.printAgreements,
      dataTableCounter: state => state.datatable.dataTableCounter
    }),
    ...mapGetters([
      'onlineContractEnabled'
    ]),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {}
    },
    trustMeEnabled() {
      return !!this.ppoSettings.trustme_token
    },
    showStandardContracts() {
      return this.clinic?.clinic_additional?.contract_templates
    },
    documentType() {
      if(this.printAgreements) return 'custom'
      if(this.selectedContract && !isNaN(this.selectedContract)) return 'custom'
      return this.selectedContract
    },
    list() {
      let arr = []
      if(!this.printAgreements) {
        arr = this.contractTypes.map(ca => ({
          ...ca,
          title: this.$t(ca.title),
          generated: this.generatedDocuments.find(gd => gd.document_type === 'services')
        }))
      }
      arr = arr.concat(this.customAgreements.map(ca => ({
        ...ca,
        type: 'custom',
        generated: this.generatedDocuments.find(gd => gd.document_type === 'custom' && gd.document_id === ca.id)
      })))
      return arr
    },
    listByCategories() {
      const arr = { 'none': { items: [], category: { id: 'none', sort_order: -1 } } }
      for (const list of this.list) {
        // const signed = !!list.generated?.signed_at
        const generated = !!list.generated
        let categoryId = null
        if(generated) {
          categoryId = 'generated'
          if(!arr[categoryId]) arr[categoryId] = { items: [], category: { id: 'generated', title: this.$t('generated'),  sort_order: 1000000 } }
        } else {
          categoryId = list.document_category_id || 'none'
          if(!arr[categoryId]) arr[categoryId] = { items: [], category: list.document_category }
        }
        arr[categoryId].items.push(list)
      }
      return Object.values(arr).sort((a, b) => b.category.sort_order - a.category.sort_order)
    }
  },
  watch: {
    dataTableCounter() {
      this.loadGeneratedDocuments()
    }
  }
}
</script>

<style scoped lang="scss">
.signed-svg {
  fill: $green-color;
  position: absolute;
  right: 7px;
  top: 10px;
}
.category-toggle {
  cursor: pointer;
  padding: 10px 0;
  //font-weight: 600;
  border-bottom: 1px solid $calendar-border-color;
}
.category-collapse {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s ease-out;
  &.opened {
    grid-template-rows: 1fr;
  }
  > div {
    overflow: hidden;
  }
}
</style>
