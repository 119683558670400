import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import TemplatesGeneral from "@/components/pages/templates/TemplatesGeneral"
import TextTemplates from "@/components/pages/templates/TextTemplates"
import TextTemplatesDiagnosis from "@/components/pages/templates/TextTemplatesDiagnosis"
import TreatmentTemplates from "@/components/pages/templates/TreatmentTemplates"
import PlanTreatmentTemplates from "@/components/pages/templates/PlanTreatmentTemplates"
import AgreementTemplates from "@/components/pages/templates/AgreementTemplates"
import ContractTemplates from "@/components/pages/templates/ContractTemplates"
import TreatmentTemplatesCategories from "@/components/pages/templates/TreatmentTemplatesCategories"
import AgreementTemplateCategories from "@/components/pages/templates/AgreementTemplateCategories"

export default {
    path: '/templates',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
    // component:() => import('../components/pages/templates/TemplatesGeneral.vue'),
    component: TemplatesGeneral,
    children: [
        { path: '', redirect: '/templates/text' },
        {
            path: 'text', name: "TextTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
            // component: () => import('../components/pages/templates/TextTemplates.vue')
            component: TextTemplates
        },
        {
            path: 'treatment', name: "TreatmentTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
            // component: () => import('../components/pages/templates/TreatmentTemplates.vue')
            component: TreatmentTemplates
        },
        {
            path: 'treatment_categories', name: "TreatmentTemplatesCategories",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
            component: TreatmentTemplatesCategories
        },
        {
            path: 'agreements', name: "AgreementTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['agreements'] },
            // component: () => import('../components/pages/templates/AgreementTemplates.vue')
            component: AgreementTemplates
        },
        {
            path: 'agreement_categories', name: "AgreementTemplateCategories",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['agreements'] },
            // component: () => import('../components/pages/templates/AgreementTemplates.vue')
            component: AgreementTemplateCategories
        },
        {
            path: 'contracts', name: "ContractTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['contracts'] },
            // component: () => import('../components/pages/templates/ContractTemplates.vue')
            component: ContractTemplates
        },
        {
            path: 'plan', name: "PlanTreatmentTemplates",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
            // component: () => import('../components/pages/templates/ContractTemplates.vue')
            component: PlanTreatmentTemplates
        },
        {
            path: 'diagnosis', name: "TextTemplatesDiagnoses",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['treatment_templates'] },
            component: TextTemplatesDiagnosis
        },
    ]
}
