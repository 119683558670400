import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

export default {
    path: '/laboratories',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories'] },
    component:() => import('../components/pages/laboratories/LaboratoriesGeneral.vue'),
    children: [
        { path: '', redirect: '/laboratories/labs' },
        {
            path: 'labs', name: "Laboratories",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories'] },
            component: () => import('../components/pages/laboratories/Laboratories.vue')
        },
        {
            path: 'services', name: "LaboratoriesServices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories'] },
            component: () => import('../components/pages/laboratories/LaboratoriesServices.vue')
        },
        {
            path: 'prices', name: "LaboratoriesPrices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories'] },
            component: () => import('../components/pages/laboratories/LaboratoriesPrices.vue')
        },
        {
            path: 'categories', name: "LaboratoriesCategories",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories'] },
            component: () => import('../components/pages/laboratories/LaboratoriesCategories.vue')
        },
        {
            path: 'orders', name: "LaboratoriesOrders",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['laboratories', 'laboratory_orders'] },
            component: () => import('../components/pages/laboratories/LaboratoriesOrders.vue')
        },
    ]
}
