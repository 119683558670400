import { EloquentService } from "@/services/api.service"
import { appendFormdata } from "@/extensions/prototypes/prototypes"
import { reportPopulatedAdapter } from "@/adapters/patients/reportPopulated"
import JwtService from "@/services/jwt.service"

const prepareNDFLData = async (patient, from, to, docNumber) => {
	const data = await EloquentService.prepare('report_populated', {
		patient_id: patient.id,
		report_name: 'ndfl',
		from: from || '',
		to: to || '',
		docNumber: docNumber || ''
	})
	return data?.data?.model?.data || null
}

const saveReport = async (patient, data, callback) => {
	let formData = new FormData()
	formData = appendFormdata(formData, reportPopulatedAdapter({
		patient_id: patient.id,
		report_name: 'ndfl',
		data
	}))
	const res = await EloquentService.create('report_populated', formData)
	const id = res?.data?.id || null
	if(callback) {
		callback()
	}
	// store.commit('incrementDataTableCounter')
	// bvModal.hide('patient-ndfl-modal')
	return id
}

const moveToReportPage = (clinic, branchId, patient, from, to, id) => {
	let link = JwtService.getPrintPrelink(clinic)
	let url = link +'/document/'+patient.id+'/ndfl?t=r&branchId='+branchId
	if(id) {
		url += '&reportId=' + id
	}
	if(from) url += '&from=' + from
	if(to) url += '&to=' + from
	url += '&isForm=1'
	window.open(url, '_blank')
}

const createAndOpenNDFLReport = async (clinic, branchId, patient, from, to, docNumber, callback) => {
	const data = await prepareNDFLData(patient, from, to, docNumber)
	const id = await saveReport(patient, data, callback)
	moveToReportPage(clinic, branchId, patient, from, to, id)
}

export { createAndOpenNDFLReport }
