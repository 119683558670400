export const agreementTemplateAdapter = ({
                                           title,
                                           text26,
                                           clinic_id,
                                           document_category_id,
                                           filename,
                                         }) => ({
  title: title ? title : null,
  text26: text26 ? text26 : null,
  clinic_id: clinic_id ? clinic_id : null,
  document_type: 'agreement',
  document_category_id: document_category_id ? document_category_id : '',
  filename: ['null', '1', undefined].includes(filename) ? null : filename
})


