<template>
  <div>
    <div class="text-right">
      <a class="btn btn-themed btn-themed-outline btn-themed-squared" @click="add">{{ $t('add') }}</a>
    </div>
    <div class="overflow-auto">
      <DataTableHtml ref="datatable"
                     :columns="columns"
                     initialOrder="orderDate"
                     initialSort="desc"
                     :can-hide-rows="false"
                     :rows="orders"
      />
    </div>
    <b-modal id="add-online-contract-payment-modal"
             :title="$t('payment')"
             :hide-footer="true"
             centered>
      <SelectGroup v-model="onlineContractLicenseType"
                   :showlabel="true"
                   variant="white"
                   :null-option="false"
                   :label="$t('license')"
                   :options="onlineContractLicenseTypesForPayment"
                   class="mb-3"/>
      <SelectGroup v-model="onlineContractPaymentType"
                   :showlabel="true"
                   variant="white"
                   :null-option="false"
                   :label="$t('payment_type')"
                   :options="onlineContractPaymentTypes"
                   class="mb-3"/>
      <div class="text-right">
        <button class="btn btn-themed btn-themed-squared mt-2"
                @click="createOrder"
                :disabled="loading">
          {{ $t('save') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue"
import { DocumentsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml.vue"
import {
  onlineContractLicenseTypes, onlineContractLicenseTypesForPayment,
  onlineContractPaymentTypes,
} from "@/dictionaries/dictionary"
import SelectGroup from "@/components/form/SelectGroup.vue"
import {openBase64AsPdf} from "@/services/onlineContract.helper"

const orders = ref([])
const page = ref(1)
const onlineContractLicenseType = ref(0)
const onlineContractPaymentType = ref(0)
const loading = ref(false)
let bvModal = null

const columns = [
  {
    id: 'orderNumber',
    field: 'orderNumber',
    label: "Номер",
    sortable: true,
  },
  {
    id: 'orderDate',
    field: 'orderDate',
    label: "Дата",
    format_datetime: true,
    sortable: true,
  },
  {
    id: 'orderSum',
    field: 'orderSum',
    label: "Сумма",
    format_number: true,
    sortable: true,
  },
  {
    id: 'eAdmLicenseType',
    field: 'eAdmLicenseType',
    label: "Лицензия",
    search_select: true,
    sortable: true,
  },
  {
    id: 'eDetCtcClientOrderPayType',
    field: 'eDetCtcClientOrderPayType',
    label: "Тип оплаты",
    search_select: true,
    sortable: true,
  },
  {
    id: 'isPayed',
    field: 'isPayed',
    label: "Оплачено",
    search_select: true,
    sortable: true,
  },
  {
    id: 'link',
    field: 'link',
    label: "Линк",
    tdcomponent: 'OnlineContractDatatableTDLink',
  }
]

onMounted(() => {
  loadOrders()
  bvModal = (getCurrentInstance())?.ctx?._bv__modal
})

const loadOrders = async () => {
  const res = await DocumentsService.getOnlineContractOrders(page.value)
  console.log(res.data)
  orders.value = res.data?.data.map(order => {
    return {
      id: order.detCtcClientOrderId,
      orderNumber: order.orderNumber,
      orderDate: order.orderDate,
      orderSum: order.orderSum,
      eAdmLicenseType: onlineContractLicenseTypes.find(obj => obj.id === order.eAdmLicenseType)?.title || '-',
      eDetCtcClientOrderPayType: onlineContractPaymentTypes.find(obj => obj.id === order.eDetCtcClientOrderPayType)?.title || '-',
      isPayed: order.isPayed ? 'Да' : 'Нет',
    }
  })
}

const add = () => {
  bvModal.show('add-online-contract-payment-modal')
}

const createOrder = async () => {
  loading.value = true
  try {
    const res = await DocumentsService.createOnlineContractOrder({
      eAdmLicenseType: onlineContractLicenseType.value,
      eAdmLicenseTypeName: onlineContractLicenseTypes.find(obj => obj.id === Number(onlineContractLicenseType.value))?.title || '-',
      eDetCtcClientOrderPayType: onlineContractPaymentType.value,
      eDetCtcClientOrderPayTypeName: onlineContractPaymentTypes.find(obj => obj.id === Number(onlineContractPaymentType.value))?.title || '-',
    })
    if(res.data) {
      bvModal.hide('add-online-contract-payment-modal')
      DocumentsService.getOnlineContractOrderLink(res.data, 1).then(pdfResponse => {
        openBase64AsPdf(pdfResponse.data)
      })
    }
    await loadOrders()
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}
</script>
