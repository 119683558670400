export default {
    insurance_companies: "Insurance companies",
    insurance_polis: "Insurance polis",
    limit: "Limit",
    number: "Number",
    polis: "Polis",
    polises: "Polises",
    use_custom_prices_for_insurance: "Use custom prices for insurance companies",
    use_only_in_insurance: "Use only in insurance",
    valid_from: "Valid from",
    valid_to: "Valid to",
    visit_should_have_polis_set_in_order_to_use_insurance_payment: "The visit must have insurance policy filled to use the insurance payment",
}
