export default {
    primaryKey: "id",
    model: 'online_contract_payment',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    deletemessage: 'confirm_deletion',
    actionscomponent: 'SuperAdminOnlineContractActions',
    actions: [],
    fields: [
        {
            id: 'online_contract_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'clinic',
            title: 'clinic',
            table: true,
            table_show: ['clinic.id', 'clinic.name'],
        },
        {
            id: 'order_number',
            title: 'number',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true
        },
        {
            id: 'order_date',
            title: 'date',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'sum',
            title: 'sum',
            table: true,
            sortable: true,
            format_number: true,
            filter: true
        },
        {
            id: 'payment_type',
            title: 'payment_type',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'license_type',
            title: 'Тип лицензии',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            sortable: true,
            tdcomponent: 'CRMDatatableTDYesNoBoolean'
        }
    ]
}
