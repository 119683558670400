<template>
  <div>
    <span class="text-primary" v-if="object[field.id]">{{ $t('yes') }}</span>
    <span class="text-secondary" v-if="!object[field.id]">{{ $t('no') }}</span>
  </div>
</template>

<script>
export default {
  name: "BooleanTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>
