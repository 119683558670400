import {laboratoryOrderStatuses} from "@/dictionaries/dictionary"

export default {
    primaryKey: 'id',
    model: 'laboratory_order',
    modaltitle: 'requirement_order',
    order: 'id',
    sort: 'asc',
    actions: [
        // "add",
        "excel",
        // "edit",
        "delete",
    ],
    rights: {
        delete: "delete_laboratory_orders",
    },
    deleteCondition(object) {
        return object.status !== 'finished'
    },
    deletemessage: 'confirm_deletion',
    actionscomponent: 'LaboratoryOrdersDatatableActions',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'order_number',
            tabletdclass: "order-mob-0",
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'created_at',
            title: 'creation_date',
            table: true,
            sortable: true,
            format_date: true,
            filter: true,
            filter_date_with_interval: true
        },
        {
            id: 'patient_id',
            title: 'patient',
            table_show: 'record.patient.full_name',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'laboratory',
            title: 'laboratory',
            table_show: 'laboratory.title',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'sum',
            title: 'sum',
            table: true,
            sortable: true,
            format_currency: true,
            filter: false
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            table_show: 'record.doctor.full_name',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_data: {
                    "title": "full_name",
                },
            },
        },
        {
            id: 'status',
            title: 'status',
            format_translate: true,
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: laboratoryOrderStatuses
            }
        },
        {
            id: 'record',
            title: 'visit',
            table_show: 'record.readableStart',
            table: true,
            sortable: true,
            filter: true,
            filter_date_with_interval: true
        },
    ]
}
