<template>
  <div class="visit-requirement-order pb-5"
       style="max-width: 800px;">
    <div class="">
      <div class="row"
           v-if="order">
        <div class="col-md-6">
          {{ $t('requirement_order') }} #{{ order.id}}
        </div>
        <div class="col-md-6">
          {{ $t('patient') }}: {{ patient.full_name }}
        </div>
        <div class="col-md-6">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">{{ $t('executor') }}: </div>
            <div class="col pl-2" v-if="order">
              <SelectGroup :options="laboratories"
                           @input="labSelected"
                           v-model="order.laboratory_id"
                           class="sm w-100 mb-0"
                           :readonly="readOnly"
                           variant="white"
                           label="title"/>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          {{ $t('visit') }}: {{ visit.readableStart }} - {{ formatDate(visit.end, 'H:m') }}
        </div>
        <div class="col-md-12" v-if="order && order.status">
          {{ $t('status') }}: {{ $t(order.status) }}
        </div>
      </div>
    </div>
    <div>
      <div>
        <div v-if="!filteredServices.length" class="alert alert-warning mt-4">
          {{ $t('no_dental_work_needed_for_selected_services') }}
        </div>
        <div v-for="(service, index) in filteredServices"
             class="mt-4 pt-2"
             :key="`service-${index}`">
          <VisitRequirementOrderService :service="service"
                                        :readOnly="readOnly"
                                        @changed="labServicesChanged"
                                        :lab-services="labServices.filter(obj => obj.service_id === service.id)"/>
        </div>
      </div>
<!--      <VisitRequirementOrderService :service="null"-->
<!--                                    class="mt-5"-->
<!--                                    :readOnly="readOnly"-->
<!--                                    @changed="labServicesChanged"-->
<!--                                    :lab-services="labServices.filter(obj => !obj.service_id)"/>-->
      <div class="text-right mt-4">
        {{ $t('total') }}: <b>{{ formatCurrency(totalSum) }}</b>
      </div>
    </div>
    <div>
      <AutosizeTextArea v-model="order.description"
                        @input="orderChanged = true"
                        :readonly="readOnly"
                        :label="$t('description')"/>
    </div>
    <div class="row">
      <div class="col">
        <DateGroup :label="$t('fitting_date')"
                   :readonly="readOnly"
                   v-model="order.fitting_date"/>
      </div>
      <div class="col">
        <DateGroup :label="$t('delivery_date')"
                   :readonly="readOnly"
                   v-model="order.delivery_date"/>
      </div>
    </div>

    <div class="mt-4 text-right">
      <button class="btn-themed btn-themed-squared btn-themed-outline d-inline-block"
              @click="print">
        {{ $t('print') }}
      </button>
      <button :disabled="loading"
              v-if="!readOnly"
              class="btn-themed btn-themed-squared d-inline-block ml-3"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>

  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref} from "vue"
import {useStore} from "vuex"
import {formatCurrency, formatDate} from "@/extensions/filters/filters"
import VisitRequirementOrderService from "@/components/parts/patients/visit/VisitRequirementOrderService.vue"
import {EloquentService, LaboratoryService} from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import JwtService from "@/services/jwt.service"
import DateGroup from "@/components/form/DateGroup.vue"

const props = defineProps({
  textFieldsDisabled: {
    type: Boolean,
    default: false
  }
})

const store = useStore()

const clinic = computed(() => store.state.auth.clinic)
const visit = computed(() => store.state.patient.visit)
const patient = computed(() => store.state.patient.patient)
const rights = computed(() => store.state.auth.rights)

const laboratories = ref([])
const labServices = ref([])
const order = ref([])
const loading = ref(false)
const orderChanged = ref(false)
let noty = null
let i18n = null

const filteredServices = computed(() => {
  const services = []
  visit.value.record_teeth.forEach(recordTooth => {
    recordTooth.services.forEach(service => {
      if(service.dental_work_required !== null && service.teeth) {
        services.push(service)
      }
    })
  })
  return services
})

const totalSum = computed(() => {
  return labServices.value.reduce((acc, obj) => acc + Number(obj.price), 0)
})

const readOnly = computed(() => {
  return order.value?.status === 'finished' ||
      (props.textFieldsDisabled && !rights.value.includes('edit_laboratory_orders'))
})

onBeforeMount(() => {
  const instance = (getCurrentInstance())
  i18n = instance?.ctx?.$i18n
  noty = instance?.appContext?.app?.noty
})

onMounted(() => {
  loadLabServices()
})

const loadLabServices = async () => {
  const response = await LaboratoryService.getRecordLabServices(patient.value.id, visit.value.id)
  labServices.value = response.data.services
  laboratories.value = response.data.laboratories
  order.value = response.data.order
}

const labServicesChanged = (data) => {
  const serviceId = data.serviceId
  labServices.value = labServices.value.filter(obj => obj.service_id !== serviceId)
  labServices.value = labServices.value.concat(data.services)
}

const save = async () => {
  loading.value = true
  try {
    if(orderChanged.value && order.value) {
      let formData = new FormData()
      formData.append('description', order.value.description || '')
      formData.append('fitting_date', order.value.fitting_date || '')
      formData.append('delivery_date', order.value.delivery_date || '')
      await EloquentService.update('laboratory_order', order.value.id, formData)
    }
    await LaboratoryService.saveRecordLabServices(patient.value.id, visit.value.id, labServices.value)
    noty.info(i18n.t('success_message'))
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

const print = () => {
  let link = JwtService.getPrintPrelink(clinic.value)
  window.open(link+'/requirement_order/'+order.value.id, '_blank')
}

const labSelected = (val) => {
  LaboratoryService.assignLabToVisit(patient.value.id, visit.value.id, order.value.id, {
    laboratory_id: val,
    status: order.value.status
  }).catch(e => {
    console.log(e)
  })
}
</script>
