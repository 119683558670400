<template>
<span v-if="object.deleted_at">
  <a class="data-table-action"
     v-b-tooltip.hover
     @click="restore"
     :title="$t('restore')">
      <RestoreSvg/><span class="action-button-mob-title">{{ $t('restore') }}</span>
    </a>
    </span>
</template>

<script setup>
import { useStore } from "vuex"
import { EloquentService } from "@/services/api.service"
import RestoreSvg from '@/assets/svg-vue/accounting/back.svg'

const props = defineProps({
  object: {
    type: Object
  }
})

const store = useStore()

const restore = async () => {
  await EloquentService.restore('laboratory_service', props.object.id)
  store.commit('incrementDataTableCounter')
}
</script>
