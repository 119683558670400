export default {
    add_call_task: "Додати завдання на дзвінки до",
    add_to_funnel: "Додати до воронки",
    add_to_funnel_came_and_repeated: "Додати до воронки 'Прийшли і повторні'",
    all_days: "Всі дні",
    amo_deal_id: "ID угоди Amo",
    api_key: "Ключ API",
    applications_and_appeals: "Заявки та звернення",
    are_you_sure_you_want_to_delete_task: "Ви впевнені, що хочете видалити завдання?",
    ask_for_confirmation_response_from_patient: "Запитувати відповідь-підтвердження від пацієнта",
    authorized: "Авторизовано",
    attach_file: "Прикріпити файл",
    binotel: "Binotel",
    binotel_integration: "Інтеграція з Binotel",
    binotel_integration_instructions: "Для інтеграції Dentist Plus з Binotel необхідно:<br>1. створити обліковий запис в Binotel<br>2. написати листа на підтримку Binotel з проханням налаштувати наступний webhook для <b>API CALL SETTINGS</b> та <b>API CALL COMPLETED</b>:<br><span class='text-primary'>{url}</span><br>у полі нижче вказати користувачів з персоналу клініки, які використовуватимуть функціонал binotel",
    binotel_key: "Binotel ключ",
    binotel_secret: "Binotel секретний ключ",
    binotel_internal: "Binotel внутрішній телефон",
    button_title: "Назва кнопки",
    call_from_patient: "Дзвінок від пацієнта",
    call_from_phone: "Зателефонуйте за номерами",
    call_noun: "Дзвінок",
    callee: "Той, кого дзвонять",
    cancel_sending: "Скасувати відправку",
    cancellation_response: "Відповідь для скасування візиту",
    change_status: "Змінити статус",
    client_comment: "Коментар клієнта",
    close_the_integration_for_this_branch: "Закрити інтеграцію для цього філіалу",
    confirmation_response: "Відповідь на підтвердження візиту",
    copied: "Скопійовано",
    copy_to_clipboard: "Копіювати в буфер обміну",
    create_application_on_call: "Створювати автоматично картку дзвінка в Заявки та звернення",
    create_card_automatically: "Створювати картку автоматично",
    create_lead: "Створювати апеляцію",
    create_lead_on_incoming_messages: "Створювати заявку на вхідні повідомлення в whatsapp з невідомого номера",
    crm: "CRM",
    curator_assigned: "Куратор призначений",
    deadline: "Термін виконання",
    deal: "Угода",
    do_not_create_duplicates: "Не створювати дублікати",
    do_not_create_duplicates_on_call: "Не створювати дублікати по дзвінках, якщо вже є активна заявка до статусу \"Записаний\"",
    do_not_create_lead_if_patient_exists: "Не створювати заявку, якщо пацієнт вже є в базі",
    do_not_show_anymore: "Більше не показувати",
    feedback: "Відгук",
    feedbacks: "Відгуки",
    follow: "Перейти",
    for_came_from_lead_funnel: "Для прийшли з воронки 'Заявки та звернення'",
    for_came_on_first_visit: "Для прийшли на перший візит",
    for_came_to_consultation: "Для прийшли на консультацію",
    for_incoming: "Для вхідних",
    for_missed: "Для пропущених",
    for_outcoming: "Для вихідних",
    funnel_came_and_repeated: "Вирва: дійшли і повторні",
    generate_key: "Згенерувати ключ",
    get_gift: "Отримати подарунок",
    has_overdue_tasks: "Має прострочені завдання",
    has_tasks: "Має завдання",
    has_today_task: "Має завдання на сьогодні",
    if_lead_has_no_responsible_then_assign_to_this_person_on_first_save: "Якщо у заявки немає відповідальної особи, то при першому збереженні призначити на цю особу",
    inprogress: "В роботі",
    insert_script_one_time_instruction: "Встановіть даний скрипт на сайт в секцію <head>",
    insert_widget_button_instruction: "Встановіть даний код кнопки в місце, де вона повинна знаходитися",
    instruction: "Інструкція",
    integration_key: "Ключ інтеграції",
    internal_phone: "Внутрішній телефон",
    lead_channel: "Канал заявки",
    leave_comment: "Залишити коментар",
    manually_selected_user: "Вручну вибраний користувач",
    mark_as_completed: "Відмітити як виконане",
    mark_as_finished: "Відмітити як виконане",
    mark_as_in_progress: "Відмітити як в роботі",
    move_to_funnel_came_and_repeated: "Перенести в воронку 'Дійшли і повторні'",
    my_deals: "Мої угоди",
    my_tasks: "Мої завдання",
    no_rating: "Немає рейтингу",
    nobody: "Ніхто",
    one_patient_one_lead: "Один пацієнт - одна справа",
    open_whatsapp: "Відкрити whatsapp",
    outgoing_calls_through: "Вихідні дзвінки через",
    overdue: "Прострочено",
    patient_already_has_active_deal: "У пацієнта вже є активна угода: {dealId}",
    patient_has_no_future_visits_continue: "Не знайдено візитів у пацієнта на майбутні дати. Все одно продовжити?",
    patient_not_derived: "Пацієнт не визначений",
    patient_not_derived_from_phone: "Дзвінок із номера {phone} - пацієнт не визначений",
    plan_treatments: "Плани лікування",
    planned: "Заплановано",
    publish: "Опублікувати",
    published: "Опубліковано",
    rating: "Рейтинг",
    recall: "Передзвонити",
    recall_on_lead: "Передзвонити по заявці '{title}'",
    record_myself: "Записатися",
    record_on_visit: "Запис на прийом",
    recorded_on_visit: "Записаний на прийом",
    responsible: "Відповідальний",
    responsible_on_call: "Відповідальний при дзвінку",
    responsible_on_other_leads_not_created_manually: "Відповідальний за інші заявки, не створені вручну",
    sales_funnel: "Воронка продажів",
    search_by_name: "Пошук за ім'ям",
    search_by_phone: "Пошук за телефоном",
    see_later: "Переглянути пізніше",
    sipuni: "Sipuni",
    sipuni_integration_instructions: "Для інтеграції Dentist Plus із Sipuni необхідно: <br>1. створити обліковий запис у Sipuni<br>2. в особистому кабінеті Sipuni у розділі API згенерувати ключ інтеграції, натиснути 'підключити послугу' у пунктах 'Дзвінок на номер' та 'Події на АТС', а також у пункті 'Події на АТС' вказати 'URL приймаючого скрипта':<br>< span class='text-primary'>{url}</span><br>3. у полі нижче вказати користувачів з персоналу клініки, які використовуватимуть функціонал sipuni, та заповнити інші поля нижче:",
    soon: "Скоро",
    stages: "Етапи",
    stage: "Етап",
    start_period: "Термін початку",
    system_number: "Номер у системі",
    task: "Завдання",
    task_date: "Дата завдання",
    task_type: "Тип завдання",
    tasks_and_calls: "Завдання і дзвінки",
    tilda_integration_instructions: "Щоб отримувати вебхуки від форм на вашому сайті Tilda і заводити ліди в системі dentist-plus, необхідно наступне:<br/>1. В особистому кабінеті Tilda зайти в Налаштування сайту → Форми → Webhook<br/>2. У полі Webhook URL ввести <span class='text-primary'>{url}</span><br>3. У полі API METHOD вибрати 'HEADER', у 'Field name' заповнити 'API-KEY', а саме поле 'Api key' скопіювати ключ, який ви можете згенерувати нижче.<br/>4. У додаткових налаштуваннях активуйте 'Посилати Cookies'.<br/>5. Наступні поля з форм будуть автоматично заповнюватися в системі dentist-plus: <b>name, phone, email, comment</b>.",
    treatment_plan_signed: "Підписаний план лікування",
    treatment_refused: "Відмова від лікування",
    treatment_started: "Почав лікування",
    turn_on_chat_panel: "Увімкнути панель чатів",
    uis: "UIS",
    uis_integration_instructions: "Для інтеграції Dentist Plus із UIS необхідно: <br>1. створити обліковий запис у UIS<br>2. у особистому кабінеті UIS відкрити налаштування потрібного користувача (наприклад, Адміністратора) і у розділі Доступ до функціональності API активувати налаштування 'Використовувати ключ API'<br/>3. У пункті меню 'Сповіщення' створити 2 сповіщення: для очікування відповіді та закінчення розмови. У обох випадках активувати метод сповіщень HTTP і у полі URL вказати посилання:<br><span class='text-primary'>{url}</span><br>4. у полі нижче вказати користувачів з персоналу клініки, які використовуватимуть функціонал uis, та заповнити їх віртуальні номери (для прийому та ініціалізації дзвінків):",
    unpublish: "Зняти з публікації",
    use_common_name_for_documents_for_all_branches: "Використовувати загальну назву в документах для всіх філіалів",
    user_add_id: "Автор",
    view: "Перегляд",
    visit_confirm: "Підтвердження прийому",
    visit_source: "Джерело візиту",
    without_tasks: "Без завдань",
    write_in_messenger: "Написати в месенджер",
    you_also_can_use_link: "Ви також можете використовувати посилання на форму безпосередньо",
}
