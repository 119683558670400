export default {
    after_scanning_qr: "After scanning qr code",
    application_reason: "Reason for applying",
    are_you_sure_you_want_to_delete_lead: "Are you sure you want to delete the lead?",
    check_status: 'Check status',
    click_here: "click here",
    connect: "Connect",
    connection_is_right_can_be_used: "Connection is valid - ready for use",
    'crm-form': "CRM-form",
    forms: "Forms",
    lead: "Lead",
    leads: "Leads",
    not_reached: "Did not reach",
    site_form: "Form",
    stale: "Stale",
    telegram: "Telegram",
    thinking: "Thinking",
    unpaid_message: 'Your account has an overdue payment. Please top up your balance or contact technical support.',
    urgent: "Urgent",
    whatsapp: "Whatsapp",
    whatsapp_qr_instructions: "On your phone, in WhatsApp settings, select 'link device' and scan the QR code",
}
