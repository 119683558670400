<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :transferToExcel="transferToExcel"
                   :excel-columns-adjustment="excelColumnsAdjustment"
                   :rows="rows"/>
  </div>
</template>

<script>
import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsVisitsDetailed",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    patients: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('visits'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'day',
          field: 'day',
          label: this.$t('date'),
          sortable: true,
        },
        {
          id: 'time',
          field: 'time',
          label: this.$t('time'),
          sortable: true,
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_phone',
          field: 'patient_phone',
          label: this.$t('phone'),
          format_phonecall: true,
          sortable: true
        },
        {
          id: 'services',
          field: 'services',
          label: this.$t('services'),
          format_html: true,
          sortable: true
        },
        {
          id: 'services_cnt',
          field: 'services_cnt',
          label: this.$t('services_amount'),
          sortable: true,
          format_number: true,
        },
        {
          id: 'price',
          field: 'price',
          label: this.$t('sum_with_discount'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'paid',
          field: 'paid',
          label: this.$t('paid'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'patient_status',
          field: 'patient_status',
          label: this.$t('patient_status'),
          search_select: true,
          sortable: true
        },
        {
          id: 'author_name',
          field: 'author_name',
          label: this.$t('administrator'),
          search_select: true,
          sortable: true,
        },
        {
          id: 'curator',
          field: 'curator',
          label: this.$t('curator'),
          search_select: true,
          sortable: true
        },
        {
          id: 'who_changed_status',
          field: 'who_changed_status',
          label: this.$t('who_changed_status'),
          search_select: true,
          sortable: true
        },
        {
          id: 'teeth',
          field: 'teeth',
          label: this.$t('teeth'),
          sortable: true
        },
        {
          id: 'pay_methods',
          field: 'pay_methods',
          label: this.$t('pay_methods'),
          sortable: true
        },
        {
          id: 'previous_visit_date',
          field: 'previous_visit_date',
          label: this.$t('previous_visit_date'),
          sortable: true
        },
        {
          id: 'next_visit_date',
          field: 'next_visit_date',
          label: this.$t('next_visit_date'),
          sortable: true
        },
        {
          id: 'result',
          field: 'result',
          label: this.$t('visit_result'),
          search_select: true,
          sortable: true
        },
        {
          id: 'description',
          field: 'description',
          label: this.$t('description'),
          sortable: true
        },
        {
          id: 'record_type',
          field: 'record_type',
          label: this.$t('record_type'),
          search_select: true,
          sortable: true
        },
      ],
      rows: []
    }
  },
  computed: {
    rights() {
      return this.$store.state.auth.rights
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('visits_detailed', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(a => a.id),
        patients: this.patients.map(a => a.id),
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    },
    excelColumnsAdjustment(columns)  {
      return columns
    },
    transferToExcel(data) {
      let excelRows = []
      if(data) {
        data.forEach(item => {
          if(item.egisz) item.egisz = this.$t(item.egisz)
          excelRows.push(item)
        })
      }
      return excelRows
    },
  },
  mounted() {
    this.loadReport()
  }
}
</script>
