<template>
  <b-modal id="visit-online-booking-links-modal"
           hide-footer
           :title="$t('links')"
           @show="loadVisit"
           size="md"
           centered>
    <div v-for="d in dictionary" :key="`${d.id}-clip`">
      <InputGroup :readonly="true"
                  :label="$t(d.label)"
                  :value="values[d.id]">
        <a class="copy-to-clipboard px-2"
           @click="copyToClipboard(d.id, values[d.id])"
           v-b-tooltip
           :title="d.copied ? $t('copied') : $t('copy_to_clipboard')">
          <CopySvg v-if="!d.copied"/>
          <TickSvg class="svg-light svg-size-md" v-if="d.copied"/>
        </a>
      </InputGroup>
    </div>
  </b-modal>
</template>

<script setup>
import { useStore } from "vuex"
import {computed, reactive, ref} from "vue"
import { EloquentService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup.vue"
import CopySvg from '@/assets/svg-vue/patients/copy.svg'
import TickSvg from '@/assets/svg-vue/form/tick.svg'

const store = useStore()

const visit = computed(() => store.state.patient.visit)
const dictionary = ref([
  { id: 'confirmLink', copied: false, label: 'tag_URL_VIZIT_CONFIRMATION' },
  { id: 'reviewLink', copied: false, label: 'tag_URL_VIZIT_REVIEW' }
])

const values = reactive({
  confirmLink: null,
  reviewLink: null
})

const loadVisit = async () => {
  const record = await EloquentService.show('record', visit.value.id)
  values.confirmLink = record?.data?.onlineBookingConfirmationLink || ''
  values.reviewLink = record?.data?.onlineBookingReviewLink || ''
}

const copyToClipboard = (id, text) => {
  navigator.clipboard.writeText(text)
  dictionary.value.forEach(item => {
    console.log(item.id === id)
    item.copied = item.id === id
    setTimeout(() => item.copied = false, 2000)
  })
}
</script>

<style scoped lang="scss">
.copy-to-clipboard {
  position: absolute;
  cursor: pointer;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  right: 1px;
  top: 1px;
  width: 40px;
  bottom: 1px;
  //height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $light-back;
  border-left: 1px solid $gray-icon;
}
</style>
