export default {
    acquiring_device: "Устройство приема платежей",
    cashdesk: "Касса",
    cash_operation: "Кассовая операция",
    cashier_inn: "ИНН кассира",
    cashier_name: "Имя кассира",
    cheque: "Чек",
    cheques: "Чеки",
    closing_balance: "Остаток на конец дня",
    close_shift: "Закрыть смену",
    data_is_invalid: "Данные невалидны",
    data_is_valid: "Данные валидны",
    deposit_to_cashdesk: "Внести в кассу",
    error_printing_cheque: "Ошибка печати чека",
    fiscal_device: "Устройство ККТ",
    injection: "Пополнение",
    kkm_url: "HTTP адрес сервера торгового оборудования. Заполнять в случае, если ккм сервер подключен нелокально.",
    name_of_service_in_cheque: "Название услуги в чеке",
    not_subject_to_vat: "Не облагается НДС",
    opening_balance: "Остаток на начало дня",
    open_shift: "Открыть смену",
    opened_at: "Дата открытия",
    print_totals: "Печать итогов",
    sales: "Продажи",
    send_services_in_cheque: "Передавать услуги в чеке",
    shift: "Смена",
    shifts: "Смены",
    shift_number: "Номер смены",
    terminal: "Терминал",
    vat_rate: "Ставка НДС",
    waiting_for_payment_in_terminal: "Ожидаем оплату на терминале",
    withdrawal: "Изъятие",
    withdraw_from_cashdesk: "Изъять из кассы",
    xreport: "X-отчет",
    xreports: "X-отчеты"
}
