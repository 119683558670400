<template>
  <div :class="{ collapsed }">
    <TariffEndsHeader/>
    <OpenedVisitHeader/>
    <Sidebar/>
    <div class="sidebar-offset" :class="{ collapsed, rightPaneOpened }">
      <AppHeader/>
      <main>
        <div class="dentist-container">
          <slot/>
        </div>
      </main>
    </div>
    <div class="mobile-shadow" @click="$store.commit('toggleCollapsed')"></div>
    <FormModal v-if="showModal"/>
    <HappyBirthdayModal v-if="rights.includes('birthday')"/>
    <WebPush/>
    <LaravelEcho/>
    <ModuleNotInTariffModal/>
    <JetAPIChatFrame/>
    <Popup/>
    <FillDemoAccount/>
  </div>
</template>
<script>

import Sidebar from "./Sidebar"
import AppHeader from "./AppHeader"
import { mapState } from "vuex"
import FormModal from "@/components/parts/datatable/FormModal"
import HappyBirthdayModal from "@/components/parts/patients/HappyBirthdayModal"
import WebPush from "@/components/parts/general/WebPush"
import LaravelEcho from "@/components/parts/general/LaravelEcho"
import ModuleNotInTariffModal from "@/components/parts/billing/ModuleNotInTariffModal"
import TariffEndsHeader from "@/components/parts/general/TariffEndsHeader"
import Popup from "@/components/parts/general/Popup"
import OpenedVisitHeader from "@/components/parts/patients/visit/OpenedVisitHeader.vue"
import JetAPIChatFrame from "@/components/parts/crm/JetAPIChatFrame.vue"
import FillDemoAccount from "@/components/parts/settings/FillDemoAccount.vue"
import {launchHelpDeskeddy} from "@/services/helpDeskeddy.helper"

export default {
  components: {
    FillDemoAccount,
    JetAPIChatFrame,
    OpenedVisitHeader,
    Popup,
    TariffEndsHeader,
    ModuleNotInTariffModal,
    WebPush,
    LaravelEcho,
    FormModal,
    AppHeader,
    Sidebar,
    HappyBirthdayModal
  },
  beforeCreate() {
  },
  data() {
    return {
      experrtoEnabled: false
    }
  },
  mounted() {
    if(this.user && this.user.id && this.$metrika) {
      let params = { UserID: this.user.id }
      if(this.clinic) params.ClinicID = this.clinic.id
      this.$metrika.userParams(params)
    }

    if(this.clinic && this.clinic.support_button === 1) {
      // (function (w, d, u) {
      //
      //   var s = d.createElement('script');
      //   s.async = true;
      //   s.src = u + '?' + (Date.now() / 60000 | 0);
      //   var h = d.getElementsByTagName('script')[0];
      //   h.parentNode.insertBefore(s, h);
      // })(window, document, 'https://cdn-ru.bitrix24.ru/b15023132/crm/site_button/loader_6_y945vk.js');
      launchHelpDeskeddy()
    }

    if(this.experrtoEnabled && this.user && this.user.role_id == 2) {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://cdn.experrto.io/client/experrto.js')
      document.head.appendChild(externalScript)
      let user = this.user
      externalScript.onload = function() {
        if(window.Experrto) {
          window.Experrto.identify("0092ea9463a637e09cf91be56a88631d0b5dfc6a", {
            userId: user.id
          })
        }
      }
    }
  },
  computed: {
    ...mapState({
      collapsed: state => state.dom.sidebarCollapsed,
      showModal: state => state.datatable.showModal,
      rights: state => state.auth.rights,
      user: state => state.auth.user,
      clinic: state => state.auth.clinic,
      rightPaneOpened: state => state.dom.rightPaneOpened
    })
  },
}
</script>
