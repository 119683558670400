<template>
  <section id="ndlf" class="ndlf">
    <a class="btn btn-themed d-inline-flex align-items-center btn-themed-outline btn-themed-squared"
       @click="printDoc">
      {{ $t('add') }}
    </a>
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('ndfl_statement') }}</h1>
          </div>
        </div>
      </div>
    </transition>
    <DataTable :options="options"
               :params="{
                  patient_id: patient.id,
                  report_name: 'ndfl'
               }"
               module="patients"/>
    <PatientNDFLConfigModal/>
  </section>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/report_populated"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref, computed } from "vue"
import { useStore } from "vuex"
import PatientNDFLConfigModal from "@/components/parts/patients/PatientNDFLConfigModal.vue"

const store = useStore()
let i18n = null
let bvModal = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  const instance = (getCurrentInstance())
  i18n = instance?.ctx?.$i18n
  bvModal = instance?.ctx?._bv__modal
  title.value = i18n.t('ndfl_statement')
})

const patient = computed(() => store.state.patient.patient)

onMounted(() => {
  store.commit('setEloquentOptions', options)
})

const printDoc = async () => {
  bvModal.show('patient-ndfl-modal')
}
</script>
