<template>
  <b-modal id="lab-service-search-modal"
           hide-footer
           :title="$t('search')"
           @hide="emit('closed')"
           @show="loadData"
           size="md"
           centered>
    <div v-if="tree">
      <div v-for="(item, index) in tree"
           :key="`serv-${index}`">
        <div class="font-weight-bold mb-2 pointer"
             @click="categoryToggle(item.category.id)">
          <MinusSvg v-if="opened.includes(item.category.id)"/>
          <PlusSvg v-else/>
          {{ item.category.title }}
        </div>
        <div v-if="opened.includes(item.category.id)">
          <div v-for="service in item.services"
               class="pl-3"
               :key="`service-${service.id}`">
            <CheckboxGroup :fid="`service-${service.id}`"
                           class="sm mb-1"
                           :label="`${service.title} - ${formatCurrency(service.price)}`"
                           :checkboxvalue="service.id"
                           v-model="selected"/>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn-themed btn-themed-squared"
              :disabled="!selected.length"
              @click="save">
        {{ $t('add') }}
      </button>
    </div>
  </b-modal>
</template>

<script setup>
import { LaboratoryService } from "@/services/api.service"
import {computed, ref} from "vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import PlusSvg from '@/assets/svg-vue/general/boxplus.svg'
import MinusSvg from '@/assets/svg-vue/general/boxminus.svg'
import {formatCurrency} from "@/extensions/filters/filters"

const emit = defineEmits(['closed'])
const tree = ref(null)
const selected = ref([])
const opened = ref([])

const loadData = async () => {
  const res = await LaboratoryService.laboratoryServicesTree()
  tree.value = res.data
}

const categoryToggle = (id) => {
  if(opened.value.includes(id)) {
    opened.value = opened.value.filter(item => item !== id)
  } else {
    opened.value.push(id)
  }
}

const servicesFlat = computed(() => {
  const arr = []
  tree.value.forEach(item => {
    item.services.forEach(service => {
      arr.push(service)
    })
  })
  return arr
})

const selectedObjects = computed(() => {
  if(!tree.value) return []
  return servicesFlat.value.filter(item => selected.value.includes(item.id))
})

const save = () => {
  emit('closed', selectedObjects.value)
}
</script>
