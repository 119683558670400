export default {
    insurance_companies: "Страховые компании",
    insurance_polis: "Страховой полис",
    limit: "Лимит",
    number: "Номер",
    polis: "Полис",
    polises: "Полисы",
    use_custom_prices_for_insurance: "Использовать индивидуальные цены для страховых компаний",
    use_only_in_insurance: "Использовать только в страховых",
    valid_from: "Действителен с",
    valid_to: "Действителен до",
    visit_should_have_polis_set_in_order_to_use_insurance_payment: "У визита должен быть заполнен страховой полис для использования страховой оплаты",
}
