<template>
  <section id="patient" class="patient">
    <div class="section-top-row align-items-center" :class="{ 'border-0': treatmentCard }">
      <div class="action-buttons-td position-relative">
        <div class="d-md-none d-inline-block">
          <h1>{{ patient ? patient.full_name : '' }}</h1>
        </div>
        <a class="d-md-none d-inline-block action-dots" @click="dotsPressed"><DotsSvg/></a>
        <div class="action-buttons-div d-md-flex row no-gutters align-items-center no-print"
             :class="{ expanded: mob_expanded }"
             v-click-outside="clickedOutside">
          <div class="col d-md-block d-none">
            <h1>
            <span class="d-md-inline d-none">
            <router-link :to="{ name: 'Patients' }">{{ $t('patients') }}</router-link>
              </span>
              <span v-if="patient && !treatmentCard"><span class="d-md-inline d-none"> / </span>{{ patient.full_name }}</span>
              <span v-if="patient && treatmentCard"> / <router-link :to="{ name: 'PatientVisits', params: {id: patient.id} }">{{ patient.full_name }}</router-link></span>
              <span v-if="patient && treatmentCard"> / {{ $t('treatment_card') }}</span>
            </h1>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && !isPatient">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="anketaPressed">
                <FileSvg class="mr-2 file-svg"/>{{ $t('questionnaire') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && !isPatient && rights.includes('contracts')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="contractPressed">
                <FileSvg class="mr-2 file-svg"/>{{ $t('contract') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && rights.includes('payments')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray"
                 @click="paymentPressed">
                <DollarSvg class="mr-2 file-svg"/>{{ $t('payment') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && rights.includes('patients_update')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="editPatient">
                <EditSvg class="mr-2"/>{{ $t('edit') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && !isPatient">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="printPressed">
                <PrintSvg class="mr-2 file-svg"/>{{ $t('print_noun') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && rights.includes('add_records')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed d-inline-flex align-items-center" @click="addVisit">
                <CalendarSvg class="svg-white mr-2"/>
                {{ $t('add') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="treatmentCard && visit">
            <a class="btn btn-themed d-inline-flex align-items-center"
               @click="startTreatment"
               v-if="['1','2','3'].includes(visit.records_statuses_id)">
              {{ $t('start_treatment') }}
            </a>
            <span v-if="visit.records_statuses_id == 4" class="text-primary d-block font-weight-bold h-100">
              <span class="circle circle-primary sm mr-2"></span>{{ $t('in_chair') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <PatientCard/>

    <div v-if="patient && patient.pinnedComments && patient.pinnedComments.length"
         style="max-width: 1200px;">
      <PatientCommentTeaser v-for="c in patient.pinnedComments"
                            :key="`comment-${c.id}`"
                            :comment="c"
                            @getComments="$store.commit('incrementDataTableCounter')"
                            class="mb-3">
      </PatientCommentTeaser>
    </div>

    <div class="d-md-none d-block mobile-big-separator"></div>

    <div class="sub-menu no-print"
         v-if="!treatmentCard">
      <template v-for="(m, mind) in menu">
        <a class="pointer"
           tabindex="#"
           v-if="m.rightPopup && !rights.includes(m.rightPopup) && hasFreeTariff"
           :key="`a-link-${mind}}`"
           @click="$bvModal.show('module-not-in-tariff-modal')">
          {{ $t(m.title) }}
        </a>
        <router-link :to="{ name: m.to }"
                     v-else-if="(!m.right || rights.includes(m.right)) && !(isPatient && m.hiddenForPatient)"
                     :key="`patient-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>

    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && !['PatientPayments', 'PatientDepositTransactions', 'PatientNotifications','PatientNDFLs', 'PatientLaboratoryOrders'].includes($route.name)">
      <AddObject module="patients"
                 btnclass="btn-themed-outline"/>
    </div>

    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && $route.name == 'PatientPayments' && rights.includes('accept_payments')">
      <button @click="paymentPressed"
              class="btn-themed btn-themed-outline">{{ $t('add') }}</button>
    </div>
    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && $route.name == 'PatientDepositTransactions' && rights.includes('accept_payments')">
      <button @click="balanceRefill"
              class="btn-themed btn-themed-outline">{{ $t('add') }}</button>
      <button @click="transferBetweenDepositAccounts"
              v-if="deposit_accounts.length > 1"
              class="btn-themed btn-themed-outline ml-3">
        {{ $t('transfer_between_patient_accounts') }}
      </button>
    </div>
    <router-view class="no-print" v-if="patient"/>
    <ScanModal/>
    <PatientAnketaModal/>
    <PatientContractSelectModal/>
    <PatientPrintModal/>
    <RefillBalanceModal/>
    <RefillBonusModal/>
    <TransferBetweenDepositAccountsModal/>
    <PatientToPayModal/>
    <VisitPayModal/>
    <SendMailModal/>
    <AddNewVisitModal/>
    <DeferredMessageModal/>
  </section>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import FileSvg from '@/assets/svg-vue/patients/file.svg'
import DollarSvg from '@/assets/svg-vue/patients/dollar.svg'
import PrintSvg from '@/assets/svg-vue/general/print.svg'
import CalendarSvg from '@/assets/svg-vue/calendar/calendar_plus.svg'
import DotsSvg from '@/assets/svg-vue/header/vertical_dots.svg'
import { mapState, mapActions, mapGetters } from "vuex"
import ScanModal from "@/components/parts/patients/ScanModal"
import AddObject from "@/components/parts/datatable/AddObject"
import PatientAnketaModal from "@/components/parts/patients/PatientAnketaModal"
import PatientContractSelectModal from "@/components/parts/patients/PatientContractSelectModal"
import PatientPrintModal from "@/components/parts/patients/PatientPrintModal"
import RefillBalanceModal from "@/components/parts/patients/RefillBalanceModal"
import PatientToPayModal from "@/components/parts/patients/PatientToPayModal"
import VisitPayModal from "@/components/parts/patients/VisitPayModal"
import { EloquentService } from "@/services/api.service"
import SendMailModal from "@/components/parts/patients/SendMailModal"
import AddNewVisitModal from "@/components/parts/calendar/AddNewVisitModal"
import moment from "moment/moment"
import DeferredMessageModal from "@/components/parts/patients/DeferredMessageModal"
import RefillBonusModal from "@/components/parts/patients/RefillBonusModal.vue"
import TransferBetweenDepositAccountsModal from "@/components/parts/patients/TransferBetweenDepositAccountsModal.vue"
import PatientCommentTeaser from "@/components/parts/patients/PatientCommentTeaser"
import { countries } from "@/dictionaries/dictionary"
import { formatCurrency, formatDate } from "@/extensions/filters/filters"
import PatientCard from "@/components/parts/patients/PatientCard.vue"

export default {
  name: "PatientGeneral",
  components: {
    PatientCard,
    RefillBonusModal,
    DeferredMessageModal,
    AddNewVisitModal,
    SendMailModal,
    VisitPayModal,
    PatientToPayModal,
    RefillBalanceModal,
    TransferBetweenDepositAccountsModal,
    PatientPrintModal,
    PatientContractSelectModal,
    PatientAnketaModal,
    AddObject,
    ScanModal,
    PatientCommentTeaser,
    CalendarSvg,
    EditSvg,
    DollarSvg,
    PrintSvg,
    FileSvg,
    DotsSvg,
  },
  data() {
    return {
      menuData: [
        { to: 'PatientVisits', title: 'visits' },
        { to: 'PatientFormula', title: 'formula' },
        { to: 'PatientPayments', title: 'payments', right: 'payments' },
        { to: 'PatientDepositTransactions', title: 'deposit', right: 'deposit' },
        { to: 'PatientFamilyAccount', title: 'family_account', right: 'family_account' },
        { to: 'PatientTreatmentPlan', title: 'treatment_plan', rightPopup: 'planning' },
        { to: 'PatientInsurancePolises', title: 'insurance', right: 'insurance' },
        { to: 'PatientScans', title: 'scans', right: 'scans' },
        { to: 'PatientNotes', title: 'notes', hiddenForPatient: true },
        { to: 'PatientDocuments', title: 'documents', right: 'documents_upload' },
        { to: 'PatientPersonal', title: 'personal_data' },
        { to: 'PatientComments', title: 'live_feed' },
        { to: 'PatientNotifications', title: 'notifications' },
        { to: 'PatientNDFLs', title: 'ndfl_statement' },
        { to: 'PatientLaboratoryOrders', title: 'requirement_order', right: 'laboratories' },
        // { to: 'PatientClientService', title: 'client_service' },
      ],
      mob_expanded: false,
      justOpened: false,
      // tags: [],
      selectedPhone: null,
      countries
    }
  },
  computed: {
    ...mapState({
      clinic:  state => state.auth.clinic,
      branchId:  state => state.auth.branch,
      user:  state => state.auth.user,
      patient:  state => state.patient.patient,
      counter: state => state.datatable.dataTableCounter,
      isMobile: state => state.dom.isMobile,
      rights: state => state.auth.rights,
      visit:  state => state.patient.visit,
      deposit_accounts: state => state.auth.deposit_accounts,
      wazzupEnabled: state => state.crm.wazzupEnabled,
      ...mapGetters([
        'hasFreeTariff'
      ]),
    }),
    menu() {
      return this.menuData.filter(obj => {
        if(obj.to === 'PatientNDFLs' && this.clinic.country_id !== countries.RUSSIA) return false
        return true
      })
    },
    treatmentCard() {
      return ['PatientVisitPage'].includes(this.$route.name)
    },
    canAdd() {
      let route = this.$route.name
      if(['PatientPersonal','PatientVisitPage','PatientTreatmentPlan','PatientVisits','PatientFamilyAccount','PatientComments'].includes(route)) {
        return false
      }
      if(['PatientPayments', 'PatientDepositTransactions'].includes(route) && !this.rights.includes('payments')) {
        return false
      }
      return true
    },
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
    formatDate,
    formatCurrency,
    ...mapActions(['getPatient']),
    addVisit() {
      let now = moment().format('YYYY-MM-DD HH')+":30:00"
      if(moment().format('mm') > 30) {
        now = moment().add(1, 'hours').format('YYYY-MM-DD HH')+":00:00"
      }
      this.$store.commit('setNewVisit', {
        time: now,
        client_id: this.patient
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    editPatient() {
      this.$store.commit("setViewModel", 'patient')
      this.$store.commit('setSelectedModel', this.copyObject(this.patient))
      this.$store.commit('setShowModal', true)
    },
    contractPressed() {
      this.$bvModal.show('contract-modal')
    },
    anketaPressed() {
      this.$bvModal.show('anketa-modal')
    },
    printPressed() {
      this.$bvModal.show('patient-print-modal')
    },
    sendMail() {
      this.$bvModal.show('send-mail-modal')
    },
    // smsModal(phone = null) {
    //   this.selectedPhone = phone
    //   this.$bvModal.show('patient-sms-modal')
    // },
    paymentPressed() {
      this.$bvModal.show('patient-topay-modal')
    },
    startTreatment() {
      if(!this.visit || !this.visit.id) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, { records_statuses_id: 4 })
      EloquentService.update('record', this.visit.id, formData).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
        this.visit.records_statuses_id = '4'
      })
    },
    // async restorePatient() {
    //   await EloquentService.restore('patient', this.patient.id)
    //   await this.getPatient(this.patient.id)
    //   this.$noty.info(this.$t('success_message'))
    // },
    dotsPressed() {
      this.justOpened = true
      this.mob_expanded = !this.mob_expanded
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.mob_expanded = false
      }
    },
    balanceRefill() {
      this.$bvModal.show('refill-balance-modal')
    },
    transferBetweenDepositAccounts() {
      this.$bvModal.show('transfer-between-deposit-accounts-modal')
    }
  },
  mounted() {
    this.$store.commit('setPatient', null)
    this.$store.commit('setModule', 'patients')
    if(this.isPatient && this.$route.params.id != this.user.id) {
      this.$router.push('/').catch(()=>{})
      return
    }
    this.getPatient(this.$route.params.id)
    this.$store.dispatch('getDepositAccounts')
  },
  unmounted() {
    this.$store.commit('setPatient', null)
  },
  watch: {
    counter() {
      this.getPatient(this.$route.params.id)
    },
    $route(val) {
      if(this.patient && this.$route.params.id != this.patient.id) {
        this.$store.commit('setPatient', null)
        this.getPatient(this.$route.params.id)
        return
      }
      if(val.name === 'PatientVisits') {
        this.getPatient(this.$route.params.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#patient {
  .section-top-row {
    border-bottom: 1px solid #e5e5e5;
  }
  .file-svg {
    fill: $label-color;
  }
}

@media screen and (max-width: 768px) {
  #patient {
    .sub-menu {
      padding-top: 4px;
    }
    .section-top-row {
      border-bottom: none;
    }
  }
}
</style>
