export default {
    primaryKey: "id",
    model: 'document_category',
    order: 'id',
    sort: 'desc',
    modaltitle: 'category',
    deletemessage: 'confirm_deletion',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deleteCondition(object) {
        return object.clinic_id
    },
    editCondition(object) {
        return object.clinic_id
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table_show: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'sort_order',
            title: 'sort_order',
            table: true,
            sortable: true,
            form: {
                type: "number",
                note: "higher_number_higher_priority"
            },
            format_number: true
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
