export default {
    absent: "Missing",
    abonplata: "Subscription fee",
    access_restriction: "Access restriction",
    accountant_fio: "Full name of accounting manager",
    accrue_bonuses_on_bonus_payments: "Accrue bonuses on bonus payments",
    activate_bonus_system: "Activate bonus system",
    acquiring_fee: "Acquiring fee",
    add_field: "Add field",
    add_user: "Add user",
    additional_doctors: "Additional doctors",
    additional_modules: "Additional modules",
    additional_space: "Additional space",
    admins_limit: "Managers limit",
    Advance: "Deposit",
    advance_calculated: "Deposit",
    advance_payment: "One-off sum",
    after_discounts: "After discount",
    after_technic_deduction: "After techinician wage",
    after_visit: "Notification after visit",
    akt_text: "Note in the act",
    all_administrators: "All administrators",
    all_visits: "All records",
    allow: "Allow",
    allowed_ips: "IP addresses from which you can log into your account",
    allow_negative_deposits: "Allow negative deposits",
    api: "API",
    api_token: "API Token",
    are_you_sure_you_want_to_delete_branch: "Are you sure, you want to delete branch?",
    are_you_sure_you_want_to_delete_company: "Are you sure, you want to delete company?",
    are_you_sure_you_want_to_delete_notification: "Are you sure, you want to delete notification?",
    are_you_sure_you_want_to_delete_source: "Are you sure, you want to delete source?",
    are_you_sure_you_want_to_delete_tag: "Are you sure, you want to delete tag?",
    ask_for_more: "Request for more",
    ask_reason_cancel: "Ask reason when cancelling visit",
    ask_reason_not_come: "Ask reason when patient does not come",
    ask_reason_reschedule: "Ask reason when rescheduling visit",
    assistants_connected: "Assistants module connected",
    available_disk_space: "Available disc space, MB (0 - unlimited)",
    automatic_conflict_task_creation: "Automatic creation of tasks in case of conflict",
    before_discounts: "Before discount",
    before_technic_deduction: "Before techinician wage",
    before_visit: "Notification before visit",
    before_visit_day_in_advance: "Visit reminder day in advance (immediately)",
    billing: "Billing",
    billing_payment: "Payment",
    bonus_accrued: "Bonus accrued",
    bonus_adjustment: "Bonus adjustment",
    bonus_payment: "Bonus payment",
    bonus_percent_can_be_used: "Maximum share of sale that can be paid using bonus",
    bonus_percent_of_cheque: "Bonus percent of sale",
    bonus_system: "Bonus system",
    bonus: "Bonus",
    bonuses: "Bonuses",
    branch_address: "Branch address",
    branch_contacts: "Branch contacts",
    branch_inn: "Branch INN",
    branches: "Branches",
    branches_connected: "Branches paid module connected",
    by_chairs: "By chairs",
    calendar_text_color: "Text color in the calendar",
    calendar_view_by_default: "Default calendar look",
    cancel_and_move_reason: "Reason for cancellation and rescheduling",
    cancel_and_move_reasons: "Reasons for cancellation and rescheduling",
    change_tariff: "Change tariff",
    ClientNote: "Client note",
    client_tag: "Tag",
    clinic: "Clinic",
    clinics: "Clinics",
    clinic_data: "Clinic's data",
    clinic_inn: "Clinic INN",
    clinic_is_blocked: "Clinic is blocked",
    clinic_name: "Clinic name",
    clinic_name_in_cheque: "Clinic name in cheque",
    close: "Close",
    cloud_space: "Cloud space",
    city: "City",
    communications: "Communications routes",
    company_data: "Company data",
    connected_successfully: "Connected successfully",
    connection_failed: "Connection failed",
    control_system: "Control system",
    countries: "Countries",
    country: "Country",
    crm_application_created: "Creating of CRM application",
    crm_connected: "CRM paid module connected",
    currency_rates: "Currency rates",
    data: "Data",
    date_of_registration: "Registration date",
    day: "Day",
    deduct_acquiring_from_salary: "Deduct acquiring fee from salary",
    deduct_warranty_from_salary: "Do not pay salaries to employees for warranty cases",
    deleted: "Delete",
    demo: "Demo",
    director_data: "Director's data",
    director_fio: "Director's full name",
    disk_space_limit: "Disc space limit (MB)",
    do_not_deduct_acquiring_from_technic_absolute: "Do not deduct acquiring from technicians with absolute accrual",
    do_not_send_notifications_if_period_passed: "Do not send notifications if period already passed",
    Doctor: "Doctor",
    DoctorAssistant: "Assistant",
    doctors_limit: "Limit of doctors",
    egisz_prod: "EGISZ production",
    enter_ip_address: "Enter the IP address",
    error_code: "Error code",
    finance: "Finances",
    free: "Free",
    free_day: "Free day",
    general: "General",
    has_additional_doctors: "Has additional doctors",
    higher_number_higher_priority: "The higher the number, the higher item in the list",
    hour: "Hour",
    hours: "Hours",
    image_hidden: "Image hidden",
    in_development: "Under develop",
    insurance_company: "Insurance company",
    integrations: "Integrations",
    interface: "Interface",
    ioka: "Ioka",
    ip_note: "Be careful when entering the IP address not to lose access to the program. This functionality can be used only if you have a static IP address in the clinic.",
    jetapi_connected: "JET API module connected",
    'kit materials sync': "Attaching materials to the kit",
    kpp: "KPP",
    last_login: "Last login",
    legal_address: "Legal address",
    legal_name: "Legal name",
    license: "License",
    license_issue_body: "License issued by",
    license_issue_date: "License issue date",
    license_num: "License #",
    location_on_map: "Location on map",
    logs: "Logs",
    logs_journal: "Action log",
    logo: "Logo",
    logout_interval_timeout: "The interval for exiting the program in case of inactivity (for manager accounts)",
    max_doctor_amount: "Max number of doctors",
    Manager: "Manager",
    manager2: "Manager",
    manual: "Manual",
    min: "Min",
    min_symbols: "Minimum symbols",
    minute: "Minute",
    minutes: "Minutes",
    model_id: "Model Id",
    model_type: "Model type",
    month: "Month",
    monthly_payment: "Monthly payment",
    months_to_consider_patient_lost: "Number of months after which the patient is considered lost",
    more_than_one_day: "More than one day",
    more_than_one_visit: "More than one visit",
    next_payment: "Next payment",
    not_connected: "Not connected",
    notification_channel: "Delivery type",
    notification_setting: "Notification",
    NotificationSetting: "Notification",
    notification_type: "Notification type",
    notifications: "Notifications",
    occupied: "Occupied",
    of_few_hours: "hours",
    ogrn: "OGRN",
    oid_ministry_rf: "OID of the Ministry of Health of the Russian Federation",
    on: "Included",
    on_pay_page: "To the checkout page",
    online_booking: "Online booking",
    online_booking_activate: "Activate online booking",
    online_booking_categories: "Show service categories",
    online_booking_feedbacks: "Activate online booking feedbacks",
    online_booking_map: "Show map of a branch",
    online_booking_max_days: "Maximum online booking time period (days)",
    online_booking_time: "Allow time booking",
    only_after_accordance_with_trustme: "Only after agreement with trustme.kz",
    only_after_payment_received: "Only upon receipt of payment from the patient",
    paid_functionality: "Paid functionality",
    paid_till: "Paid until",
    Patient: "Patient",
    patient_conditions: "Patient's condition",
    patient_sources: "Patient sources",
    patients_import: "Patients import",
    pay: "Pay",
    payable: "Paid",
    Payment: "Payment",
    payment_id: "Payment Id",
    pay_methods: "Pay methods",
    pay_methods_acquiring: "Pay methods to be sent to acquiring terminal",
    pay_methods_cash: "Pay methods included in cash",
    pay_system: "Payment system",
    percent_accrual_for_administrator: "Accrual of interest to administrators",
    percent_accrual_for_personnel: "Accrual of interest to personnel",
    permanent_patients_hint: "Should a patient be considered permanent if a) he has at least two visits with the status arrived or services invoiced, even within one day b) he has at least two different days with visits",
    period_by_default: "Period by default",
    plan_treatment_text: "Treatment plan text",
    PlanTreatment: "Treatment plan",
    popup: "Popup",
    price_per_additional_doctor: "Price for additional doctor",
    price_per_additional_space: "Price for additional space (100 MB)",
    program_language: "Program language",
    prolong: "Extend",
    purchase: "Purchase",
    record_tag: "Record tag",
    record_tags: "Record tags",
    record_timing_enabled: "Start and finish of visits control system",
    Record: "Record",
    RecordTooth: "Tooth",
    recipient: "Recipient",
    reject: "Reject",
    required_fields_to_close_visit: "Required fields to close visit",
    requires_payment: "Requires after-payment",
    right: "Right",
    rights: "Rights",
    salary_accrual: "Interest calculation logic for doctors / managers / curators",
    salary_accrual_for_personnel: "Payroll for doctors / administrators / curators",
    salary_accrual_for_technics: "Payroll for technicians",
    send_time: "Send time",
    sendouts_only_in_day_time: "Send marketing only in day time (09:00 - 22:00)",
    server_error: "The system could not fulfill your request. Please try again or contact support",
    services_import: "Services import",
    short_title: "Short title",
    show_complex_materials_for_teeth: "Show kits and materials in the \"Teeth\" tab of treatment card",
    show_description_in_forms: "Display visit comment in forms 043, 058 and treatment history",
    show_images: "Show images",
    show_preinstalled_templates: "Show preinstalled templates",
    show_teeth_general: "Show general fields in the \"Teeth\" tab of treatment card",
    sign_type: "Sign type",
    sort_order: "Sort order",
    source: "Source",
    sources: "Sources",
    superadmin: "Super admin",
    superadministrators: "Superadministrators",
    supermanager: "Super manager",
    super_user: "Super user",
    switched_to_version_2: "Switched to version 2.0",
    sync_data: "Sync data",
    tag: "Tag",
    tag_id: "Tag",
    tags: "Tags",
    tags_for_custom_notifications: "Tags for automatic display of information:",
    tags_for_notifications: "Tags for automatic display of information:",
    tariff: "Tariff",
    tariff_payment_expires_in_days: "Tariff payment expires today | Tariff payment expires in {day} | Tariff payment expires in {day}",
    tariffs: "Tariffs",
    task_created: "Creation of a task",
    technic_salary_accrual: "Interest calculation logic for technicians",
    templates: "Templates",
    templates_agreements: "Agreement templates",
    templates_categories: "Standard categories",
    templates_contracts: "Contract templates",
    templates_for_fields: "Field templates",
    templates_text: "Text templates",
    templates_treatments: "Treatment templates",
    techsupport_panel: "Technical support panel",
    test: "Test",
    this_may_take_several_minutes_do_not_close_the_window: "This may take several minutes. Do not close the window",
    time_after_visit: "After visit",
    time_before_visit: "Before visit",
    time_interval_value: "Time intervals",
    time_zone: "Time zone",
    to_billing: "To the billing settings",
    to_the_one_who_accepted_payment: "To the one who accepts the payment",
    to_the_one_who_made_record: "To the one who makes record",
    treatment_finished: "Treatment is finished",
    turn_off: "Turn off",
    turn_on_autosign: "Turn on autosign",
    turned_off: "Turned off",
    turned_on: "Turned on",
    'visit services sync': "Service attachment",
    ukrainian: "Ukrainian",
    unblock_for_3_days: "Unblock for 3 days",
    updated: "Changed",
    User: "User",
    use_bonuses_on_salary: "Accrue salary from bonus payments",
    use_proxy: "Use proxy",
    user_id: "User Id",
    uzbek: "Uzbek",
    verify: "Verify",
    visit_cancel: "Appointment cancellation",
    visit_comment: "Visit comment",
    visit_created: "Create an appointment",
    visit_results: "Visit results",
    visit_result: "Visit result",
    visit_type: "Visit type",
    warehouse_connected: "Warehouse paid module connected",
    week: "Week",
    when_changing_tariff_all_payments_will_nullify: "When you change the tariff, all payments will be canceled",
    which_branches_use_online_booking: "Which branches use online booking",
    which_clients_to_consider_permanent: "Which clients to consider permanent",
    work_time_from: "Open hours from",
    work_time_to: "Open hours to",
    your_current_ip: "Your current ip",
    your_tariff_plan_is_not_paid: "Your tariff plan has not been paid",
    zero_unlimited: "0 - unlimited",
}
