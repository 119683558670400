<template>
  <section id="sessions" class="sessions">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('sessions') }}</h1>
          </div>
        </div>
      </div>
    </transition>
    <div class="row align-items-center mb-md-0 mb-3">
      <div class="col-md">
        <div class="mb-2" v-if="ip">{{ $t('your_current_ip') }}: {{ ip }}</div>
      </div>
      <div class="col-md-auto">
        <button @click="deleteAll"
                class="btn-themed btn-themed-squared btn-outline-danger">
          {{ $t('delete_all_sessions_except_current') }}
        </button>
      </div>
    </div>
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="cancel_date"
                   initialSort="desc"
                   :rows="rows"/>
  </section>
</template>

<script setup>
import DataTableHtml from "@/components/parts/general/DataTableHtml.vue"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue"
import { UsersService } from "@/services/api.service"
import { useHead } from "@unhead/vue"
import { useStore } from "vuex"

let i18n = null
const rows = ref([])
const title = ref('')
const ip = ref(null)

const store = useStore()

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('sessions')
})

const counter = computed(() => store.state.datatable.dataTableCounter)
const columns = computed(() => {
  return [
    {
      id: 'id',
      field: 'id',
      label: i18n.t('id'),
      sortable: true
    },
    {
      id: 'last_used_at',
      field: 'last_used_at',
      label: i18n.t('last_use'),
      format_datetime: true,
      sortable: true
    },
    {
      id: 'created_at',
      field: 'created_at',
      label: i18n.t('date_add'),
      format_datetime: true,
      sortable: true
    },
    {
      id: 'ip',
      field: 'ip',
      label: 'Ip',
      search_select: true,
      sortable: true
    },
    {
      id: 'browser',
      field: 'browser',
      label: i18n.t('browser'),
      search_select: true,
      sortable: true
    },
    {
      id: 'os',
      field: 'os',
      label: i18n.t('operating_system'),
      search_select: true,
      sortable: true
    },
    {
      id: 'current',
      field: 'current',
      label: i18n.t('current_session'),
      sortable: true,
      tdcomponent: 'BooleanTD',
      search_select: true,
      format_boolean: true,
    },
    {
      id: 'id',
      field: 'id',
      label: '',
      tdcomponent: 'SessionsDatatableTDSessionInvalidate',
      sortable: false,
      hide_search: true
    }
  ]
})

const loadData = async () => {
  try {
    const res = await UsersService.getSessions()
    rows.value = res.data
  } catch (e) {
    console.log(e)
  }
}

const deleteAll = async () => {
  try {
    const res = await UsersService.invalidateToken('allButCurrent')
    if(res.data) store.commit('incrementDataTableCounter')
  } catch (e) {
    console.log(e)
  }
}

onMounted(() => {
  loadData()
  UsersService.getCurrentIp().then(data => {
    ip.value = data.data
  }).catch(e => {
    console.log(e)
  })
})

watch(counter, loadData)
</script>
