export default {
    tag_ACCRUED_BONUSES: "Нараховано бонусів",
    tag_ADDRESS: "Адреса пацієнта",
    tag_ADDRESS_PREDSTAVITEL: "Адреса представника клієнта",
    tag_BIRTHDAY: "Дата народження пацієнта",
    tag_BIRTHDAY_PREDSTAVITEL: "Дата народження представника пацієнта",
    tag_BRANCH_ADDRESS: "Адреса філії",
    tag_BRANCH_BIN: "БІН філії",
    tag_BRANCH_INN: "ІПН філії",
    tag_BRANCH_KPP: "КПП філії",
    tag_BRANCH_LICENSE: "Ліцензія філії",
    tag_BRANCH_OGRN: "ОГРН філії",
    tag_BRANCH_TITLE: "Назва філії",
    tag_CARD_CLIENT: "Номер картки клієнта",
    tag_CLIENT: "П.І.Б. клієнта",
    tag_CLIENT_BONUSES: "Бонуси клієнта",
    tag_CLIENT_NAME: "Iм'я клієнта",
    tag_CLIENT_NAME_MNAME: "Iм'я клієнта з по батькові",
    tag_CLINIC: "Назва клініки",
    tag_COMPLEX_STATUS: "Статус комплексу плану лікування",
    tag_COST_DISCOUNTED: "Вартість послуг зі знижкою",
    tag_COST_ORIGINAL: "Вартість послуг без урахування знижки",
    tag_DATA: "Дата створення документа",
    tag_DATA_VIZIT: "Дата візиту",
    tag_DATE: "Дата створення розсилки",
    tag_DATE_FIRST_VIZIT: "Дата першого візиту",
    tag_DIAGNOZ: "Діагноз",
    tag_DIAGNOZ_PLAN: "Діагноз з плану лікування",
    tag_DOCTOR: "П.І.Б. – піб лікаря",
    tag_DOCTOR_PHONE: "Телефон лікаря",
    tag_DOCUMENT_TYPE_PREDSTAVITEL: "Тип документа представника пацієнта",
    tag_FIO_CLIENT: "П.І.Б. клієнта",
    tag_FIO_DIRECTOR: "П.І.Б. директора",
    tag_IIN: "ИИН пацієнта",
    tag_INN: "ИНН пацієнта",
    tag_LEGAL_ADDRESS: "Юридична адреса клініки",
    tag_LEGAL_COMPANY_NAME: "Юридична назва клініки",
    tag_LEGAL_NAME: "Юридична назва",
    tag_PASSPORT: "Номер паспорта пацієнта",
    tag_PASSPORT_CODE: "Код підрозділу паспорта пацієнта",
    tag_PASSPORT_CODE_PREDSTAVITEL: "Код підрозділу паспорта представника пацієнта",
    tag_PASSPORT_DATE: "Дата видачі паспорта пацієнта",
    tag_PASSPORT_DATE_PREDSTAVITEL: "Дата видачі документа представника пацієнта",
    tag_PASSPORT_ISSUED: "Ким видано паспорт пацієнта",
    tag_PASSPORT_ISSUED_PREDSTAVITEL: "Ким видано документ представника пацієнта",
    tag_PASSPORT_PREDSTAVITEL: "Номер паспорта представника пацієнта",
    tag_PASSPORT_SERIES: "Серія паспорта пацієнта",
    tag_PASSPORT_SERIES_PREDSTAVITEL: "Серія документа представника пацієнта",
    tag_PLAN_TYPE: "Тип плану лікування",
    tag_PHONE: "Телефон клієнта",
    tag_PHONE_CLIENT: "Телефон клієнта",
    tag_PHONE_PREDSTAVITEL: "Телефон представника клієнта",
    tag_PLAN_STATUS: "Статус плану лікування",
    tag_PLAN_TITLE: "Назва плану лікування",
    tag_PREDSTAVITEL_PACIENTA: "П.І.Б. представника клієнта",
    tag_SNILS: "СНІЛС пацієнта",
    tag_SNILS_PREDSTAVITEL: "СНИЛС представника пацієнта",
    tag_TASK: "Текст задачі",
    tag_URL_VIZIT_CONFIRMATION: "Посилання на підтвердження візиту",
    tag_URL_VIZIT_REVIEW: "Посилання на відгук про візит",
    tag_USLUGI: "Послуги",
    tag_USLUGI_PLAN: "Послуги з плану лікування",
    tag_VISIT_DOCTOR: "Лікар, до якого записаний пацієнт",
}
