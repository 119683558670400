export default {
    accepted_by: "Кем принято",
    additional_income: "Дополнительный доход",
    additional_costs: "Расходы",
    admin_statistics_future: "Статистика администратора о новых записях на будущие даты",
    administrator_percent: "Процент администратора",
    administrator_sum: "Сумма администратора",
    apply: "Применить",
    assistant_percent: "Процент ассистента",
    assistant_sum: "Сумма ассистента",
    average_cheque: "Средний чек",
    average_conversion_per_doctor_per_day: "Средний показатель по врачам в день",
    average_conversion_per_doctor_per_period: "Средний показатель по врачам за период",
    balance_amount: "Оплачено с депозита",
    balance_return: "Возврат депозита",
    by: "Кем",
    by_means: "По форме оплаты",
    by_services: "По услугам",
    came_again: "Пришли повторно",
    came_among_patients: "Из них пришли",
    cancel_date: "Дата отмены",
    cancel_reason: "Причина отмены",
    cancelled_visits: "Отмененные визиты",
    cashdesk_report: "Кассовый отчет",
    cash_flow_statement: "Отчет о движении ДС",
    conversion: "Конверсия",
    cos: "Себестоимость",
    costs: "Расходы",
    cost_of_materials: "Стоимость материалов",
    crm_leads_analytics: "Аналитика заявок CRM",
    curator_id: "Id куратора",
    curator_name: "Имя куратора",
    curator_percent: "Процент куратора",
    curator_sum: "Сумма куратора",
    daily_report: "Отчет за день",
    debt_payments: "Оплата долгов",
    debt_writeoff: "Списание долгов",
    debtor: "Должник",
    debtors: "Должники",
    debtors_statement: "Отчет о должниках",
    details_by_visits: "Детали по визитам",
    did_not_came_again: "Не пришли повторно",
    did_not_come_among_patients: "Из них не пришли",
    doctor_percent: "Процент врача",
    doctor_load_percent: "% загрузки врача",
    doctor_name: "Врач",
    doctor_salary: "Зарплата врача",
    doctor_statistics: "Статистика по врачам",
    doctor_sum: "Сумма врача",
    doctors_conversion: "Конверсия по врачам",
    doctors_load: "Загрузка врачей",
    duration: "Продолжительность",
    f057: "Форма 057",
    first_visit_date: "Дата первого визита",
    first_visit_doctor: "Доктор первого визита",
    full_name: "Имя",
    general_percent: "Общий процент",
    hide_cancelled_visits: "Скрывать отмененные визиты",
    hidden_columns: "Спрятанные колонки",
    income_by_doctors: "Выручка по врачам",
    income_by_services: "Выручка по услугам",
    initiator: "Инициатор",
    invoiced: "Выставлено счетов",
    last_visit_date: "Дата последнего визита",
    last_visit_doctor: "Доктор последнего визита",
    last_visit_recommendation: "Рекомендации последнего визита",
    margins: "Валовая прибыль",
    materials_movement_analytics: "Аналитика движения материалов",
    next_visit_date: "Дата следующего визита",
    next_visit_doctor: "Доктор следующего визита",
    only_paid: "Только оплаченные",
    operation_type: "Тип операции",
    orthodontist: "Ортодонт",
    orthopedist: "Ортопед",
    paid_from_account: "Оплачено со счета",
    paid_for: "Оплачено за",
    paid_invoices: "Оплаченные счета",
    patient_id: "Id пациента",
    patient_name: "Пациент",
    patient_status: "Статус пациента",
    permanent_among_patients: "Из них визиты с постоянными пациентами",
    pl_statement: "Отчет о доходах и расходах",
    plan_treatment_complexes: "Комплексы",
    plan_treatments: "План лечения",
    previous_visit_date: "Дата предыдущего визита",
    price_before_discount: "Сумма до скидки",
    primary_among_patients: "Из них визиты с первичными пациентами",
    primary_records: "Первичные - записано",
    primary_records_came: "Первичные - дошло",
    primary_records_further: "Первичные - записаны далее",
    primary_records_revenue: "Первичные - выручка",
    records: "Записей",
    records_count: "Кол-во визитов",
    repeated_records: "Повторные - записано",
    repeated_records_came: "Повторные - дошло",
    repeated_records_further: "Повторные - записаны далее",
    repeated_records_revenue: "Повторные - выручка",
    report_is_too_heavy_confirm: "Отчет слишком большой для просмотра на странице, выгрузить отчет в excel файл?",
    report_days_limit: "Ограничение периода отчетности (дней)",
    revenue: "Выручка",
    revenue_per_hour: "Выручка в час",
    revenues: "Выручка",
    saldo: "Сальдо",
    sales_funnel_applications: "Воронка: Заявки и обращения",
    sales_funnel_plans: "Воронка: Планы лечений",
    services_amount: "Кол-во услуг",
    show_completed_visits: "Отображать дошедшие визиты",
    show_deleted_doctors: "Показывать удаленных врачей",
    show_only_new_patients: "Отображать только новых пациентов",
    source_type: "Тип источника",
    technic_percent: "Процент техника",
    technic_sum: "Сумма техника",
    therapist: "Терапевт",
    total_patients: "Кол-во записанных пациентов",
    total_primary: "Итого первичных",
    total_revenue: "Итого выручка",
    unpaid_invoices: "Неоплаченные счета",
    updated_date: "Дата изменения",
    visit_id: "Id записи",
    visit_statistics: "Статистика визитов",
    visits_by_source: "Визиты по источникам",
    visits_detailed: "Визиты (расширенный)",
    where_came_from: "Откуда пришел(-ла)",
    who_cancelled: "Кто отменил",
    who_changed_status: "Кем изменен статус (Оплата / Пришел)",
    who_created: "Кем создано",
    who_updated: "Кем изменено",
    with_patient: "С пациентом",
    worked_days: "Отработанных дней",
    worked_hours: "Отработанных часов",
}
