<template>
  <div>
    <div class="row font-weight-bold" v-if="service">
      <div class="col-auto">
        {{ [...new Set(service.teeth.map(obj => toothName(obj, $t)))].join(', ') }}
      </div>
      <div class="col">
        {{ service.title }}{{ service.teeth.length > 1 ? ` x ${service.teeth.length}` : '' }}
        <span v-if="service.dental_work_required === 'required'">*</span>
      </div>
    </div>
    <div v-if="!service"
         class="font-weight-bold">
      {{ $t('without_attachment_to_tooth') }}
    </div>
    <div class="row align-items-center my-2" v-if="!readOnly">
      <div class="col">
        <Autocomplete model="laboratory_service"
                      :placeholder="$t('add_laboratory_service')"
                      class="autocomplete-operation-material w-100"
                      @input="serviceAdded"/>
      </div>
      <div class="col-auto">
        <a class="pointer text-underlined text-muted"
           @click="selectFromPrice">
          {{ $t('select_from_price') }}
        </a>
      </div>
    </div>
    <div v-if="readOnly && !_labServices.length">
      <div class="text-muted mt-2">
        {{ $t('no_services') }}
      </div>
    </div>
    <div v-if="service && !readOnly && !_labServices.length && service.dental_work_required === 'required'"
         class="text-muted small">
      * {{ $t('field_is_required') }}
    </div>
    <table class="table-blue services-table"
           v-if="_labServices.length">
      <thead>
      <tr>
        <th class="name-th">{{ $t('name') }}</th>
        <th class="num-th">{{ $t('price') }}</th>
        <th class="num-th">{{ $t('amount') }}</th>
        <th class="num-th">{{ $t('sum') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(labService, index) in _labServices"
          :key="`lab-service-${index}`">
        <td>{{ labService.laboratory_service?.title }}</td>
        <td>{{ formatNumber(labService.price) }}</td>
        <td>
          <input type="number"
                 :readonly="true"
                 :value="labService.qty"
                 style="width: 50px"
                 class="form-control d-inline-block no-right-radius sm align-middle"/>
          <div class="d-inline-flex align-middle flex-column plus-minus-block" :class="{ disabled: readOnly }">
            <a class="plus"
               :class="{ disabled: readOnly }"
               tabindex="#"
               @click="quantityChangedManually(index, 'plus')">+</a>
            <a class="minus"
               :class="{ disabled: readOnly }"
               tabindex="#"
               @click="quantityChangedManually(index, 'minus')">-</a>
          </div>

        </td>
        <td>{{ formatNumber(labService.price * labService.qty) }}</td>
        <td>
          <a class="pointer"
             v-if="!readOnly"
             @click="deleteItem(index)">
            <DeleteSvg/>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot class="border-top">
      <tr class="font-weight-bold">
        <td>{{ $t('total') }}</td>
        <td></td>
        <td></td>
        <td>{{ formatNumber(totalSum) }}</td>
        <td></td>
      </tr>
      </tfoot>
    </table>
    <LaboratoryServiceSearchModal v-if="searchMode"
                                  @closed="priceSelectClosed"/>
  </div>
</template>

<script setup>
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'

import Autocomplete from "@/components/parts/general/Autocomplete.vue"
import { formatNumber } from "@/extensions/filters/filters"
import { computed, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue"
import { copyObject } from "@/extensions/prototypes/prototypes"
import LaboratoryServiceSearchModal from "@/components/parts/patients/visit/LaboratoryServiceSearchModal.vue"
import { toothName } from "@/services/teeth.helper"

const props = defineProps({
  service: {
    type: Object,
    required: false
  },
  labServices: Array,
  readOnly: {
    type: Boolean,
    default: false
  }
})

let bvModal = null

const _labServices = ref([])
const searchMode = ref(false)

const emit = defineEmits(['changed'])

const serviceAdded = (val) => {
  _labServices.value.push({
    id: null,
    title: val.title,
    price: val.price,
    qty: 1,
    laboratory_service: {
      id: val.id,
      title: val.title,
    },
    laboratory_service_id: val.id,
    service_id: props.service?.id || null
  })
  changed()
}

const totalSum = computed(() => {
  return props.labServices.reduce((acc, obj) => acc + Number(obj.price), 0)
})

const changed = () => {
  const dataToSend = []
  _labServices.value.forEach(val => {
    for(let i = 0; i < val.qty; i++) {
      dataToSend.push(val)
    }
  })
  emit('changed', {
    services: dataToSend,
    serviceId: props.service?.id || null
  })
}

const deleteItem = (index) => {
  _labServices.value.splice(index, 1)
  changed()
}

const copyData = () => {
  const tmpArray = copyObject(props.labServices)
  let arr = {}
  tmpArray.forEach((labService) => {
    if(arr[labService.laboratory_service_id]) {
      arr[labService.laboratory_service_id].qty++
    } else {
      labService.qty = 1
      arr[labService.laboratory_service_id] = labService
    }
  })
  _labServices.value = Object.values(arr)
}

const selectFromPrice = async () => {
  searchMode.value = true
  await nextTick()
  bvModal.show('lab-service-search-modal')
}

const quantityChangedManually = (index, sign = 'plus') => {
  if(props.readOnly) return
  _labServices.value[index].qty = sign === 'plus' ?
      _labServices.value[index].qty + 1 :
      _labServices.value[index].qty - 1
  changed()
}

const priceSelectClosed = (selectedObjects) => {
  if(selectedObjects) {
    selectedObjects.forEach(obj => {
      serviceAdded(obj)
    })
  }
  bvModal.hide('lab-service-search-modal')
  setTimeout(() => {
    searchMode.value = false
  }, 200)
}

onMounted(() => {
  copyData()
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
})

watch(() => props.labServices, () => {
  copyData()
}, { deep: true })
</script>

<style scoped lang="scss">
.name-th {
  width: 36%;
}
.num-th {
  width: 18%;
}
@media screen and (max-width: 768px) {
  .name-th, .num-th {
    width: auto;
  }
}
</style>
