import { DocumentsService } from "@/services/api.service"

const getOnlineContractKzContractPdf = async (docId) => {
	if(!docId) return
	try {
		const res = await DocumentsService.getOnlineContractKzContractPdf(docId)
		openBase64AsPdf(res.data)
	} catch (e) {
		console.log(e)
	}
}

const openBase64AsPdf = (data) => {
	const byteCharacters = atob(data)
	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}
	const byteArray = new Uint8Array(byteNumbers)
	const pdfBlob = new Blob([byteArray], { type: "application/pdf" })
	const pdfUrl = URL.createObjectURL(pdfBlob)
	window.open(pdfUrl, "_blank")
}

export { getOnlineContractKzContractPdf, openBase64AsPdf }
