<template>
  <div>
    <span v-if="object.deleted_at" class="text-danger">{{ $t('is_deleted') }}</span>
    <span class="text-primary" v-else-if="object[field.id]">{{ $t('active') }}</span>
    <span v-else class="text-danger">{{ $t('nonactive') }}</span>
  </div>
</template>

<script>
export default {
  name: "ActiveTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>
