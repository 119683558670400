<template>
  <div>

    <div class="mb-3">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-anketa-headers`">
        {{ $t('anketa_texts') }}
      </b-button>
      <spaceduck-collapse
          :id="`collapse-anketa-headers`" v-if="form">
        <label class="mt-3">{{ $t('anketa_header') }}</label>
        <jodit-editor v-model="form.anketa_header"
                      :buttons="joditConfig.buttons"
                      :config="joditConfig.config"/>
        <label class="mt-3">{{ $t('anketa_footer') }}</label>
        <jodit-editor v-model="form.anketa_footer"
                      :buttons="joditConfig.buttons"
                      :config="joditConfig.config"/>
        <button :disabled="loading"
                class="btn-themed btn-themed-squared d-inline-block mt-3"
                @click="saveSettings">
          {{ $t('save') }}
        </button>
        <hr class="mb-5"/>
      </spaceduck-collapse>
    </div>

    <AddObject module="settings"
               btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <!--    <CheckboxGroup fid="ask_result_on_saving"-->
    <!--                   v-if="form"-->
    <!--                   v-model="form.ask_result_on_saving"-->
    <!--                   @input="saveSetting"-->
    <!--                   class="mb-0 mt-3"-->
    <!--                   :checkboxvalue="1"-->
    <!--                   :label="$t('ask_result_on_saving')"/>-->

    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script setup>
import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/anketa_question"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref, computed } from "vue"
import { useStore } from "vuex"
import { JoditEditor } from 'jodit-vue'
import { joditConfig } from "@/dictionaries/joditConfig"
import { appendFormdata, copyObject} from "@/extensions/prototypes/prototypes"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import { EloquentService} from "@/services/api.service"

const store = useStore()
let i18n = null
let noty = null
const title = ref('')
const form = ref(null)
const loading = ref(false)

useHead({
  title: () => title.value,
})

const clinic = computed(() => store.state.auth.clinic)
const additional = computed(() => clinic.value.clinic_additional)

onBeforeMount(() => {
  JoditEditor.compatConfig = { MODE: 3 }
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('questionnaire')
  noty = getCurrentInstance()?.appContext?.app?.noty
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
  form.value = copyObject(additional.value)
})

const saveSettings = async () => {
  loading.value = true
  let formData = new FormData()
  formData = appendFormdata(formData, form.value)
  try {
    await EloquentService.update('clinic_additional', clinic.value.id, formData)
    await store.dispatch('init')
    noty.info(i18n.t('success_message'))
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}
</script>
