export default {
    acquiring_device: "Ödəniş qəbul edən cihaz",
    cashdesk: "Kassa",
    cash_operation: "Kassa əməliyyatı",
    cashier_inn: "Kassirin VÖEN-i",
    cashier_name: "Kassirin adı",
    cheque: "Çek",
    cheques: "Çeklər",
    closing_balance: "Günün sonuna qalıq",
    close_shift: "Növbəni bağla",
    data_is_invalid: "Məlumat etibarsızdır",
    data_is_valid: "Məlumat etibarlıdır",
    deposit_to_cashdesk: "Kassaya pul qoymaq",
    error_printing_cheque: "Çekin çapında xəta",
    fiscal_device: "Fiskal cihaz",
    injection: "Pulun artırılması",
    kkm_url: "Ticarət avadanlığı serverinin HTTP ünvanı. Kassa serveri yerli olaraq qoşulmamışdırsa doldurun.",
    name_of_service_in_cheque: "Çekdə xidmətin adı",
    not_subject_to_vat: "ƏDV-yə cəlb olunmur",
    opening_balance: "Günün əvvəlinə qalıq",
    open_shift: "Növbəni aç",
    opened_at: "Açılış tarixi",
    print_totals: "Toplamı çap et",
    sales: "Satışlar",
    send_services_in_cheque: "Xidməti çekə daxil et",
    shift: "Növbə",
    shifts: "Növbələr",
    shift_number: "Növbənin nömrəsi",
    terminal: "Terminal",
    vat_rate: "ƏDV dərəcəsi",
    waiting_for_payment_in_terminal: "Terminalla ödəniş gözlənilir",
    withdrawal: "Çıxarış",
    withdraw_from_cashdesk: "Kassadan çıxarış",
    xreport: "X-hesabat",
    xreports: "X-hesabatlar"
}
