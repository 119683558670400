export default {
    acquiring_device: "To‘lov qabul qilish qurilmasi",
    cashdesk: "Kassa",
    cash_operation: "Naqd pul muomalasi",
    cashier_inn: "Kassir INN",
    cashier_name: "Kassir nomi",
    cheque: "Chek",
    cheques: "Cheklar",
    closing_balance: "Kun oxiridagi balans",
    close_shift: "Smenani yopish",
    data_is_invalid: "Ma’lumotlar noto‘g‘ri",
    data_is_valid: "Ma’lumotlar to‘g‘ri",
    deposit_to_cashdesk: "Kassaga to‘lov qilish",
    error_printing_cheque: "Chekni chop etishda xatolik",
    fiscal_device: "Fiskal qurilma",
    injection: "To'ldirish",
    kkm_url: "Savdo uskunalari serverining HTTP manzili. Kassa serveri mahalliy ravishda ulanmagan bo'lsa, to'ldiring.",
    name_of_service_in_cheque: "Chiptadagi xizmat nomi",
    not_subject_to_vat: "QQSga soliq qo‘yilmaydi",
    opening_balance: "Kunning boshida muvozanat",
    open_shift: "Smenani ochish",
    opened_at: "Ochilish sanasi",
    print_totals: "Jami summalarni chop etish",
    sales: "Sotuvlar",
    send_services_in_cheque: "Xizmatlarni chiptada yuborish",
    shift: "Smena",
    shifts: "Smenalar",
    shift_number: "Smena raqami",
    terminal: "Terminal",
    vat_rate: "QQS stavkasi",
    waiting_for_payment_in_terminal: "Terminalda to‘lovni kutish",
    withdraw_from_cashdesk: "Kassadan yechish",
    withdrawal: "Olib tashlash",
    xreport: "X-hisobot",
    xreports: "X-hisobotlar"
}
